<template>
  <div>
    <v-row justify="center">
      <v-col>
        <v-data-table
          disable-sort
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
          :headers="headers"
          :items="questions"
          :loading="loading"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Sorular</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.title="{ item }">
            <router-link class="font-weight-regular" :to="'question/' + item.id">
              {{ item.title }}
            </router-link>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.resolved="{ item }">
            <v-checkbox disabled v-model="item.resolved" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ApiService from "../../common/api.service";

export default {
  data: () => ({
    search: "",
    options: {},
    loading: false,
    serverItemsLength: 0,
    headers: [
      { text: "Başlık", value: "title" },
      { text: "Sınıf", value: "courseCategory.name" },
      { text: "Tarih", value: "createdDate" },
      { text: "Ders", value: "lecture.name" },
      { text: "Soran", value: "createdBy" },
      { text: "Yanıt Sayısı", value: "comments.length" },
      { text: "Çözüldü", value: "resolved" }
    ],
    questions: []
  }),
  mounted() {
    this.getAllQuestions();
  },
  watch: {
    options: {
      handler() {
        this.getAllQuestions();
      },
      deep: true
    }
  },
  methods: {
    async getAllQuestions() {
      this.loading = true;
      const res = await ApiService.get(
        `qaQuestions?projection=QAQuestionProjection&page=${this.options.page - 1}&size=${
          this.options.itemsPerPage
        }&sort=createdDate,desc`
      );
      this.questions = res.data["_embedded"].qaQuestions;
      this.serverItemsLength = res.data.page.totalElements;
      this.loading = false;
    }
  }
};
</script>
