var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"options":_vm.options,"server-items-length":_vm.serverItemsLength,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.quizzes,"search":_vm.search,"footer-props":{ 'items-per-page-options': [5, 10, 20] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Sınavlar")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 black--text font-weight-bold",on:{"click":function($event){return _vm.$emit('newItem')}}},[_vm._v(" Yeni Sınav ")])],1)]},proxy:true},{key:"item.published",fn:function(ref){
var item = ref.item;
return [(item.published)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(" Yayında ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Yayında Değil ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'quiz-detail', params: { quizId: item.id } }}},[_c('p',{staticClass:"table-item-link font-weight-bold"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.isExam",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.isExam),callback:function ($$v) {_vm.$set(item, "isExam", $$v)},expression:"item.isExam"}})]}},(_vm.hasActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('editItem', item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('deleteItem', item)}}},[_vm._v("mdi-delete")])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }