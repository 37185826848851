<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <vue-dropzone
          @vdropzone-success="success"
          @vdropzone-sending="sendingEvent"
          @vdropzone-mounted="populate"
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
        ></vue-dropzone>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { StorageService, USER } from "@/common/storage.service";

export default {
  name: "FeedbackMediaDialog",
  props: ["value", "feedbackVideo"],
  components: { vueDropzone: vue2Dropzone },
  data: () => ({
    dropzoneOptions: {
      url: `${process.env.VUE_APP_API_URL}medias/upload`,
      thumbnailWidth: 150,
      maxFiles: 1,
      acceptedFiles: "video/*",
      dictDefaultMessage: "Dosya Yükle",
      headers: {
        Authorization: `Bearer ${StorageService.getItem(USER).token}`,
        "Cache-Control": "",
        "X-Requested-With": ""
      }
    }
  }),
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append("type", "VIDEO");
    },
    success(file, response) {
      this.$emit("mediaUploaded", response.id);
      this.show = false;
    },
    populate() {
      if (this.feedbackVideo) {
        var file = { name: "Çözüm Videosu", type: "video/*" };
        this.$refs.myVueDropzone.manuallyAddFile(file, this.feedbackVideo.url);
      }
    }
  },
  mounted() {},
  computed: {
    addNew() {
      return !this.feedbackVideo;
    },
    formTitle() {
      return this.addNew ? "Yeni Çözüm Videosu Ekle" : "Çözüm Videosunu Düzenle";
    },
    show: {
      get() {
        this.$refs.observer?.reset();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style src="vue2-dropzone/dist/vue2Dropzone.min.css"></style>
