<template>
  <v-card class="rounded-lg">
    <v-toolbar flat>
      <v-toolbar-title>Deneme Sınavı Sonuçları</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-expansion-panels :hover="true">
        <v-expansion-panel v-for="exam in exams" :key="exam.id" @change="getExamReport(exam.id)">
          <v-expansion-panel-header>
            <h3>{{ exam.name }}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="attemptDetail">
              <br />
              <p>Sınav {{ examDuration }} dakika sürdü.</p>
              Sınav sonuç puanı : {{ attemptDetail.score }}

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left font-weight-black">Branş</th>
                      <th class="text-left font-weight-black">Toplam Soru Sayısı</th>
                      <th class="text-left font-weight-black">Doğru Cevap</th>
                      <th class="text-left font-weight-black">Yanlış Cevap</th>
                      <th class="text-left font-weight-black">Boş Bırakılan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(examResult, i) in examResults" :key="i">
                      <td>{{ examResult.name }}</td>
                      <td>{{ examResult.total }}</td>
                      <td>{{ examResult.correct }}</td>
                      <td>{{ examResult.wrong }}</td>
                      <td v-if="examResult.total > 0">
                        {{ examResult.total - (examResult.wrong + examResult.correct) }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div v-else>
              <br />
              Öğrenci henüz bu sınavı tamamlamamış.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import ApiService from "@/common/api.service";
import moment from "moment";

export default {
  name: "ExamReport",
  props: ["userId", "courseCategoryId"],
  data() {
    return {
      exams: [],
      attemptDetail: null,
      examResults: []
    };
  },
  watch: {
    courseCategoryId: function() {
      this.drawGraph();
    }
  },
  mounted() {
    moment.locale("tr");
    this.drawGraph();
  },
  computed: {
    examDuration() {
      if (!this.attemptDetail.startTime && !this.attemptDetail.endTime) return;

      let start = moment(this.attemptDetail.startTime);
      let end = moment(this.attemptDetail.endTime);

      let duration = moment.duration(end.diff(start));
      return Math.floor(duration.asMinutes());
    }
  },
  methods: {
    ...mapActions("quiz", ["fetchExamsByCourseCategory"]),
    async drawGraph() {
      this.exams = await this.fetchExamsByCourseCategory(this.courseCategoryId);
    },
    async getExamReport(examId) {
      this.attemptDetail = null;
      let url = `attempts/search/findFirstByQuiz_IdAndUser_Id`;
      url += `?quizId=${examId}&userId=${this.userId}&projection=AttemptListProjection`;
      let res = await ApiService.getWithoutValidate(url);
      if (res.status != 200) return;
      this.attemptDetail = res.data;

      url = `reports/examReport?quizId=${this.attemptDetail.quiz}&attemptId=${this.attemptDetail.id}`;
      let reportRes = await ApiService.getWithoutValidate(url);
      this.examResults = reportRes.data;
    }
  }
};
</script>

<style></style>
