<template>
  <v-col cols="12" class="daily-schedule">
    <div v-if="!todaySchedule.length">
      <v-alert class="rounded-lg" type="warning" dark color="#292F36">
        <strong class="font-weight-bold">
          Bugün canlı ders bulunmamaktadır. Geçmiş canlı dersleri izleyerek tekrar yapabilirsiniz.
        </strong>
      </v-alert>
    </div>
    <v-card v-for="schedule in todaySchedule" :key="schedule.id" rounded class="rounded-xl">
      <div class="d-flex flex-no-wrap flex-column flex-md-row flex-sm-row justify-space-between align-center">
        <div class="schedule-card d-flex pa-4">
          <v-avatar size="120px">
            <img alt="Avatar" :src="schedule.lectureImage" />
          </v-avatar>
          <div class="pt-2">
            <v-card-subtitle>{{ courseCategory.name }}</v-card-subtitle>
            <v-card-title v-text="schedule.webinar.name"></v-card-title>
          </div>
        </div>
        <div class="text-center pt-md-4">
          <p class="schedule-startTime">{{ schedule.startTime }}</p>
          <p v-if="showingToday">
            {{ schedule.fromNow }}
            <span v-if="schedule.onAir">başladı</span>
          </p>
        </div>
        <div class="schedule-actions my-2">
          <v-btn
            v-if="schedule.isPast && showingToday"
            @click="
              showMessage(`Bu ders ${schedule.fromNow} başladı ve sona erdi. Geçmiş canlı dersi izleyebilirsiniz.`)
            "
            class="ma-0 font-weight-bold"
          >
            <v-icon left>hourglass_full</v-icon>
            Geçmiş Ders
          </v-btn>
          <v-btn
            @click="joinWebinar(schedule)"
            v-else-if="hasRole('ROLE_STUDIO') && showingToday"
            color="red"
            class="ma-0"
          >
            <v-icon left>mdi-play</v-icon>
            Dersi Başlat
          </v-btn>
          <v-btn @click="joinWebinar(schedule)" v-else-if="schedule.onAir && showingToday" color="red" class="ma-0">
            <v-icon left>mdi-video</v-icon>
            Derse Katıl
          </v-btn>
          <template v-else>
            <v-btn
              v-if="showingToday"
              @click="showMessage('Bu ders henüz başlamadı. Başladığı zaman katılabilirsiniz.')"
              color="blue"
              class="ma-0"
            >
              <v-icon left>hourglass_empty</v-icon>
              Henüz Başlamadı
            </v-btn>
            <v-btn v-else color="blue" disabled class="ma-0">
              <v-icon left>hourglass_empty</v-icon>
              Henüz Başlamadı
            </v-btn>
          </template>
        </div>
      </div>
    </v-card>
    <v-snackbar v-model="showSnackbar" :multi-line="true">
      {{ snackBarMessage }}
      <v-btn color="red" text @click="showSnackbar = false">
        Kapat
      </v-btn>
    </v-snackbar>
  </v-col>
</template>

<script>
import ApiService from "@/common/api.service";
import { StorageService, COURSE_CATEGORY } from "@/common/storage.service";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "DailyWebinarSchedule",
  props: ["day"],
  data: () => ({
    todaySchedule: [],
    courseCategory: null,
    snackBarMessage: "",
    showSnackbar: false,
    timer: null,
    scheduleCache: {}
  }),
  created() {
    this.courseCategory = StorageService.getItem(COURSE_CATEGORY);
    this.getWebinarSchedules(this.day).then(() => {
      this.timer = setInterval(() => this.updateSchedules(this.scheduleCache), 60000);
    });
  },
  methods: {
    async getWebinarSchedules(day) {
      if (this.courseCategory.id) {
        const res = await ApiService.get(`webinar-schedules/category/${this.courseCategory.id}/days/${day}`);
        let schedules = res.data;
        this.scheduleCache = schedules;
        this.updateSchedules(schedules);
      }
    },
    async updateSchedules(schedules) {
      const serverTimeRes = await ApiService.get("config/time");
      const serverDateTime = serverTimeRes.data;
      this.todaySchedule = schedules.map(elem => {
        moment.locale("tr");
        let now = moment(serverDateTime);
        let startTime = moment(elem.startTime, "HH:mm:ss");
        let formattedTime = now.clone().set({ hour: startTime.hour(), minute: startTime.minute(), second: 0 });
        elem.startTime = formattedTime.format("HH:mm");
        elem.fromNow = formattedTime.fromNow();

        let endTime = formattedTime.clone().add(elem.duration, "minutes");
        elem.onAir = now.isBetween(formattedTime, endTime);
        elem.isPast = now.isAfter(endTime);
        return elem;
      });
    },
    async joinWebinar(schedule) {
      let res = await ApiService.get(`webinars/${schedule.webinar.id}/join`);
      if (res.data.joinUrl.length === 0) {
        this.showMessage(`Bu ders ${schedule.fromNow} başladı ve sona erdi. Geçmiş canlı dersi izleyebilirsiniz.`);
        return;
      }
      window.location.assign(res.data.joinUrl);
    },
    showMessage(message) {
      this.snackBarMessage = message;
      this.showSnackbar = true;
    },
    hasRole(role) {
      return this.currentUser?.roles?.find(user_role => {
        return role === user_role;
      });
    }
  },
  watch: {
    day: function(newVal) {
      this.getWebinarSchedules(newVal);
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    showingToday: function() {
      let today = new Date();
      return this.day == today.getDay();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style>
.schedule-startTime {
  font-size: 1.4rem;
  font-weight: bold;
  color: #4caf50;
}
.schedule-card .v-card__subtitle {
  padding-bottom: 0px;
}
.schedule-card .v-card__title {
  padding-top: 0px;
}
.daily-schedule .v-card {
  margin-top: 15px;
}
.daily-schedule .schedule-actions {
  min-width: 200px;
  text-align: center;
}
</style>
