import { StorageService, USER } from "@/common/storage.service";
import Vue from "vue";
import { PURGE_AUTH, SET_AUTH, SET_ERROR } from "../mutations.type";
import { updateField } from "vuex-map-fields";
import axios from "axios";

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    StorageService.saveItem(USER, state.user || localStorage.getItem("user"));
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    StorageService.destroyItem(USER);
    delete axios.defaults.headers.common["Authorization"];
    Vue.prototype.stompClient?.disconnect();
  },
  updateField
};

export default mutations;
