<template>
  <v-card class="rounded-lg">
    <v-toolbar flat>
      <v-toolbar-title>Video Tamamlama Oranı</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col v-for="(rate, i) in completeRates" :key="i" cols="12" sm="6" md="4">
          <v-card class="rounded-xl">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex justify-space-around">
                  <v-list-item-title class="headline">
                    {{ rate.lectureName }}
                  </v-list-item-title>
                  <v-btn small outlined @click="openReportDetail(rate)">Detay</v-btn>
                </div>
                <v-list-item-subtitle>Toplam video : {{ rate.totalVideoCount }}</v-list-item-subtitle>
                <v-list-item-subtitle>İzlenen video : {{ rate.watchedCount }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-text>
              <div class="text-center">
                <v-progress-circular :size="150" :value="calculateCompleteRate(rate)" color="teal">
                  {{ calculateCompleteRate(rate) }}
                </v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <VideoReportDetail v-model="showDetail" :userId="userId" :lecture="lecture" />
  </v-card>
</template>

<script>
import ApiService from "@/common/api.service";
import VideoReportDetail from "./VideoReportDetail";

export default {
  name: "VideoReport",
  props: ["userId", "courseCategoryId"],
  components: { VideoReportDetail },
  data() {
    return {
      completeRates: [],
      showDetail: false,
      lecture: null
    };
  },
  mounted() {
    this.getVideoCounts();
  },
  watch: {
    courseCategoryId: function() {
      this.getVideoCounts();
    }
  },
  methods: {
    getVideoCounts() {
      ApiService.get(`reports/videoCompleteRate?userId=${this.userId}&courseCategoryId=${this.courseCategoryId}`).then(
        res => {
          this.completeRates = res.data;
        }
      );
    },
    calculateCompleteRate(completeRate) {
      if (completeRate.totalVideoCount === 0) {
        return 100;
      }
      return ((completeRate.watchedCount * 100) / completeRate.totalVideoCount).toFixed(0);
    },
    openReportDetail(lecture) {
      this.lecture = lecture;
      this.showDetail = true;
    }
  }
};
</script>

<style></style>
