<template>
  <v-card rounded class="mx-auto rounded-lg" min-height="100px">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4 justify-space-between align-center" style="display: flex">
          <span>{{ lecture.courseCategory.name }}</span>
          <v-btn icon x-small @click="favOrUnfavLecture(lecture)">
            <v-icon @mouseover="hover = true" @mouseleave="hover = false" color="red">
              {{ heart }}
            </v-icon>
          </v-btn>
        </div>
        <router-link :to="{ name: 'student-lecture', params: { id: lecture.id } }">
          <v-list-item-title :title="lecture.name" class="headline mb-1 black--text font-weight-light">
            {{ lecture.name }}
          </v-list-item-title>
        </router-link>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
import ApiService from "../../common/api.service";

export default {
  props: ["lecture"],
  data: function() {
    return {
      hover: false
    };
  },
  computed: {
    heart() {
      if (this.lecture.favorited) {
        if (this.hover) {
          return "mdi-heart-outline";
        }
        return "mdi-heart";
      } else {
        if (this.hover) {
          return "mdi-heart";
        }
        return "mdi-heart-outline";
      }
    }
  },
  methods: {
    favOrUnfavLecture(lecture) {
      if (lecture.favorited) {
        ApiService.post(`users/unfavLecture/${lecture.id}`);
        lecture.favorited = false;
        this.$emit("onUnFav", lecture);
      } else {
        ApiService.post(`users/favLecture/${lecture.id}`);
        lecture.favorited = true;
        this.$emit("onFav", lecture);
      }
    }
  }
};
</script>
