<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card flat tile>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="show = false">
            İptal
          </v-btn>
          <v-btn dark text @click="save">
            Kaydet
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="save()">
        <v-card-text>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <ValidationProvider name="Kullanıcı Adı" v-slot="{ errors }" rules="required|min:2|max:255">
                    <v-text-field label="Kullanıcı Adı" v-model="user.username" :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6" v-if="!salesmanView">
                  <v-select
                    multiple
                    prepend-icon="assignment_ind"
                    label="Rol"
                    v-model="user.roleList"
                    :items="roles"
                  ></v-select>
                </v-col>
                <template v-if="!userId">
                  <v-col cols="12" md="6">
                    <ValidationProvider name="Şifre" v-slot="{ errors }" rules="required|min:2|max:255">
                      <v-text-field
                        label="Şifre"
                        v-model="user.password"
                        type="password"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </template>
                <v-col cols="12" sm="6">
                  <ValidationProvider name="Ad" v-slot="{ errors }" rules="required|min:2|max:255">
                    <v-text-field label="Ad" v-model="user.firstName" :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <ValidationProvider name="Soyad" v-slot="{ errors }" rules="required|min:2|max:255">
                    <v-text-field label="Soyad" v-model="user.lastName" :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email">
                    <v-text-field label="Email" v-model="user.mail" :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    clearable
                    auto-select-first
                    v-model="user.city"
                    :items="cityList"
                    label="Şehir"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field label="Öğrenci Telefon Numarası" v-model="user.phoneNumber"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <ValidationProvider name="Sınıf" v-slot="{ errors }" rules="excluded:0|required">
                    <v-select
                      prepend-icon="mdi-google-classroom"
                      label="Sınıf"
                      v-model="user.courseCategory"
                      :items="courseCategories"
                      item-text="name"
                      item-value="id"
                      return-object
                      :error-messages="errors"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="6" v-if="!salesmanView">
                  <v-text-field label="Kayıt Tarihi" v-model="user.registerDate" type="date"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <div class="d-flex">
                    <v-radio-group v-model="user.membershipDuration" class="mr-10">
                      <v-radio label="1 Yıllık" value="365"></v-radio>
                      <v-radio disabled="1" label="2 Yıllık" value="730"></v-radio>
                    </v-radio-group>
                    <v-text-field
                      label="Kayıt Süresi"
                      v-model="user.membershipDuration"
                      type="number"
                      suffix="gün"
                      :disabled="salesmanView"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Veli Ad-Soyad" v-model="user.parentName"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Veli Telefon Numarası" v-model="user.parentPhoneNumber"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" v-if="!salesmanView">
                  <v-select
                    prepend-icon="account_circle"
                    label="Satış Temsilcisi"
                    v-model="user.registeredBy"
                    :items="salesmanList"
                    item-text="firstName"
                    item-value="id"
                    return-object
                  >
                    <template v-slot:selection="data">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                    <template v-slot:item="data">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" v-if="!salesmanView">
                  <v-select
                    prepend-icon="account_circle"
                    label="Rehber Öğretmen"
                    v-model="user.advisoryTeacher"
                    :items="advisoryTeachers"
                    item-text="firstName"
                    item-value="id"
                    return-object
                  >
                    <template v-slot:selection="data">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                    <template v-slot:item="data">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" v-if="!salesmanView">
                  <v-select
                    prepend-icon="work"
                    label="Firma"
                    v-model="user.vendor"
                    :items="vendors"
                    item-text="name"
                    item-value="id"
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <template v-if="userId">
                <v-btn class="error" @click="delUser">
                  <v-icon left>delete</v-icon>
                  Sil
                </v-btn>
                <v-btn class="warning" @click="showResetPassDialog = true">Şifre Sıfırla</v-btn>
              </template>
              <v-switch v-if="!salesmanView" v-model="user.status" label="Üyeliği aktif?"></v-switch>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </ValidationObserver>
    </v-card>
    <reset-pass-dialog :userId="userId" v-model="showResetPassDialog" />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResetPassDialog from "./ResetPassDialog";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import moment from "moment";
import cities from "./cities.json";

export default {
  props: ["value", "userId", "salesmanView"],
  components: { ResetPassDialog, ValidationObserver, ValidationProvider },
  data: () => ({
    user: {},
    salesmanList: [],
    advisoryTeachers: [],
    showResetPassDialog: false,
    roles: [
      { value: "ROLE_USER", text: "Öğrenci" },
      { value: "ROLE_ADMIN", text: "Yönetici" },
      { value: "ROLE_ADVISORY", text: "Rehber Öğretmen" },
      { value: "ROLE_SALESMAN", text: "Satış Temsilcisi" },
      { value: "ROLE_STUDIO", text: "Stüdyo/Tanıtım" },
      { value: "ROLE_VENDOR", text: "Firma Yetkilisi" }
    ],
    cityList: cities,
    vendors: [],
    radioGroup: null
  }),
  watch: {
    value(val) {
      if (val && !this.salesmanView) {
        this.fetchCourseCategories("DefinitionProjection");
        this.getUserByRole("ROLE_ADVISORY").then(res => {
          this.advisoryTeachers = res;
        });
        this.getUserByRole("ROLE_SALESMAN").then(res => {
          this.salesmanList = res;
        });
        this.fetchVendors().then(res => {
          this.vendors = res;
        });

        this.user = {};
        if (this.userId) {
          this.getUser(this.userId, "UserDetailProjection").then(res => {
            this.user = res;
            this.user.roleList = res.roles;
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters("course", ["courseCategories"]),
    ...mapGetters("auth", ["currentUser"]),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    formTitle() {
      return this.userId ? "Kullanıcıyı Düzenle" : "Yeni Kullanıcı Oluştur";
    }
  },
  methods: {
    ...mapActions("user", ["getUser", "saveUser", "updateUser", "deleteUser", "getUserByRole", "fetchVendors"]),
    ...mapActions("course", ["fetchCourseCategories"]),

    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        if (this.salesmanView) {
          this.user.status = true;
          await this.getUser(this.currentUser.id, "UserDetailProjection").then(res => {
            this.user.vendor = res.vendor;
            this.user.registeredBy = res;
          });
          this.user.registerDate = moment().format("YYYY-MM-DD");
        }

        await this.saveUser(this.user);

        this.show = false;
        this.$emit("updated");
      }
    },

    delUser() {
      if (confirm("Bu kullanıcıya dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        this.deleteUser(this.user);
        this.show = false;
        this.$emit("updated");
      }
    }
  }
};
</script>
