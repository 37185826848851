<template>
  <v-card>
    <v-toolbar class="rounded-lg">
      <v-toolbar-title v-if="lecture && lecture.courseCategory">
        {{ lecture.courseCategory.name }} {{ lecture.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-text-field dense class="mt-4" prepend-icon="mdi-magnify" clearable v-model="search" label="Konu Ara" />
      </v-col>
    </v-toolbar>
    <v-row class="pa-5">
      <v-col v-for="subject in filtered" v-bind:key="subject.id" cols="12" sm="3">
        <SubjectCard :subject="subject" :filter="show" />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import SubjectCard from "./SubjectCard";
import ApiService from "../../common/api.service";
export default {
  components: { SubjectCard },
  data() {
    return {
      search: null,
      recordsDialog: false,
      subjects: [],
      filtered: [],
      lecture: { webinar: {} },
      show: ""
    };
  },
  async mounted() {
    await this.fetchSubjects();
    Promise.all([this.fetchLecture(), this.fetchFavSubjects()]);
    this.show = this.$route.query.show;
  },
  watch: {
    search() {
      this.filter();
    }
  },
  methods: {
    async fetchLecture() {
      let res = await ApiService.get(`lectures/${this.$route.params.id}?projection=LectureDetailsProjection`);
      this.lecture = res.data;
    },
    async fetchSubjects() {
      let res = await ApiService.get(`lectures/${this.$route.params.id}/subjects?projection=SubjectListProjection`);
      this.subjects = res.data["_embedded"].subjects;
      this.filtered = this.subjects;
    },
    async fetchFavSubjects() {
      let res = await ApiService.get(`users/search/getCurrentUsersFavSubjects?projection=SubjectListProjection`);
      let favs = res.data["_embedded"].subjects;
      favs.forEach(f => {
        let found = this.subjects.find(s => s.id === f.id);
        if (found) {
          this.$set(found, "favorited", true);
        }
      });
    },
    filter() {
      if (this.search) {
        const regex = new RegExp(this.search, "i");
        this.filtered = this.subjects.filter(subject => subject.name.search(regex) > -1);
      } else {
        this.filtered = this.subjects;
      }
    }
  }
};
</script>
