<template>
  <v-row style="flex-direction: column">
    <v-col>
      <v-sheet class="elevation-5" style="display: flex; align-items: center" color="#264653" min-height="50">
        <h2 class="font-weight-regular ma-2" style="color: #f1faee">
          <span id="question-content" v-html="question.content"></span>
        </h2>
      </v-sheet>
    </v-col>
    <v-col>
      <v-row style="flex-direction: column">
        <v-radio-group v-model="question.selectedAnswerId">
          <v-col v-for="answer in question.answers" v-bind:key="answer.id" style="padding-bottom: 0">
            <v-sheet
              style="cursor: pointer; display:flex; justify-content: space-between; align-items: center"
              color="#2a9d8f"
              class="elevation-5"
              min-height="50"
              @click="$emit('answerSelected', answer.id)"
            >
              <h2 class="font-weight-regular" style="color: #f1faee; margin-left: 5px">
                <span v-html="answer.title"></span>
              </h2>
              <v-radio color="#f4a261" dark :value="answer.id" />
            </v-sheet>
          </v-col>
        </v-radio-group>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["question"],
  name: "Question"
};
</script>

<style scoped></style>
