<template>
  <v-card flat rounded class="rounded-lg pa-3">
    <v-toolbar>
      <v-toolbar-title v-if="userCourseCategory">{{ userCourseCategory.name }} Deneme Sınavları</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-data-table
          item-key="name"
          class="elevation-1"
          hide-default-header
          :headers="headers"
          :items="exams"
          :disable-pagination="true"
        >
          <template v-slot:item.name="{ item }">
            <v-btn :to="{ name: 'quiz-attempt', params: { quizId: item.id } }" text color="primary">
              <strong>
                <p class="ma-0" style="font-size: 1.2rem">{{ item.name }}</p>
              </strong>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ApiService from "@/common/api.service";
import { StorageService, COURSE_CATEGORY } from "@/common/storage.service";

export default {
  name: "StudentExamList",
  data: () => ({
    headers: [{ value: "name" }, { timeLimit: "" }],
    userCourseCategory: {},
    exams: []
  }),
  mounted() {
    this.userCourseCategory = StorageService.getItem(COURSE_CATEGORY);

    ApiService.get(`quizzes/exams/${this.userCourseCategory.id}`).then(res => {
      this.exams = res.data
        .filter(q => q.isExam)
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
    });
  }
};
</script>

<style></style>
