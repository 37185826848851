<template>
  <v-app>
    <v-container class="text-center" fill-height style="height: calc(100vh - 58px);">
      <v-row align="center">
        <v-col>
          <h1 class="display-2 error--text">
            Teknik bir hata oluştu. Teknik destekle iletişime geçmeyi deneyebilirsiniz.
          </h1>

          <v-btn @click="onClick" color="secondary" outlined>
            Tekrar Dene
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
export default {
  methods: {
    onClick() {
      window.localStorage.clear();
      this.$router.push("/login");
    }
  }
};
</script>
