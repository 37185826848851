<template>
  <div>
    <v-progress-linear v-if="loading" class="loader" color="red" :value="loadingValue"></v-progress-linear>
    <v-overlay z-index="999" :value="loading"></v-overlay>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "loader",
  computed: {
    ...mapState("loader", ["loading", "requestsPending"]),
    loadingValue() {
      return 100 - this.requestsPending * 10;
    }
  }
};
</script>
<style lang="scss">
.loader {
  position: absolute;
  z-index: 999;
}
</style>
