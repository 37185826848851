import ApiService from "@/common/api.service";

const MaterialService = {
  getMaterials: function(subjectId) {
    let url = `subjects/${subjectId}/materials?projection=MaterialProjection`;
    return ApiService.get(url).then(res => {
      return res.data._embedded.materials;
    });
  },
  deleteMaterial: function(materialId) {
    let url = `materials/${materialId}`;
    return ApiService.delete(url);
  },
  updateMaterial: function(material) {
    let url = `materials/${material.id}`;
    return ApiService.put(url, material);
  },
  createMaterial: function(material) {
    return ApiService.post("materials", material);
  }
};

export default MaterialService;
