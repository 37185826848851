<template>
  <v-app id="inspire">
    <Loader />
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="d-flex justify-center mb-5">
              <img src="@/assets/logo2-big.png" style="max-width:330px; max-height:109px;" />
            </div>
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{ $t("common.login") }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <ul v-if="errors" class="error-messages">
                  <li v-for="(v, k) in errors" :key="k">{{ k }} {{ v | error }}</li>
                </ul>
                <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="onSubmit(email, password)">
                  <v-form>
                    <ValidationProvider name="Kullanıcı Adı" v-slot="{ errors }" rules="required">
                      <v-text-field
                        label="Kullanıcı Adı"
                        prepend-icon="account_circle"
                        type="text"
                        :error-messages="errors"
                        v-model="email"
                      />
                    </ValidationProvider>
                    <ValidationProvider name="Şifre" v-slot="{ errors }" rules="required|min:3">
                      <v-text-field
                        label="Şifre"
                        prepend-icon="lock"
                        type="password"
                        :error-messages="errors"
                        v-model="password"
                      />
                    </ValidationProvider>
                    <v-row justify="space-between">
                      <router-link style="margin-top: 5px; margin-left: 2px;" to="/forgot-password">
                        {{ $t("common.forgotPassword") }}
                      </router-link>
                      <v-btn type="submit" color="primary">
                        {{ $t("common.login") }}
                      </v-btn>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Loader from "@/components/Loader";

export default {
  components: {
    Loader,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      email: null,
      password: null
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "home" });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isAuthenticated: state => state.auth.isAuthenticated,
      user: state => state.auth.user
    })
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async onSubmit(email, password) {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          await this.login({ username: email, password });
        } catch (e) {
          this.$toast.error("Yanlış kullanıcı adı veya şifre girdiniz");
          return;
        }
        const resetPass = !!this.user.roles.find(s => s === "ROLE_CHANGE_PASSWORD");
        if (resetPass) {
          await this.$router.push("/update-password");
        } else {
          await this.$router.push(this.$route.query.redirect || "/");
        }
      }
    }
  }
};
</script>
