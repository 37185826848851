<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{ $t("user.resetPassword") }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <ul v-if="errors" class="error-messages">
                  <li v-for="(v, k) in errors" :key="k">{{ k }} {{ v | error }}</li>
                </ul>
                <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="onSubmit(password)">
                  <v-form>
                    <ValidationProvider name="Şifre" v-slot="{ errors }" rules="required|confirmed:confirm">
                      <v-text-field
                        autocomplete="off"
                        ref="password"
                        v-bind:label="$t('user.password')"
                        prepend-icon="lock"
                        type="password"
                        :error-messages="errors"
                        v-model="password"
                      />
                    </ValidationProvider>
                    <ValidationProvider vid="confirm" name="Şifre" v-slot="{ errors }" rules="required">
                      <v-text-field
                        autocomplete="off"
                        v-bind:label="$t('user.passwordAgain')"
                        prepend-icon="lock"
                        type="password"
                        :error-messages="errors"
                        v-model="passwordAgain"
                      />
                    </ValidationProvider>
                    <v-row justify="end">
                      <v-btn type="submit" color="primary">
                        {{ $t("common.update") }}
                      </v-btn>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mounted() {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      user: state => state.auth.user
    })
  },
  data() {
    return {
      password: null,
      passwordAgain: null
    };
  },
  methods: {
    async onSubmit(password) {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.updatePassword({ newPassword: password });
      }
    },
    ...mapActions("auth", ["updatePassword"])
  }
};
</script>
