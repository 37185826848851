var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Soru Sor")])]),_c('v-card-text',[_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Sınıf","rules":"excluded:0|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Sınıf","items":_vm.courseCategories,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.item.courseCategory),callback:function ($$v) {_vm.$set(_vm.item, "courseCategory", $$v)},expression:"item.courseCategory"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Ders","rules":"excluded:0|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Ders","items":_vm.lectures,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.item.lecture),callback:function ($$v) {_vm.$set(_vm.item, "lecture", $$v)},expression:"item.lecture"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Soru Başlığı","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Soru Başlığı"},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('editor',{attrs:{"content":_vm.item.content},on:{"update:content":function($event){return _vm.$set(_vm.item, "content", $event)}}})],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onCancel}},[_vm._v(" İptal ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSubmit}},[_vm._v(" Kaydet ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }