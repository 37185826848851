<template>
  <v-container id="list-my-salesmen" fluid tag="section">
    <v-card rounded class="rounded-lg pa-3">
      <v-row justify="center">
        <v-col>
          <v-data-table :headers="headers" :items="salesmenList">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Firma Satış Temsilcileri</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>
            <template v-slot:item.lastLogin="{ item }">
              {{ formatDate(item.lastLogin) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/common/api.service";
import moment from "moment";

export default {
  data: () => ({
    headers: [
      { text: "Kullanıcı Adı", value: "username" },
      { text: "İsim", value: "firstName" },
      { text: "Soyisim", value: "lastName" },
      { text: "Aktif", value: "status", sortable: false },
      { text: "Son Giriş Tarihi", value: "lastLogin" }
    ],
    salesmenList: []
  }),

  mounted() {
    moment.locale("tr");
    ApiService.get(`users/salesmen`).then(res => {
      this.salesmenList = res.data;
    });
  },

  methods: {
    formatDate(date) {
      if (date) return moment(date).fromNow();
    }
  }
};
</script>

<style></style>
