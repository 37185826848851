import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { StorageService, USER } from "./storage.service";
import { API_URL } from "./config";
import store from "../store/";
import router from "../routes";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    const toast = Vue.prototype.$toast;

    let requestsPending = 0;

    const req = {
      pending: () => {
        store.dispatch("loader/show");
      },
      done: () => {
        if (requestsPending <= 0) {
          store.dispatch("loader/hide");
        }
      }
    };
    axios.interceptors.request.use(
      config => {
        config.withCredentials = true;
        req.pending();
        const token = StorageService.getItem(USER).token;
        if (token && config.url !== "auth/signin") {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        requestsPending--;
        req.done();
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        req.done();
        return response;
      },
      async error => {
        if (error.response) {
          const originalRequest = error.config;
          if (
            error.response.status === 403 &&
            !originalRequest._retry &&
            originalRequest.url !== "/auth/refresh-token"
          ) {
            try {
              const access_token = await this.refreshAccessToken();
              console.log("refreshed");
              originalRequest._retry = true;
              axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
              return axios(originalRequest);
            } catch (e) {
              delete axios.defaults.headers.common["Authorization"];
            }
          }
          switch (error.response.status) {
            case 401:
              if (router.currentRoute.name !== "login") {
                toast.error("Lütfen tekrar giriş yapınız");
                store.dispatch("auth/logout");
              }
              break;
            case 400:
              toast.error(error.response.data.apierror.message);
              break;
            case 404:
              router.push("not-found");
              break;
          }
        }
        req.done();
        return Promise.reject(error);
      }
    );
    console.log(API_URL);
    Vue.axios.defaults.baseURL = API_URL;
  },

  async refreshAccessToken() {
    console.log("refreshing access token");
    let storageUser = StorageService.getItem(USER);
    try {
      let res = await axios("/auth/refresh-token", {
        method: "post",
        data: { userId: storageUser.id },
        withCredentials: true
      });
      let token = res.data.token;
      storageUser.token = token;
      StorageService.saveItem(USER, storageUser);
      return token;
    } catch (e) {
      store.dispatch("auth/logout");
    }
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource) {
    return Vue.axios.get(`${resource}`);
  },

  getWithoutValidate(resource) {
    return Vue.axios.get(`${resource}`, { validateStatus: false });
  },

  // eslint-disable-next-line no-unused-vars
  post(resource, params, success = "İşlem başarılı", fail) {
    const toast = Vue.prototype.$toast;
    return Vue.axios
      .post(`${resource}`, params)
      .then(result => {
        // toast.success(success);
        return result;
      })
      .catch(e => {
        console.log(e);
        if (fail) {
          toast.error(fail);
        } else {
          throw e;
        }
      });
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
