<template>
  <v-container id="list-my-users" fluid tag="section">
    <v-card rounded class="rounded-lg pa-3">
      <v-row class="d-flex justify-space-between">
        <v-col md="3">
          <v-btn class="primary" @click="editUser()">Yeni Öğrenci</v-btn>
        </v-col>
        <v-col md="3">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            :options.sync="options"
            :server-items-length="totalUsers"
            :loading="loading"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Kayıt Edilen Öğrenciler</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.firstName="{ item }">
              <router-link :to="{ name: 'profile', params: { id: item.id } }">
                {{ item.firstName }}
              </router-link>
            </template>
            <template v-slot:item.lastName="{ item }">
              <router-link :to="{ name: 'profile', params: { id: item.id } }">
                {{ item.lastName }}
              </router-link>
            </template>
            <template v-slot:item.lastLogin="{ item }">
              {{ formatDate(item.lastLogin) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="$router.push({ path: '/profile/' + item.id })">
                mdi-information-outline
              </v-icon>
            </template>
            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <EditDialog v-model="editDialog" @updated="getData" :salesmanView="true" />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { StorageService, USER } from "@/common/storage.service";
import EditDialog from "./EditDialog";
import moment from "moment";

export default {
  name: "MyStudents",
  components: { EditDialog },
  data: () => ({
    search: "",
    totalUsers: 0,
    options: {},
    users: [],
    loading: true,
    editDialog: false,
    headers: [
      { text: "İsim", value: "firstName" },
      { text: "Soyisim", value: "lastName" },
      { text: "Sınıf", value: "courseCategory.name" },
      { text: "Mail", value: "mail" },
      { text: "Aktif", value: "status", sortable: false },
      { text: "Son Giriş Tarihi", value: "lastLogin" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ]
  }),
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    },
    search() {
      this.getData();
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"])
  },
  mounted() {
    moment.locale("tr");
    if (this.checkRole("ROLE_SALESMAN")) {
      this.options.registeredBy = StorageService.getItem(USER).id;
    } else if (this.checkRole("ROLE_VENDOR")) {
      this.options.vendor = true;
    }
  },
  methods: {
    ...mapActions("user", ["fetchMyUsers", "fetchVendorUsers"]),

    async getData() {
      this.loading = true;
      this.options.keyword = this.search;

      let data;
      if (this.options.vendor) {
        data = await this.fetchVendorUsers(this.options);
      } else {
        data = await this.fetchMyUsers(this.options);
      }

      this.users = data["content"];
      this.totalUsers = data.totalElements;
      this.loading = false;
    },

    checkRole(role) {
      return this.currentUser.roles.find(user_role => {
        return role === user_role;
      });
    },

    formatDate(date) {
      if (!date) {
        return "Hiçbir zaman";
      }
      let parsedDate = moment(date);
      if (moment().diff(parsedDate, "days") > 1) return parsedDate.format("D.M.YYYY");

      return parsedDate.fromNow();
    },

    editUser(userId) {
      this.editedUserId = userId;
      this.editDialog = true;
    }
  }
};
</script>

<style></style>
