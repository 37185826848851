<template>
  <v-card class="rounded-lg">
    <v-toolbar flat>
      <v-toolbar-title>Test Raporu</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-expansion-panels :hover="true">
        <v-expansion-panel v-for="lecture in lectures" :key="lecture.id" @change="getLectureQuizReport(lecture.id)">
          <v-expansion-panel-header>
            <h3>{{ lecture.name }}</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="lectureQuizReport"
              :single-expand="true"
              item-key="id"
              show-expand
              v-if="lectureQuizReport"
              :hide-default-footer="true"
              :disable-pagination="true"
              @item-expanded="getSubjectQuizReport($event.item.id)"
            >
              <template v-slot:top>
                <div class="d-flex ma-5">
                  <div class="mt-4 mr-10">
                    <p>Toplam Test Sayısı : {{ totalQuizCount }}</p>
                    <p>Çözülen Test Sayısı : {{ finishedQuizCount }}</p>
                  </div>
                  <v-progress-circular :rotate="360" :size="100" :width="15" :value="completePercentage" color="teal">
                    {{ completePercentage }}
                  </v-progress-circular>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers }">
                <td :colspan="headers.length">
                  <v-simple-table class="ma-5">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Sınav Adı</th>
                          <th class="text-left">Puan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="report in subjectQuizReport" :key="report.id">
                          <td>{{ report.name }}</td>
                          <td>
                            <v-btn
                              :to="'/quiz-result/' + report.attemptid + '/' + report.id"
                              fab
                              x-small
                              color="green"
                              class="font-weight-bold"
                              style="font-size: 15px"
                            >
                              {{ report.score }}
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApiService from "@/common/api.service";

export default {
  props: ["user", "courseCategoryId"],
  name: "SubjectQuizReport",
  data() {
    return {
      lectureQuizReport: null,
      subjectQuizReport: null,
      expanded: [],
      headers: [
        { text: "Konu", value: "name" },
        { text: "Toplam Test Sayısı", value: "total" },
        { text: "Tamamlanan Test Sayısı", value: "finished" },
        { text: "Oran", value: "ratio" }
      ]
    };
  },
  computed: {
    ...mapGetters("course", ["lectures"]),
    totalQuizCount() {
      if (!this.lectureQuizReport) return 0;
      let total = 0;
      this.lectureQuizReport.forEach(report => {
        total += report.total;
      });

      return total;
    },
    finishedQuizCount() {
      if (!this.lectureQuizReport) return 0;
      let total = 0;
      this.lectureQuizReport.forEach(report => {
        total += report.finished;
      });

      return total;
    },
    completePercentage() {
      return ((this.finishedQuizCount / this.totalQuizCount) * 100).toFixed(0);
    }
  },
  mounted() {
    Promise.all([this.fetchLectures(this.courseCategoryId)]);
  },
  watch: {
    courseCategoryId: function() {
      Promise.all([this.fetchLectures(this.courseCategoryId)]);
    }
  },
  methods: {
    ...mapActions("course", ["fetchLectures"]),
    getLectureQuizReport(lectureId) {
      ApiService.get(`reports/lectureQuizReport?userId=${this.user.id}&lectureId=${lectureId}`).then(res => {
        this.lectureQuizReport = res.data;
        this.lectureQuizReport.filter(report => {
          if (!report.finished) {
            report.ratio = 0;
            return;
          }
          report.ratio = ((report.finished / report.total) * 100).toFixed(1);
        });
      });
    },
    getSubjectQuizReport(subjectId) {
      ApiService.get(`reports/subjectQuizReport?userId=${this.user.id}&subjectId=${subjectId}`).then(res => {
        this.subjectQuizReport = res.data;
      });
    }
  }
};
</script>
