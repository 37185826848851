<template>
  <v-container v-if="courseCategory" id="course-category-admin-detail" fluid tag="section">
    <div class="mb-12">
      <v-toolbar color="cyan" class="primary" dark>
        <v-toolbar-title v-if="courseCategory">
          {{ courseCategory.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <base-material-card icon="mdi-briefcase-outline" title="Dersler" class="px-5 py-3 mini-icon mb-12">
      <Lectures :courseCategoryId="courseCategory.id" />
    </base-material-card>
    <base-material-card icon="toc" title="Deneme Sınavları" class="px-5 py-3 mini-icon mb-12">
      <quiz-table :serverItemsLength="-1" :quizzes="quizzes" @newItem="addNew" :hasActions="false"></quiz-table>
    </base-material-card>
    <select-quiz-dialog v-model="openDialog" :categoryId="courseCategory.id" @onCancel="close" @onSave="save($event)" />
  </v-container>
</template>

<script>
import ApiService from "@/common/api.service";
import QuizTable from "../Quiz/QuizTable";
import SelectQuizDialog from "../Quiz/SelectQuizDialog";
import Lectures from "../Lecture/Lectures";

export default {
  name: "CourseCategoryDetail",
  components: { QuizTable, SelectQuizDialog, Lectures },
  data: () => ({
    courseCategory: null,
    quizzes: [],
    openDialog: false
  }),
  methods: {
    addNew() {
      this.openDialog = true;
    },
    initialize() {
      ApiService.get(`quizzes/search/getAllExams?courseCategoryId=${this.courseCategory.id}`).then(res => {
        this.quizzes = res.data._embedded.quizzes;
        this.openDialog = false;
      });
    },
    save(quiz) {
      let payload = {
        id: quiz.id,
        courseCategory: `courseCategories/${this.courseCategory.id}`
      };
      ApiService.patch(`quizzes/${quiz.id}`, payload).then(() => {
        this.initialize();
      });
    },
    close() {
      this.initialize();
    }
  },
  created() {
    let courseCategoryId = this.$route.params.categoryId;
    ApiService.get(`courseCategories/${courseCategoryId}`)
      .then(res => {
        this.courseCategory = res.data;
      })
      .then(() => {
        this.initialize();
      });
  }
};
</script>

<style></style>
