<template>
  <div>
    <v-row v-if="!showError" justify="space-around">
      <v-col xs="12" sm="6" md="6">
        <v-card class="elevation-5">
          <v-card-title style="background: #457b9d; height: 72px" class="white--text">
            Başarı Grafiği
          </v-card-title>
          <v-card-text style="max-width: 300px" class="mx-auto">
            <PieChart :chartData="chartData" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="6" md="6">
        <v-card class="elevation-5" style="padding-bottom: 16px">
          <v-card-title style="background: #457b9d; height: 72px" class="white--text">
            İstatistikler
          </v-card-title>
          <v-card-text style="min-height: 250px;">
            <v-list>
              <v-list-item>
                {{ totalQuestionCount }} sorudan {{ corrects.length }} soruya doğru cevap verdiniz.
              </v-list-item>
              <div v-if="quiz.isExam">
                <v-list-item v-for="examStat in examStats" v-bind:key="examStat.lecture">
                  <span style="margin-right: 2px">{{ examStat.lecture }}:</span>
                  <span style="color: #2a9d8f; margin-right: 5px">{{ examStat.correctCount }} Doğru /</span>
                  <span style="color: #e63946">{{ examStat.falseCount }} Yanlış</span>
                </v-list-item>
              </div>
              <v-list-item>100 üzerinden {{ attempt.score.toFixed(2) }} puan aldınız.</v-list-item>
              <v-list-item>Bu quizin not ortalaması: {{ avgScore.toFixed(2) }}.</v-list-item>
              <v-list-item>Bu quizi bitirmeniz {{ timeDistance }} sürdü.</v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" md="12" sm="12" lg="12">
        <v-card class="elevation-5">
          <v-tabs v-model="tab" background-color="#457b9d" centered dark grow icons-and-text>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Doğrularım
              <v-icon>mdi-emoticon-excited</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
              Yanlışlarım
              <v-icon>mdi-emoticon-sad</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Boşlarım
              <v-icon>mdi-emoticon-neutral</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-1'">
              <ResultQuestion
                v-for="correct in corrects"
                v-bind:key="correct.id"
                :question="correct.question"
                :correct-answer="correct.question.answers.filter(a => a.isCorrect)[0]"
                style="margin: 10px;"
              />
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
              <ResultQuestion
                v-for="falseAnswer in falses"
                v-bind:key="falseAnswer.id"
                :question="falseAnswer.question"
                :user-answer="falseAnswer.answer"
                :correct-answer="falseAnswer.question.answers.filter(a => a.isCorrect)[0]"
                style="margin: 10px;"
              />
            </v-tab-item>
            <v-tab-item :value="'tab-3'">
              <ResultQuestion
                v-for="blankAnswer in blanks"
                v-bind:key="blankAnswer.id"
                :question="blankAnswer"
                :user-answer="blankAnswer.answer"
                :correct-answer="blankAnswer.answers.filter(a => a.isCorrect)[0]"
                style="margin: 10px;"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="showError">Bu quiz girişimi henüz bitmemiş.</div>
  </div>
</template>

<script>
import PieChart from "./PieChart";
import ResultQuestion from "./ResultQuestion";
import { formatDistanceStrict } from "date-fns";
import { tr } from "date-fns/locale";
import { mapActions } from "vuex";

export default {
  components: { ResultQuestion, PieChart },
  name: "Result",
  mounted() {
    this.$store.commit("SET_DRAWER", false);
    const attemptId = this.$route.params.attemptId;
    const quizId = this.$route.params.quizId;
    Promise.all([
      this.fetchResult(attemptId),
      this.fetchUserProgresses(attemptId),
      this.fetchAttempt(attemptId),
      this.fetchAverageScore(quizId),
      this.fetchQuiz(quizId),
      this.fetchQuestionsWithAnswers(quizId)
    ]).then(([result, progresses, attempt, avgScore, quiz, allQuestions]) => {
      this.info = result;
      this.questions = progresses;
      this.attempt = attempt;
      this.avgScore = avgScore;
      this.quiz = quiz;
      this.allQuestions = allQuestions;
      this.filterQuestions();
    });
  },
  watch: {
    attempt: {
      deep: true,
      handler(val) {
        this.showError = val?.score < 0 || val.score == null;
      }
    }
  },
  data: function() {
    return {
      showError: false,
      quiz: {},
      tab: "this.messageI",
      info: { correctCount: 0, falseCount: 0, blankCount: 0 },
      questions: [],
      corrects: [],
      falses: [],
      blanks: [],
      allQuestions: [],
      attempt: {
        endTime: new Date(),
        startTime: new Date(),
        score: -1
      },
      avgScore: 0
    };
  },
  computed: {
    timeDistance() {
      const endTime = this.attempt?.endTime;
      const startTime = this.attempt?.startTime;

      return formatDistanceStrict(new Date(endTime), new Date(startTime), {
        locale: tr
      });
    },
    totalQuestionCount() {
      return this.info.correctCount + this.info.falseCount + this.info.blankCount;
    },
    chartData() {
      return {
        labels: ["Doğru", "Yanlış", "Boş"],
        datasets: [
          {
            data: [this.info.correctCount, this.info.falseCount, this.info.blankCount],
            backgroundColor: ["#2a9d8f", "#e63946", "#5e6472"],
            datalabels: {
              color: "white",
              labels: {
                title: {
                  font: {
                    weight: "bold"
                  }
                }
              }
            }
          }
        ]
      };
    },
    examStats() {
      let result = [];
      let correctsByLecture = this._.groupBy(this.corrects, "question.lecture.name");
      let falsesByLecture = this._.groupBy(this.falses, "question.lecture.name");

      for (const [key, value] of Object.entries(correctsByLecture)) {
        result.push({ lecture: key, correctCount: value.length, falseCount: 0 });
      }

      for (const [key, value] of Object.entries(falsesByLecture)) {
        let found = this._.find(result, ["lecture", key]);
        if (found) {
          found.falseCount = value.length;
        } else {
          result.push({ lecture: key, falseCount: value.length });
        }
      }

      return result;
    }
  },
  methods: {
    ...mapActions("quiz", [
      "fetchQuiz",
      "fetchAttempt",
      "fetchResult",
      "fetchUserProgresses",
      "fetchAverageScore",
      "fetchQuestionsWithAnswers"
    ]),
    filterQuestions() {
      this.corrects = this.questions.filter(q => q.answer?.isCorrect);
      this.falses = this.questions.filter(q => !q.answer?.isCorrect);
      this.blanks = this.allQuestions.filter(
        q =>
          this.corrects.findIndex(c => c.question.id === q.id) < 0 &&
          this.falses.findIndex(f => f.question.id === q.id) < 0
      );
    }
  }
};
</script>
