<template>
  <div>
    <v-alert class="mt-5" type="warning">Yönlendiriliyorsunuz. Lütfen bekleyiniz.</v-alert>
    <v-alert v-if="joinUrl" class="mt-5">
      Tarayıcınız yönlendirmeyi desteklemiyorsa
      <a :href="joinUrl">tıklayınız.</a>
    </v-alert>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  data: () => ({
    joinUrl: ""
  }),
  created() {
    let webinarId = this.$route.params.webinarId;

    ApiService.get(`webinars/${webinarId}/join`).then(res => {
      this.joinUrl = res.data.joinUrl;
      window.open(this.joinUrl);
    });
  }
};
</script>
