<template>
  <v-dialog v-model="show">
    <v-card>
      <v-card-title>
        <span class="headline">Soru Sor</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-form ref="form">
              <v-row justify="center">
                <v-col cols="6">
                  <ValidationProvider name="Sınıf" v-slot="{ errors }" rules="excluded:0|required">
                    <v-select
                      label="Sınıf"
                      :items="courseCategories"
                      v-model="item.courseCategory"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider name="Ders" v-slot="{ errors }" rules="excluded:0|required">
                    <v-select
                      label="Ders"
                      :items="lectures"
                      v-model="item.lecture"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <ValidationProvider name="Soru Başlığı" v-slot="{ errors }" rules="required">
                    <v-text-field v-model="item.title" :error-messages="errors" label="Soru Başlığı"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <editor :content.sync="item.content" />
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onCancel">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="onSubmit">
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "../../components/Editor";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    Editor,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({}),
  props: ["value", "item", "courseCategories", "lectures"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    async onSubmit() {
      let valid = await this.$refs.observer.validate();
      if (valid) {
        if (!this.item.content || this.item.content.trim() === "") {
          this.$toast.error("Soru içeriği boş olmamalı");
          return;
        }
        this.$emit("onSave");
        this.$refs.form.reset();
        this.$refs.observer.reset();
      }
    },
    onCancel() {
      this.$emit("onCancel");
      this.$refs.form.reset();
      this.$refs.observer.reset();
    }
  }
};
</script>
