<template>
  <v-container id="add-question" fluid tag="section">
    <div class="mb-12">
      <v-toolbar color="cyan" class="primary" dark>
        <v-toolbar-title>{{ quiz.name }} Soru Ekle</v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="openFeedbackMediaDialog = true" class="mr-2">Çözüm Videosu Ekle</v-btn>
          <v-btn @click="addAnswer" class="mr-2">Cevap Ekle</v-btn>
          <v-btn @click="saveQuestionAndAnswers" color="secondary">
            Kaydet
          </v-btn>
        </div>
      </v-toolbar>
    </div>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-row dense class="pa-0 ma-0">
        <v-col cols="2">
          <ValidationProvider v-if="quiz.isExam" name="Ders" v-slot="{ errors }" rules="excluded:0|required">
            <v-select
              v-model="question.lecture"
              dense
              outlined
              placeholder="Ders"
              :items="quiz.lectures"
              item-text="name"
              item-value="id"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <base-material-card color="blue">
        <template v-slot:heading>
          <div class="display-2 font-weight-light">Soru</div>
        </template>
        <v-container class="py-0">
          <v-row>
            <div class="editor">
              <editor :content.sync="question.content" />
            </div>
          </v-row>
        </v-container>
      </base-material-card>
      <hr />
      <base-material-card
        v-for="answer in answers"
        :key="answer.id"
        :color="answer.correct ? 'green' : 'red'"
        class="answer"
      >
        <template v-slot:heading>
          <v-row align-content="space-between">
            <div class="answer-heading">
              <div class="display-2 font-weight-light">{{ answer.id }}. Cevap</div>
              <div class="correct-answer-switch">
                <v-switch v-model="answer.correct" label="Doğru cevap?"></v-switch>
              </div>
            </div>
          </v-row>
        </template>
        <v-container class="py-0">
          <v-row>
            <div class="editor">
              <editor :content.sync="answer.content" />
            </div>
          </v-row>
        </v-container>
      </base-material-card>
    </ValidationObserver>
    <FeedbackMediaDialog @mediaUploaded="addFeedbackVideo($event)" v-model="openFeedbackMediaDialog" />
  </v-container>
</template>

<script>
import ApiService from "@/common/api.service";
import Editor from "@/components/Editor";
import FeedbackMediaDialog from "./FeedbackMediaDialog";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "add-question",
  components: {
    Editor,
    FeedbackMediaDialog,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      question: {},
      answers: [],
      quiz: {},
      openFeedbackMediaDialog: false
    };
  },
  created() {
    this.quizId = this.$route.params.quizId;
    if (this.quizId) {
      ApiService.get(`quizzes/${this.quizId}?projection=AddQuestionProjection`)
        .then(response => {
          this.quiz = response.data;
        })
        .catch(err => {
          this.$router.push("/");
          console.log(err);
        });
    }
    const minAnswerCount = 4;
    for (let i = 1; i <= minAnswerCount; i++) this.addAnswer();
  },
  methods: {
    addAnswer() {
      let id = this.answers.length + 1;
      let answer = {
        id: id,
        content: "",
        correct: false
      };
      this.answers.push(answer);
    },
    async saveQuestionAndAnswers() {
      let valid = await this.$refs.observer.validate();
      if (valid) {
        let questionPayload = {
          title: this.question.title,
          content: this.question.content,
          feedbackVideo: this.question.feedbackVideo,
          answers: this.answers.map(answer => {
            return {
              title: answer.content,
              isCorrect: answer.correct
            };
          })
        };
        ApiService.post("questions", questionPayload)
          .then(res => {
            return res.data;
          })
          .then(question => {
            ApiService.post("quizzes/add-question", {
              questionId: question.id,
              quizId: this.quizId,
              lectureId: this.question.lecture
            });
            this.$router.push({
              name: "quiz-detail",
              params: { quizId: this.quizId }
            });
          });
      }
    },
    addFeedbackVideo(videoId) {
      this.question.feedbackVideo = {
        id: videoId
      };
    }
  }
};
</script>

<style>
.editor {
  width: 100%;
}

.answer-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer .v-card--material__heading.pa-7 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
