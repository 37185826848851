<template>
  <div>
    <v-row v-if="!startDialog && !ended">
      <v-col cols="12" xs="10" md="10" sm="10" lg="6" offset-lg="2" offset-xl="2" xl="6">
        <Question :question="currQuestion" @answerSelected="updateAnswer" />
        <v-row justify="space-around">
          <v-btn
            class="elevation-5 font-weight-medium"
            color="#e76f51"
            :disabled="currQuestionIndex === 0"
            @click="previousQuestion()"
          >
            Önceki Soru
          </v-btn>
          <v-btn
            class="elevation-5 font-weight-medium"
            color="#ff6b6b"
            depressed
            :disabled="!currQuestion.selectedAnswerId"
            @click="updateAnswer(null)"
          >
            Yanıtı Temizle
          </v-btn>
          <v-btn
            color="#e76f51"
            class="elevation-5 font-weight-medium"
            :disabled="currQuestionIndex === questions.length - 1"
            @click="nextQuestion()"
          >
            Sonraki Soru
          </v-btn>
        </v-row>
        <v-row justify="center" class="ma-5">
          <v-btn color="#e63946" @click="showEndDialog()">
            Quizi Bitir
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" sm="2" offset-lg="2" lg="2" xl="2" offset-xl="2">
        <v-row style="overflow-y: auto; overflow-x: hidden" justify="center">
          <timer v-if="quiz.timeLimit" :timeLimit="quiz.timeLimit * 60" @timesUp="endQuiz" />
          <v-col style="padding-top: 0">
            <QuestionList :questions="questions" @showQuestion="showQuestion" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <StartDialog
      v-model="startDialog"
      :quiz="quiz"
      :attempts="attempts"
      @onStart="startQuiz()"
      @onCancel="cancelStart()"
    />
    <EndDialog v-model="endDialog" @onEnd="endQuiz()" @onCancel="cancelEnd()" />
  </div>
</template>

<script>
import Question from "./Question";
import ApiService from "../../common/api.service";
import StartDialog from "./StartDialog";
import { mapActions, mapGetters } from "vuex";
import EndDialog from "./EndDialog";
import QuestionList from "./QuestionList";
import Timer from "@/components/Quiz/Timer";

export default {
  name: "QuizAttempt",
  components: { EndDialog, StartDialog, Question, QuestionList, Timer },
  data: () => ({
    questions: [],
    currQuestion: {
      selectedAnswerId: null
    },
    currQuestionIndex: 0,
    startDialog: true,
    endDialog: false,
    attemptId: 0,
    showEndButton: false,
    ended: false,
    result: {},
    quiz: {},
    attempts: []
  }),
  async mounted() {
    this.$store.commit("SET_DRAWER", false);
    let quizId = this.$route.params.quizId;
    Promise.all([this.fetchMinQuestions(quizId), this.fetchQuiz(quizId), this.fetchUserAttempts(quizId)]).then(
      ([questions, quiz, attempts]) => {
        this.questions = this._.sortBy(questions, ["lecture.id"]);
        this.quiz = quiz;
        this.attempts = attempts;
        if (questions.length === 0) {
          this.$router.push("/");
        }
      }
    );
  },

  computed: {
    ...mapGetters("auth", ["currentUser"])
  },
  methods: {
    ...mapActions("quiz", ["fetchQuiz", "fetchMinQuestions", "fetchUserAttempts"]),
    updateAnswer(id) {
      this.$set(this.currQuestion, "selectedAnswerId", id);
      this.saveUserAnswer();
    },
    async fetchCurrQuestionWithAnswers() {
      const id = this.questions[this.currQuestionIndex].id;
      this.currQuestion = await this.fetchQuestionWithAnswers(id);
    },
    async fetchQuestionWithAnswers(id) {
      const res = await ApiService.get(`questions/${id}?projection=SecureQuestionProjection`);
      return res.data;
    },
    async startQuiz() {
      const id = this.$route.params.quizId;
      const res = await ApiService.post(`quizzes/start-attempt?quizId=${id}`);
      this.attemptId = res.data.id;
      this.startDialog = false;
      await this.fetchCurrQuestionWithAnswers();
    },
    async showEndDialog() {
      this.endDialog = true;
    },
    async endQuiz() {
      await ApiService.post(`quizzes/end-attempt?attemptId=${this.attemptId}`);
      this.ended = true;
      this.endDialog = false;
      this.$router.push(`/quiz-result/${this.attemptId}/${this.$route.params.quizId}`);
    },
    nextQuestion() {
      this.currQuestionIndex++;
      let question = this.questions[this.currQuestionIndex];
      this.showQuestion({ question, i: this.currQuestionIndex });
    },
    previousQuestion() {
      this.currQuestionIndex--;
      let question = this.questions[this.currQuestionIndex];
      this.showQuestion({ question, i: this.currQuestionIndex });
    },
    updateQuestionList() {
      let question = this.questions[this.currQuestionIndex];
      question.answered = !!this.currQuestion.selectedAnswerId;
      this.$set(this.questions, this.currQuestionIndex, question);
    },
    saveUserAnswer() {
      const userProgress = {
        userId: this.currentUser.id,
        questionId: this.currQuestion.id,
        answerId: this.currQuestion.selectedAnswerId,
        quizId: this.$route.params.quizId,
        attemptId: this.attemptId
      };

      ApiService.post("userProgress", userProgress, "Yanıtınız kaydedildi", "Bir hata oluştu");
      this.updateQuestionList();
    },
    cancelStart() {
      this.startDialog = false;
      this.$router.go(-1);
    },
    cancelEnd() {
      this.endDialog = false;
    },
    async showQuestion(data) {
      let questionWithAnswers = await this.fetchQuestionWithAnswers(data.question.id);
      let userProgress = await this.fetchUserProgressForQuestion(data.question.id);
      if (userProgress && userProgress.answer) {
        questionWithAnswers.selectedAnswerId = userProgress.answer.id;
      }

      this.currQuestion = questionWithAnswers;
      this.currQuestionIndex = data.i;
    },
    async fetchUserProgressForQuestion(questionId) {
      let res = await ApiService.get(`userProgress?attemptId=${this.attemptId}&questionId=${questionId}`);
      return res.data;
    }
  }
};
</script>

<style scoped></style>
