<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Kullanıcı Ara</span>
      </v-card-title>
      <form @submit.prevent="search()">
        <v-card-text>
          <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="search()">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field clearable v-model="filter.name" label="Ad - Soyad"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider name="Süre" v-slot="{ errors }" rules="integer|min_value:0">
                    <v-text-field
                      clearable
                      v-model="filter.expireIn"
                      :disabled="disableExpireIn"
                      label="X gün içinde kayıt süresi dolacak"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <v-select
                    clearable
                    label="Firma"
                    v-model="filter.vendor"
                    :items="vendors"
                    item-text="name"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    clearable
                    label="Rehber Öğretmen"
                    v-model="filter.advisoryTeacher"
                    :items="advisoryTeachers"
                    item-text="firstName"
                    item-value="id"
                  >
                    <template v-slot:selection="data">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                    <template v-slot:item="data">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    clearable
                    label="Satış Temsilcisi"
                    v-model="filter.registeredBy"
                    :items="salesmanList"
                    item-text="firstName"
                    item-value="id"
                  >
                    <template v-slot:selection="data">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                    <template v-slot:item="data">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-checkbox label="Aktif ve Kayıt Süresi Dolmuş Kullanıcılar" v-model="filter.activeAndExpired" />
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('onCancelSearch')">
            İptal
          </v-btn>
          <v-btn type="submit" color="blue darken-1" text @click="search()">
            Ara
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions } from "vuex";

export default {
  props: ["value", "filter"],
  components: { ValidationObserver, ValidationProvider },
  data: () => ({
    vendors: [],
    advisoryTeachers: [],
    salesmanList: [],
    disableExpireIn: false
  }),
  mounted() {
    Promise.all([this.fetchVendors(), this.getUserByRole("ROLE_ADVISORY"), this.getUserByRole("ROLE_SALESMAN")]).then(
      ([vendors, advisoryTeachers, salesmanList]) => {
        this.vendors = vendors;
        this.advisoryTeachers = advisoryTeachers;
        this.salesmanList = salesmanList;
      }
    );
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    activeAndExpired() {
      return this.filter.activeAndExpired;
    }
  },
  watch: {
    activeAndExpired(val) {
      if (val) {
        delete this.filter.expireIn;
        this.disableExpireIn = true;
      } else {
        this.disableExpireIn = false;
      }
    }
  },
  methods: {
    ...mapActions("user", ["fetchVendors", "getUserByRole"]),
    async search() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$emit("onSearch");
      }
    }
  }
};
</script>
