<template>
  <v-container id="list-badges" fluid tag="section">
    <v-card class="pa-3">
      <v-row justify="end">
        <v-col md="3">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table :headers="headers" :items="badges" :search="search">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Madalyalar</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="mb-2 black--text font-weight-bold" @click="editItem()">
                  Yeni Madalya
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.pic="{ item }">
              <v-avatar>
                <v-img :src="item.picUrl" />
              </v-avatar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <EditDialog
        v-model="editDialog"
        :edited-item="editedItem"
        :form-title="formTitle"
        @onSave="save"
        @onCancel="close"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditDialog from "./EditDialog";

export default {
  components: { EditDialog },
  data: () => ({
    search: "",
    editDialog: false,
    headers: [
      { text: "Madalya Adı", value: "name" },
      { text: "Görsel", value: "pic", sortable: false },
      { text: "Oluşturma", value: "createdDate" },
      { text: "Son Düzenleme", value: "lastModifiedDate" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      picUrl: null,
      mediaId: null
    },
    defaultItem: {
      name: "",
      picUrl: null,
      mediaId: null
    }
  }),

  computed: {
    ...mapGetters("badge", ["badges"]),
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Madalya" : "Madalyayı Düzenle";
    }
  },

  mounted() {
    Promise.all([this.fetchBadges()]);
  },

  watch: {
    editDialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions("badge", ["fetchBadges", "createBadge", "updateBadge", "deleteBadge"]),

    editItem(item) {
      if (item) {
        this.editedIndex = this.badges.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "name", "picUrl"]);
      } else {
        this.editedItem = { ...this.defaultItem };
      }
      this.editDialog = true;
    },

    save() {
      if (this.editedIndex > -1) {
        this.updateBadge(this.editedItem);
      } else {
        this.createBadge(this.editedItem);
      }
      this.close();
    },

    close() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItem(badge) {
      if (confirm("Bu madalyaya dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        this.deleteBadge(badge.id);
      }
    }
  }
};
</script>
