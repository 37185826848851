<script>
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  extends: Pie,
  mixins: [reactiveProp],
  data: function() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom"
        }
      }
    };
  }
};
</script>
