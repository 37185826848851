<template>
  <div v-if="privateProfile">
    <PrivateProfile />
  </div>
  <div v-else>
    <v-container class="mt-5" id="user-profile" fluid tag="section">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2 font-weight-regular">
                Kullanıcı Ayrıntıları
              </div>
            </template>

            <v-container class="py-0">
              <user-card :user="user" />
            </v-container>
          </base-material-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-material-card class="v-card-profile" :avatar="user.profilePicUrl">
            <v-card-text v-if="showUploadPic" class="text-center pa-0" style="margin-top: -25px">
              <v-btn color="primary" class="text-none mr-0" rounded depressed @click="$refs.uploader.click()">
                <v-icon left>
                  cloud_upload
                </v-icon>
                Fotoğrafı Güncelle
              </v-btn>
              <input ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged" />
            </v-card-text>
            <v-card-text class="text-center">
              <v-row justify="center" no-gutters dense>
                <v-col cols="12">
                  <h6 class="display-1 mb-1 grey--text">{{ courseCategory }}</h6>
                </v-col>
                <v-col cols="12">
                  <h4 class="display-2 font-weight-regular mb-3 black--text">
                    {{ fullName }} ({{ user.totalXp }} puan)
                  </h4>
                </v-col>
              </v-row>

              <v-row justify="center" align="center" dense>
                <v-col>
                  <v-btn
                    v-if="user.id === currentUser.id"
                    color="white"
                    class="font-weight-bold"
                    rounded
                    to="/profile/settings"
                  >
                    <span class="black--text">Profili Düzenle</span>
                  </v-btn>
                </v-col>

                <v-col>
                  <v-btn
                    v-if="authorizedToSeeNotes"
                    class="font-weight-bold"
                    color="white"
                    rounded
                    @click="showReport = true"
                  >
                    <span class="black--text">Karnesini Görüntüle</span>
                  </v-btn>
                </v-col>

                <v-col>
                  <v-btn
                    v-if="authorizedToSeeNotes"
                    class="font-weight-bold"
                    color="white"
                    rounded
                    @click="showLogs = true"
                  >
                    <span class="black--text">Kayıtları Görüntüle</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </base-material-card>
          <v-row v-if="user.badges.length > 0 || currentUser.roles.includes('ROLE_ADMIN')">
            <v-col cols="12">
              <base-material-card>
                <template v-slot:heading>
                  <div class="display-2 font-weight-regular">
                    Madalyalar
                  </div>
                </template>

                <v-container class="py-1">
                  <v-row>
                    <v-avatar v-for="badge in user.badges" :key="badge.id" size="128">
                      <img :src="badge.picUrl" :alt="badge.name" />
                    </v-avatar>
                  </v-row>
                  <v-row v-if="currentUser.roles.includes('ROLE_ADMIN')" justify="end">
                    <v-btn color="white" :to="/user-badges/ + user.id" rounded>
                      <span class="black--text">Ayrıntılar</span>
                    </v-btn>
                  </v-row>
                </v-container>
              </base-material-card>
            </v-col>
          </v-row>
          <v-row v-if="authorizedToSeeNotes">
            <v-col cols="12">
              <base-material-card>
                <template v-slot:heading>
                  <div class="display-2 font-weight-regular">
                    Notlar
                  </div>
                </template>

                <v-container class="py-1">
                  <v-row v-for="note in notes" :key="note.id">
                    <v-list-item
                      two-line
                      style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; display: inline-block;"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ note.text }}</v-list-item-title>
                        <v-list-item-subtitle>{{ note.createdDate }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-row justify="end">
                    <v-btn color="white" :to="/notes/ + user.id" rounded>
                      <span class="black--text">Ayrıntılar</span>
                    </v-btn>
                  </v-row>
                </v-container>
              </base-material-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <ReportDialog v-model="showReport" :userId="user.id" />
      <LogsDialog v-model="showLogs" :userId="user.id" />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserCard from "@/components/Profile/UserCard";
import ApiService from "../../common/api.service";
import ReportDialog from "../Report/ReportDialog";
import LogsDialog from "./LogsDialog";
import PrivateProfile from "./PrivateProfile";

export default {
  name: "profile",
  components: { UserCard, ReportDialog, LogsDialog, PrivateProfile },
  mounted() {
    this.initUser();
  },
  data() {
    return {
      user: {
        courseCategory: {},
        badges: []
      },
      notes: [],
      authorizedToSeeNotes: false,
      showReport: false,
      showLogs: false,
      privateProfile: false
    };
  },
  watch: {
    $route() {
      this.initUser();
    }
  },
  methods: {
    ...mapActions("user", ["getUserNotes", "uploadProfilePic"]),
    async initUser() {
      const userId = this.$route.params.id;
      try {
        this.user = await this.getUser(userId);
        this.privateProfile = false;
      } catch (e) {
        if (e.response.status === 406) {
          this.privateProfile = true;
        }
      }

      let authorizedRoles = ["ROLE_ADMIN", "ROLE_VENDOR", "ROLE_SALESMAN"];
      authorizedRoles.forEach(roleToCheck => {
        if (this.currentUser.roles.includes(roleToCheck)) {
          this.authorizedToSeeNotes = true;
        }
      });
      if (this.authorizedToSeeNotes) {
        this.notes = await this.getUserNotes(userId);
        if (this.notes) this.notes = this.notes.slice(0, 3);
      }
    },
    async getUser(userId) {
      let res = await ApiService.get(`users/${userId}?projection=UserDetailProjection`);
      return res.data;
    },
    async onFileChanged(e) {
      let file = e.target.files[0];
      if (file.size > 2000000) {
        this.$toast.error("Profil fotoğrafı 2 MB'dan büyük olmamalı");
      } else {
        try {
          await this.uploadProfilePic(file);
        } catch (e) {
          this.$toast.error("Yükleme sırasında hata oluştu");
        }
        this.initUser();
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    fullName() {
      let fullName = "";
      if (this.user.firstName) fullName = `${this.user.firstName} ${this.user.lastName}`;

      return fullName;
    },
    showUploadPic() {
      return this.currentUser.id === +this.$route.params.id;
    },
    courseCategory() {
      return this.user?.courseCategory?.name;
    }
  }
};
</script>
