<template>
  <v-card class="pa-3">
    <AllQuestionsTable />
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import ApiService from "../../common/api.service";
import AllQuestionsTable from "./AllQuestionsTable";

export default {
  components: { AllQuestionsTable },
  data: () => ({
    showAskQuestionDialog: false,
    editedItem: { courseCategory: 0, lecture: 0 },
    lectures: []
  }),
  methods: {
    ...mapActions("course", ["fetchCourseCategories"]),
    editItem(item) {
      if (item) {
        this.editedIndex = this.tableItems.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "name", "lecture"]);
      }

      this.showAskQuestionDialog = true;
    },
    save() {
      let data = {
        title: this.editedItem.title,
        content: this.editedItem.content,
        lecture: `lectures/${this.editedItem.lecture}`,
        courseCategory: `courseCategories/${this.editedItem.courseCategory}`
      };
      ApiService.post("qaQuestions", data);
      this.editedItem.content = "";
      this.showAskQuestionDialog = false;
    },
    close() {
      this.editedItem.content = "";
      this.showAskQuestionDialog = false;
    }
  }
};
</script>
