<template>
  <v-card flat tile class="mt-0">
    <v-toolbar rounded class="rounded-lg">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-col v-for="lecture in lectures" :key="lecture.id" cols="12" sm="6" md="4">
          <v-card rounded class="rounded-lg" @click="$emit('action', lecture.id)">
            <v-img :src="lecture.pictureUrl" height="300px"></v-img>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <span class="headline" v-text="lecture.name"></span>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { StorageService, COURSE_CATEGORY } from "@/common/storage.service";
import ApiService from "../../common/api.service";

export default {
  props: ["listTitle"],
  data: () => ({
    lectures: [],
    userCourseCategory: {}
  }),
  computed: {
    title() {
      return this.userCourseCategory.name + " " + this.listTitle;
    }
  },
  mounted() {
    this.userCourseCategory = StorageService.getItem(COURSE_CATEGORY);

    ApiService.get(`courseCategories/${this.userCourseCategory.id}/lectures`).then(res => {
      this.lectures = res.data._embedded.lectures;
    });
  }
};
</script>

<style></style>
