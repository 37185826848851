<template>
  <div>
    <vue-editor
      use-custom-image-handler
      @image-added="handleImageAdded"
      ref="editor"
      :editorOptions="editorSettings"
      v-model="editorContent"
    ></vue-editor>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { VueEditor, Quill } from "vue2-editor";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";

Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

export default {
  components: {
    VueEditor
  },
  props: ["content"],
  data() {
    return {
      editorSettings: {
        modules: {
          imageDropAndPaste: {
            handler: this.imageHandler
          }
        }
      }
    };
  },
  computed: {
    editorContent: {
      get() {
        return this.content;
      },
      set(updatedContent) {
        this.$emit("update:content", updatedContent);
      }
    }
  },
  methods: {
    imageHandler(_, type, imageData) {
      var file = imageData.toFile();
      this.uploadImage(file).then(res => {
        this.$refs.editor.quill.insertEmbed(0, "image", res.data.url);
      });
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.uploadImage(file).then(res => {
        Editor.insertEmbed(cursorLocation, "image", res.data.url);
        resetUploader();
      });
    },
    uploadImage(file) {
      var formData = new FormData();
      formData.append("file", file);
      return ApiService.post("medias/upload", formData);
    }
  }
};
</script>
<style>
.ql-editor img {
  width: 20vh;
}
</style>
