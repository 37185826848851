<template>
  <v-card class="pa-3">
    <MyQuestionsTable ref="myQuestionsTable" @editItem="editItem()" />
    <AskQuestionDialog
      v-model="showAskQuestionDialog"
      @onSave="save"
      @onCancel="close"
      :item="editedItem"
      :courseCategories="courseCategories"
      :lectures="lectures"
    />
  </v-card>
</template>
<script>
import AskQuestionDialog from "./AskQuestionDialog";
import { mapActions, mapGetters } from "vuex";
import ApiService from "../../common/api.service";
import MyQuestionsTable from "./MyQuestionsTable";

export default {
  components: { MyQuestionsTable, AskQuestionDialog },
  data: () => ({
    showAskQuestionDialog: false,
    editedItem: { courseCategory: 0, lecture: 0 },
    lectures: []
  }),
  computed: {
    ...mapGetters("course", ["courseCategories"]),
    courseCategory() {
      return this.editedItem.courseCategory;
    }
  },
  mounted() {
    Promise.all([this.fetchCourseCategories("CourseCategory")]);
  },
  watch: {
    courseCategory(val) {
      this.editedItem.lecture = 0;
      this.lectures = this._.find(this.courseCategories, {
        id: val
      })?.lectures;
    }
  },
  methods: {
    ...mapActions("course", ["fetchCourseCategories"]),
    editItem(item) {
      if (item) {
        this.editedIndex = this.tableItems.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "name", "lecture"]);
      }

      this.showAskQuestionDialog = true;
    },
    async save() {
      let data = {
        title: this.editedItem.title,
        content: this.editedItem.content,
        lecture: { id: this.editedItem.lecture },
        courseCategory: { id: this.editedItem.courseCategory }
      };
      await ApiService.post("qa/addQuestion", data);
      this.editedItem.content = "";
      this.showAskQuestionDialog = false;
      this.$refs.myQuestionsTable.getMyQuestions();
    },
    close() {
      this.editedItem.content = "";
      this.showAskQuestionDialog = false;
    }
  }
};
</script>
