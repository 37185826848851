import ApiService from "../../common/api.service";

const announcement = {
  namespaced: true,
  state: {
    announcements: [],
    visible: false
  },
  actions: {
    async fetchCurrentAnnouncements({ commit }) {
      let res = await ApiService.get("announcements/search/getActiveAnnouncements");
      commit("setCurrentAnnouncements", res.data["_embedded"].announcements);
    }
  },
  mutations: {
    setCurrentAnnouncements(state, announcements) {
      state.announcements = announcements;
      if (announcements.length > 0) {
        state.visible = true;
      }
    },
    show(state) {
      state.visible = true;
    },
    hide(state) {
      state.visible = false;
    }
  },
  getters: {
    announcements(state) {
      return state.announcements;
    },
    visible(state) {
      return state.visible;
    }
  }
};

export default announcement;
