<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row justify="end">
            <v-col cols="12">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                v-model="selectedItems"
                :items-per-page="5"
                show-select
                single-select
                :headers="headers"
                :items="badges"
                :search="search"
                @item-selected="badgeSelected"
              >
                <template v-slot:item.badgeImg="{ item }">
                  <v-avatar>
                    <v-img :src="item.picUrl" />
                  </v-avatar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('onCancel')">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="onSave()">
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "editedItem", "formTitle", "badges"],
  data: () => ({
    search: "",
    headers: [
      { text: "Madalya Adı", value: "name" },
      { text: "Görsel", value: "badgeImg" }
    ],
    selectedBadgeId: null,
    selectedItems: []
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    badgeSelected(select) {
      if (select.value) {
        this.selectedBadgeId = select.item.id;
      } else {
        this.selectedBadgeId = null;
      }
    },
    onSave() {
      if (this.selectedBadgeId) {
        this.$emit("onSave", this.selectedBadgeId);
        this.selectedItems = [];
      } else {
        this.$toast.error("Bir seçim yapınız");
      }
    }
  }
};
</script>
