<template>
  <v-card rounded class="rounded-lg" v-if="isActive">
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>{{ examSchedule.name }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div v-if="isActive && !started">
        <v-alert border="top" colored-border type="info" elevation="2" class="display-1">
          <b>{{ examSchedule.name }}</b>
          <b>&nbsp;{{ formatDate }}</b>
          tarihinde
          <b>{{ formatTime }}</b>
          saatleri arasında yapılacaktır.
          <br />
          Sınav saati geldiğinde aşağıdaki butona tıklayarak sınava katılacabileceksiniz.
        </v-alert>

        <v-alert text dense color="teal" icon="mdi-clock-fast" border="left">
          Sınava kalan süre : {{ examSchedule.start.fromNow(true) }}
        </v-alert>
      </div>
      <div v-if="started">
        <v-alert border="top" colored-border type="info" elevation="2" class="display-1">
          Sınav başlamıştır. Aşağıdaki butona tıklayarak sınava katılabilirsiniz.
          <b><u>Sınava yalnızca bir defa katılabileceğinizi unutmayın!</u></b>
        </v-alert>
        <v-alert text dense color="teal" icon="hourglass_empty" border="left">
          Sınavın bitmesine kalan süre :
          <b>{{ examSchedule.end.fromNow(true) }}</b>
        </v-alert>
      </div>
      <div style="text-align:center">
        <v-btn @click="startAttempt" :disabled="!started" color="primary">
          {{ courseCategory.name }} Deneme Sınavını Başlat
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import examScheduleJson from "@/common/examSchedule.json";
import moment from "moment";

export default {
  name: "ExamSchedule",
  props: ["courseCategory"],
  data() {
    return {
      examSchedule: examScheduleJson,
      isActive: false,
      started: false
    };
  },
  created() {
    moment.locale("tr");
    this.examSchedule.start = moment(this.examSchedule.start, "DD.MM.YYYY - HH:mm:ss");
    this.examSchedule.end = moment(this.examSchedule.end, "DD.MM.YYYY - HH:mm:ss");
    var aWeekAgo = this.examSchedule.start.clone().subtract(7, "days");
    var now = moment();

    if (now.isBetween(aWeekAgo, this.examSchedule.end)) {
      this.isActive = true;

      if (now.isBetween(this.examSchedule.start, this.examSchedule.end)) this.started = true;
    }
  },
  computed: {
    formatDate() {
      return this.examSchedule.start.format("Do MMMM dddd");
    },
    formatTime() {
      return this.examSchedule.start.format("HH") + " - " + this.examSchedule.end.format("HH");
    }
  },
  methods: {
    startAttempt() {
      let exam = this.examSchedule.ids.filter(id => {
        return id.courseCategoryId == this.courseCategory.id;
      });

      this.$router.push({ name: "quiz-attempt", params: { quizId: exam[0].examId } });
    }
  }
};
</script>

<style></style>
