<template>
  <v-container id="quiz-detail" fluid tag="section">
    <div class="mb-12">
      <v-toolbar color="cyan" class="primary" dark>
        <v-toolbar-title>{{ quiz.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <base-material-card icon="toc" title="Sorular" class="px-5 py-3 mini-icon mb-12">
      <template v-slot:header-actions>
        <router-link :to="{ name: 'add-question-to-quiz', params: { quizId: quiz.id } }">
          <v-btn color="primary" small>Soru Ekle</v-btn>
        </router-link>
      </template>
      <v-data-table :headers="questionTableHeader" :items="quiz.questions" sort-by="id" class="elevation-1">
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'edit-question', params: { questionId: item.id }, query: { quizId: quiz.id } }">
            <v-icon small class="mr-2">
              mdi-pencil
            </v-icon>
          </router-link>
          <v-icon small @click="deleteQuestion(item)">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.content="{ item }">
          <span v-html="item.content" />
        </template>
      </v-data-table>
    </base-material-card>

    <base-material-card icon="assessment" title="Katılımlar" class="px-5 py-3 mini-icon">
      <template v-slot:header-actions>
        <v-btn class="error" @click="reCalculate">Yeniden değerlendir</v-btn>
      </template>
      <v-data-table :headers="attempsTableHeader" :items="attempts" sort-by="id" class="elevation-1">
        <template v-slot:item.attemptTime="{ item }">
          {{ timeDistance(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn color="primary" small @click="reviewAttempt(item)">İncele</v-btn>
          &nbsp;
          <v-btn color="red" small @click="deleteAttempt(item)">Sil</v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import ApiService from "@/common/api.service";
import { mapActions } from "vuex";
import { formatDistanceStrict } from "date-fns";
import { tr } from "date-fns/locale";

export default {
  name: "quiz-detail",
  data() {
    return {
      quiz: {},
      questionTableHeader: [
        { text: "Soru", sortable: false, value: "content" },
        { text: "Eklenme Tarihi", sortable: true, value: "createdDate" },
        { text: "Aksiyon", value: "actions", sortable: false }
      ],
      attempsTableHeader: [
        { text: "Öğrenci Adı", value: "studentName" },
        { text: "Puan", value: "score" },
        { text: "Sınav Süresi", value: "attemptTime" },
        { text: "Aksiyon", value: "actions", sortable: false }
      ],
      attempts: []
    };
  },
  methods: {
    ...mapActions("quiz", ["fetchAttempts", "fetchQuizWithQuestions"]),
    timeDistance(item) {
      return formatDistanceStrict(new Date(item.endTime), new Date(item.startTime), {
        locale: tr
      });
    },
    deleteQuestion(question) {
      this.$store.dispatch("loader/show");
      ApiService.delete(`questions/${question.id}`)
        .then(() => {
          this.initialize();
        })
        .finally(() => {
          this.$store.dispatch("loader/hide");
        });
    },
    reviewAttempt(attempt) {
      this.$router.push(`/quiz-result/${attempt.id}/${attempt.quiz}`);
    },
    async reCalculate() {
      await ApiService.get(`quizzes/${this.quiz.id}/recalculate`);
      this.attempts = await this.fetchAttempts(this.quiz.id);
    },
    async deleteAttempt(attempt) {
      await ApiService.post(`quizzes/delete-attempt?attemptId=${attempt.id}`);
      this.attempts = await this.fetchAttempts(this.quiz.id);
    },
    async initialize() {
      try {
        let quizId = this.$route.params.quizId;
        Promise.all([this.fetchQuizWithQuestions(quizId), this.fetchAttempts(quizId)]).then(([quiz, attempts]) => {
          this.quiz = quiz;
          this.attempts = attempts;
        });
      } catch (e) {
        console.log(e);
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/quizzes");
      }
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style></style>
