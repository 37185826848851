<template>
  <v-app>
    <Loader />

    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
  </v-app>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./Main/AppBar"),
    DashboardCoreDrawer: () => import("./Main/Drawer"),
    DashboardCoreView: () => import("./Main/View"),
    Loader
  },

  data: () => ({
    expandOnHover: false
  })
};
</script>
