<template>
  <v-card flat tile class="mt-0">
    <v-toolbar rounded class="rounded-lg">
      <v-toolbar-title>Deneyler</v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col v-for="(experiment, index) in experiments" :key="index" cols="12" sm="6" md="4">
          <v-card rounded class="rounded-lg" @click="showDetailDialog(experiment)">
            <v-img :src="experiment.thumbnail_url" height="300px"></v-img>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <span v-text="experiment.name"></span>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ExperimentsDetail v-model="detailDialog" :experiment="selectedExperiment" />
  </v-card>
</template>

<script>
import experimentsList from "./experiments.json";
import ExperimentsDetail from "./ExperimentDetail";

export default {
  name: "ExperimentsList",
  components: { ExperimentsDetail },
  data() {
    return {
      experiments: experimentsList,
      selectedExperiment: null,
      detailDialog: false
    };
  },
  methods: {
    showDetailDialog(experiment) {
      this.selectedExperiment = experiment;
      this.detailDialog = true;
    }
  }
};
</script>
