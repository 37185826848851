<template>
  <v-container id="list-users" fluid tag="section">
    <v-card class="pa-3 rounded-lg">
      <v-row class="d-flex justify-space-between">
        <v-col md="3">
          <v-btn class="primary" @click="editUser()">Yeni Kullanıcı</v-btn>
        </v-col>
        <v-row dense justify="center">
          <v-col md="6">
            <v-text-field
              v-model="filter.name"
              append-icon="mdi-magnify"
              label="Ad-Soyad ara"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
        <v-col md="3" class="d-flex justify-end">
          <v-btn class="primary" @click="filterDialog = true">Filtrele</v-btn>
          <v-btn v-if="filter.filtered" class="secondary" @click="clearFilter()">Filtreyi Temizle</v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="users"
            :search="filter.name"
            :options.sync="options"
            :server-items-length="totalUsers"
            :loading="loading"
            :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
          >
            <template v-slot:item.firstName="{ item }">
              <router-link class="font-weight-regular" :to="{ name: 'profile', params: { id: item.id } }">
                {{ item.firstName }}
              </router-link>
            </template>
            <template v-slot:item.lastName="{ item }">
              <router-link class="font-weight-regular" :to="{ name: 'profile', params: { id: item.id } }">
                {{ item.lastName }}
              </router-link>
            </template>
            <template v-slot:item.lastLogin="{ item }">
              {{ formatDate(item.lastLogin) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editUser(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="$router.push({ path: '/profile/' + item.id })">
                mdi-information-outline
              </v-icon>
            </template>
            <template v-slot:item.status="{ item }">
              <v-simple-checkbox v-model="item.status" disabled></v-simple-checkbox>
            </template>
            <template v-slot:item.expireAt="{ item }">
              <span :class="isExpired(item.expireAt) ? 'red--text' : ''">{{ item.expireAt }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <EditDialog v-model="editDialog" :userId="editedUserId" @updated="getData" />
      <FilterDialog v-model="filterDialog" :filter="filter" @onSearch="search" @onCancelSearch="onCancelSearch" />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import EditDialog from "./EditDialog";
import moment from "moment";
import FilterDialog from "@/views/UserList/FilterDialog";

export default {
  components: { FilterDialog, EditDialog },
  data: () => ({
    filter: {
      filtered: false
    },
    totalUsers: 0,
    options: {},
    users: [],
    loading: true,
    editDialog: false,
    filterDialog: false,
    headers: [
      { text: "İsim", value: "firstName" },
      { text: "Soyisim", value: "lastName" },
      { text: "Sınıf", value: "courseCategory.name" },
      { text: "Mail", value: "mail" },
      { text: "Kayıt Tarihi", value: "registerDate" },
      { text: "Sona Erme Tarihi", value: "expireAt" },
      { text: "Son Giriş", value: "lastLogin" },
      { text: "Aktif", value: "status", sortable: false },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    editedUserId: null
  }),

  mounted() {
    moment.locale("tr");
    this.getData = this.lodash.debounce(this.getData, 300);
  },

  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    },
    filterName() {
      if (!this.filterDialog && !this.filter.clearing) {
        this.search();
      }
    }
  },
  computed: {
    filterName() {
      return this.filter.name;
    }
  },

  methods: {
    ...mapActions("user", ["searchUsers"]),

    async getData() {
      this.loading = true;
      let wrapper = { options: this.options, filter: this.filter };

      let data = await this.searchUsers(wrapper);
      this.users = data.content;
      this.totalUsers = data.totalElements;

      this.loading = false;
    },

    async search() {
      this.options.page = 1;
      await this.getData();
      this.filter.filtered = true;
      this.filterDialog = false;
    },
    onCancelSearch() {
      this.filterDialog = false;
    },
    async clearFilter() {
      this.filter = { filtered: false, clearing: true };
      await this.getData();
      this.filter = { filtered: false, clearing: false };
    },

    editUser(userId) {
      this.editedUserId = userId;
      this.editDialog = true;
    },

    formatDate(date) {
      if (!date) {
        return "Hiçbir zaman";
      }
      let parsedDate = moment(date);
      if (moment().diff(parsedDate, "days") > 1) return parsedDate.format("D.M.YYYY");

      return parsedDate.fromNow();
    },
    isExpired(expireAt) {
      return moment().isAfter(moment(expireAt, "DD-MM-YYYY"));
    }
  }
};
</script>
