<template>
  <v-container id="lecture-admin-detail" fluid tag="section">
    <div class="mb-12">
      <v-toolbar color="cyan" class="primary" dark>
        <v-toolbar-title v-if="subject">
          {{ subject.lecture.courseCategory.name }} / {{ subject.lecture.name }} / {{ subject.name }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </div>
    <materials :subject="subject"></materials>
    <base-material-card icon="video_library" title="Sınavlar" class="px-5 py-3 mini-icon mb-12">
      <Quizzes v-if="subject" :subject="subject" />
    </base-material-card>
  </v-container>
</template>

<script>
import ApiService from "@/common/api.service";
import Materials from "./partials/Materials/MaterialList";
import Quizzes from "../Quiz/Quizzes";

export default {
  components: { Materials, Quizzes },
  data() {
    return {
      subject: null
    };
  },
  created() {
    let subjectId = this.$route.params.subjectId;
    ApiService.get(`subjects/${subjectId}?projection=SubjectDetailProjection`).then(res => {
      this.subject = res.data;
    });
  }
};
</script>

<style></style>
