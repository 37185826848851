<template>
  <v-container id="lecture-admin-detail" fluid tag="section">
    <base-material-card color="green">
      <template v-slot:heading>
        <div class="display-2 font-weight-light">{{ title }}</div>
      </template>
      <v-container class="py-0">
        <v-row>
          <v-text-field class="mt-2" label="Materyal Adı" outlined v-model="material.name"></v-text-field>
        </v-row>
        <v-row>
          <div style="width:100%">
            <vue-dropzone
              @vdropzone-sending="sendingEvent"
              @vdropzone-success="success"
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
            ></vue-dropzone>
          </div>
        </v-row>
        <br />
        <v-row>
          <v-select
            v-model="mediaType"
            :items="['VIDEO', 'DOCUMENT', 'PICTURE']"
            filled
            label="Materyal Türü"
          ></v-select>
        </v-row>
        <div class="text-right">
          <v-btn @click="save" class="primary">Kaydet</v-btn>
        </div>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { StorageService, USER } from "@/common/storage.service";
import MaterialService from "./material.service";

export default {
  name: "AddMaterial",
  components: {
    vueDropzone: vue2Dropzone
  },
  data: () => ({
    subjectId: null,
    material: {},
    mediaType: "",
    dropzoneOptions: {
      timeout: 0,
      url: `${process.env.VUE_APP_API_URL}medias/upload`,
      thumbnailWidth: 150,
      addRemoveLinks: true,
      dictDefaultMessage: "Dosya Yükle",
      headers: {
        Authorization: `Bearer ${StorageService.getItem(USER).token}`,
        "Cache-Control": "",
        "X-Requested-With": ""
      }
    }
  }),
  methods: {
    sendingEvent(file, xhr, formData) {
      formData.append("type", this.mediaType);
    },
    success(file, response) {
      this.material = { ...this.material, media: `media/${response.id}` };
    },
    save() {
      MaterialService.createMaterial(this.material).then(() => {
        this.$router.push({
          name: "subjects-admin-detail",
          params: { subjectId: this.subjectId }
        });
      });
    }
  },
  created() {
    this.subjectId = this.$route.params.subjectId;
    this.material = { ...this.material, subject: `subjects/${this.subjectId}` };
  },
  computed: {
    title() {
      return "Yeni Materyal";
    }
  }
};
</script>

<style src="vue2-dropzone/dist/vue2Dropzone.min.css"></style>
