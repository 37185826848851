<template>
  <div style="overflow-y: scroll; overflow-x: hidden; max-height: calc( 100vh - 200px );" class="custom-scrollbar">
    <v-row v-for="(question, i) in questions" v-bind:key="question.id">
      <v-col style="padding-bottom: 0">
        <v-sheet class="elevation-5" :color="question.answered ? '#355070' : '#6d597a'">
          <v-btn
            @click="$emit('showQuestion', { question, i })"
            text
            width="100%"
            class="white--text font-weight-medium"
          >
            Soru {{ i + 1 }}
          </v-btn>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["questions"]
};
</script>
<style scoped>
.custom-scrollbar {
  scrollbar-color: #e76f51 #d1d1d1;
  scrollbar-width: thin;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #d1d1d1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #e76f51;
}
</style>
