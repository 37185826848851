<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card v-if="lecture">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ lecture.lectureName }} / Video İzlenme Oranı</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="show = false">
            İptal
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <template v-for="(subject, index) in lectureSubjects">
          <div :key="index">
            <v-toolbar flat>
              <v-toolbar-title>{{ subject.subjectName }}</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Video Adı
                    </th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="video in subject.videos" :key="video.materialId">
                    <td>{{ video.materialName }}</td>
                    <td style="text-align:right;">
                      <v-chip v-if="video.hasWatched" class="ma-2" color="green" text-color="white">
                        İzlendi
                      </v-chip>
                      <v-chip v-else class="ma-2" color="red" text-color="white">
                        İzlenmedi
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "VideoReportDetail",
  props: ["value", "userId", "lecture"],
  data() {
    return {
      lectureSubjects: []
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {
    value(val) {
      if (val) {
        ApiService.get(`reports/videoReportDetail?userId=${this.userId}&lectureId=${this.lecture.lectureId}`).then(
          res => {
            this.lectureSubjects = res.data;
          }
        );
      }
    }
  }
};
</script>
