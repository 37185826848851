import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import VuetifyToast from "vuetify-toast-snackbar";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import "@/sass/overrides.sass";
import tr from "vuetify/es5/locale/tr";

const requireComponent = require.context("@/components/Base", true, /\.vue$/);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, "")));

  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig);
});

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
});

const theme = {
  primary: "#3D75C1",
  secondary: "#9C27b0",
  accent: "#9C27b0",
  info: "#00CAE3"
};

const vuetifyObj = new Vuetify({
  icons: {
    iconfont: "fa" // default - only for display purposes
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  },
  lang: {
    locales: { tr },
    current: "tr"
  }
});

export default vuetifyObj;

Vue.use(VuetifyToast, {
  $vuetify: vuetifyObj.framework,
  x: "right", // default
  y: "top", // default
  color: "info", // default
  icon: "info",
  iconColor: "", // default
  classes: ["body-2"],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: true, // default
  showClose: true, // default
  closeText: "", // default
  closeIcon: "close", // default
  closeColor: "", // default
  slot: [], //default
  shorts: {
    custom: {
      color: "purple"
    }
  },
  property: "$toast"
});
