<template>
  <v-container id="add-question" fluid tag="section">
    <div class="mb-12">
      <v-toolbar color="cyan" class="primary" dark>
        <v-toolbar-title>{{ question.title }} Düzenleniyor</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="openFeedbackMediaDialog = true" class="mr-2">Çözüm Videosu Ekle</v-btn>
        <v-btn @click="addAnswer" class="mr-2">Cevap Ekle</v-btn>
        <v-btn @click="updateQuestion" color="secondary">Kaydet</v-btn>
      </v-toolbar>
    </div>
    <v-row dense class="pa-0 ma-0">
      <v-col cols="2">
        <ValidationProvider v-if="quiz.isExam" name="Ders" v-slot="{ errors }" rules="excluded:0|required">
          <v-select
            v-model="question.lecture"
            dense
            outlined
            placeholder="Ders"
            :items="quiz.lectures"
            item-text="name"
            item-value="id"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <base-material-card color="blue">
      <template v-slot:heading>
        <div class="display-2 font-weight-light">Soru</div>
      </template>
      <v-container class="py-0">
        <v-row>
          <div class="editor">
            <editor :content.sync="question.content" />
          </div>
        </v-row>
      </v-container>
    </base-material-card>
    <hr />
    <base-material-card
      v-for="(answer, key) in question.answers"
      :key="answer.id"
      :color="answer.isCorrect ? 'green' : 'red'"
      class="answer"
    >
      <template v-slot:heading>
        <v-row align-content="space-between">
          <div class="answer-heading">
            <div class="display-2 font-weight-light">{{ key + 1 }}. Cevap</div>
            <div class="correct-answer-switch">
              <v-switch v-model="answer.isCorrect" label="Doğru cevap?"></v-switch>
            </div>
          </div>
        </v-row>
      </template>
      <v-container class="py-0">
        <v-row>
          <div class="editor">
            <editor :content.sync="answer.title" />
          </div>
        </v-row>
      </v-container>
    </base-material-card>
    <FeedbackMediaDialog
      @mediaUploaded="addFeedbackVideo($event)"
      v-model="openFeedbackMediaDialog"
      :feedbackVideo="question.feedbackVideo"
    />
  </v-container>
</template>

<script>
import ApiService from "@/common/api.service";
import Editor from "@/components/Editor";
import FeedbackMediaDialog from "./FeedbackMediaDialog";
import { ValidationProvider } from "vee-validate";

export default {
  name: "EditQuestion",
  components: {
    Editor,
    FeedbackMediaDialog,
    ValidationProvider
  },
  data() {
    return {
      question: {},
      quiz: {},
      openFeedbackMediaDialog: false
    };
  },
  methods: {
    async updateQuestion() {
      let lecture;

      if (this.quiz.isExam) {
        if (this.question?.lecture?.id) {
          lecture = `lectures/${this.question.lecture.id}`;
        } else {
          lecture = `lectures/${this.question.lecture}`;
        }
      }

      let payload = {
        title: this.question.title,
        content: this.question.content,
        lecture: lecture,
        feedbackVideo: this.question.feedbackVideo
      };
      if (this.question.feedbackVideo && this.question.feedbackVideo instanceof Object) {
        payload.feedbackVideo = `medias/${this.question.feedbackVideo.id}`;
      }
      await ApiService.patch(`questions/${this.question.id}`, payload);
      for (let i = 0; i < this.question.answers.length; i++) {
        let answer = this.question.answers[i];
        if (answer.isNew) {
          await ApiService.post(`answers`, answer);
          continue;
        }

        await ApiService.patch(`answers/${answer.id}`, answer);
      }
      this.$router.push(`/quizzes/${this.quiz.id}`);
    },
    addFeedbackVideo(videoId) {
      this.question.feedbackVideo = `medias/${videoId}`;
    },
    addAnswer() {
      let id = this.question.answers.length + 1;
      let answer = {
        id: id,
        content: "",
        question: `questions/${this.question.id}`,
        correct: false,
        isNew: true
      };
      this.question.answers.push(answer);
    }
  },
  created() {
    let questionId = this.$route.params.questionId;
    let quizId = this.$route.query.quizId;
    ApiService.get(`quizzes/${quizId}?projection=AddQuestionProjection`).then(response => {
      this.quiz = response.data;
    });
    ApiService.get(`questions/${questionId}?projection=QuestionProjection`)
      .then(res => {
        this.question = res.data;
      })
      .catch(err => {
        console.log(err);
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/quizzes");
      });
  }
};
</script>

<style></style>
