<template>
  <v-container id="list-notes" fluid tag="section">
    <v-card class="pa-3 rounded-lg">
      <v-row justify="end">
        <v-col md="3">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table :headers="headers" :items="notes" :search="search">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>
                  <router-link :to="/profile/ + user.id">{{ user.firstName }} {{ user.lastName }}</router-link>
                  Kullanıcısının Notları
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="mb-2 black--text font-weight-bold" @click="editItem()">
                  Yeni Not
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.text="{ item }">
              <span class="font-weight-regular">{{ item.text }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <EditDialog
        v-model="editDialog"
        :edited-item="editedItem"
        :form-title="formTitle"
        @onSave="save"
        @onCancel="close"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import EditDialog from "./EditDialog";

export default {
  components: { EditDialog },
  data: () => ({
    search: "",
    editDialog: false,
    headers: [
      { text: "Not", value: "text" },
      { text: "Oluşturan", value: "createdBy" },
      { text: "Düzenleyen", value: "lastModifiedBy" },
      { text: "Oluşturma", value: "createdDate" },
      { text: "Son Düzenleme", value: "lastModifiedDate" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      text: ""
    },
    defaultItem: {
      id: 0,
      text: ""
    },
    user: {},
    notes: []
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Not" : "Notu Düzenle";
    }
  },

  mounted() {
    this.initData();
  },

  watch: {
    editDialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions("user", ["getUser", "getUserNotes", "createNote", "updateNote", "deleteNote"]),

    initData() {
      Promise.all([this.getUserNotes(this.$route.params.userId), this.getUser(this.$route.params.userId)]).then(
        ([notes, user]) => {
          this.notes = notes;
          this.user = user;
        }
      );
    },

    editItem(item) {
      if (item) {
        this.editedIndex = this.notes.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "text"]);
      }

      this.editDialog = true;
    },

    async save() {
      this.editedItem.user = "users/" + this.user.id;
      if (this.editedIndex > -1) {
        await this.updateNote(this.editedItem);
      } else {
        await this.createNote(this.editedItem);
      }
      this.close();
      this.initData();
    },

    close() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async deleteItem(note) {
      if (confirm("Bu nota dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        await this.deleteNote(note);
        this.initData();
      }
    }
  }
};
</script>
