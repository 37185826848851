<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Öğrenci Karnesi</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="show = false">
            İptal
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <UserReport :requestedUserId="userId" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UserReport from "./UserReport";

export default {
  name: "ReportDialog",
  props: ["value", "userId"],
  components: { UserReport },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style></style>
