const USER = "user";
const COURSE_CATEGORY = "course_category";
const VERSION = "version";

const StorageService = {
  getItem: type => {
    const item = window.localStorage.getItem(type);
    return JSON.parse(item) || {};
  },
  saveItem: (type, payload) => {
    const currItem = StorageService.getItem(type);
    Object.assign(currItem, payload);
    window.localStorage.setItem(type, JSON.stringify(currItem));
  },
  destroyItem: type => {
    window.localStorage.removeItem(type);
  }
};

export { StorageService, USER, COURSE_CATEGORY, VERSION };
