import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./routes";
import VueLodash from "vue-lodash";
import lodash from "lodash";

import vuetify from "./common/plugins/vuetify";
import ApiService from "./common/api.service";
import { StorageService, USER, VERSION } from "./common/storage.service";
import { CHECK_AUTH } from "./store/auth/actions.type";
import i18n from "./common/i18n";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

import "./common/validation";
import "./assets/my.css";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://9ae1f5a101a94628ba1ae74b91e25d29@o412203.ingest.sentry.io/5288774",
    integrations: [new VueIntegration({ Vue, attachProps: true })]
  });
}

Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.use(Viewer);

Vue.config.productionTip = false;

ApiService.init();

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  if (authorize) {
    await store.dispatch("auth/" + CHECK_AUTH);
    const currentUser = StorageService.getItem(USER);
    if (!currentUser.token && !authorize.includes("ANONYMOUS")) {
      // not logged in so redirect to login page with the return url
      return next({ path: "/login", query: { returnUrl: to.path } });
    }

    if (!currentUser.roles) {
      currentUser.roles = ["ANONYMOUS"];
    }
    if (currentUser.token && currentUser.roles.includes("HAS_NO_ROLE")) {
      return next({ path: "/tech-error" });
    }

    if (currentUser.roles.includes("ROLE_CHANGE_PASSWORD") && to.name !== "update-password") {
      return next({ name: "update-password" });
    }

    if (
      !currentUser.roles.includes("ANONYMOUS") &&
      !currentUser.roles.includes("ROLE_CHANGE_PASSWORD") &&
      !currentUser.courseCategory &&
      to.name !== "user-settings"
    ) {
      return next({ name: "user-settings", query: { showError: true } });
    }

    // check if route is restricted by role
    if (authorize.filter(el => currentUser.roles.includes(el)).length === 0) {
      // role not authorised so redirect to home page
      return next({ path: "/" });
    }
  }

  ApiService.get("/config/frontendVersion").then(res => {
    let isNew = StorageService.getItem(VERSION).version !== res.data.version;
    StorageService.saveItem(VERSION, res.data);
    if (isNew) {
      location.reload();
    }
  });
  next();
});

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App),
  components: {
    ChartJsPluginDataLabels
  }
}).$mount("#app");
