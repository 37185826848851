export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_COURSE_CATEGORIES = "setCourseCategories";
export const SET_COURSES = "setCourses";
export const SET_LECTURES = "setLectures";
export const SET_SUBJECTS = "setSubjects";
export const SET_QUIZZES = "setQuizzes";
export const SET_USERS = "setUsers";
export let SET_BADGES = "setBadges";
