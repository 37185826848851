import { SET_COURSE_CATEGORIES, SET_COURSES, SET_LECTURES, SET_SUBJECTS } from "../mutations.type";

const mutations = {
  [SET_COURSE_CATEGORIES](state, courseCategories) {
    state.courseCategories = courseCategories
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
  },
  [SET_COURSES](state, courses) {
    state.courses = courses;
  },
  [SET_LECTURES](state, lectures) {
    state.lectures = lectures;
  },
  [SET_SUBJECTS](state, subjects) {
    state.subjects = subjects;
  }
};

export default mutations;
