<template>
  <LectureList listTitle="Geçmiş Canlı Dersler" @action="doAction($event)" />
</template>

<script>
import LectureList from "../StudentLecture/LectureList";

export default {
  name: "WebinarLectureList",
  components: { LectureList },
  methods: {
    doAction(lectureId) {
      this.$router.push({ name: "student-webinar-list", params: { id: lectureId } });
    }
  }
};
</script>

<style></style>
