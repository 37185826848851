<template>
  <v-container id="list-lectures" fluid tag="section">
    <v-card class="pa-3">
      <v-row justify="end">
        <v-col md="3">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table :headers="headers" :items="announcements" :search="search">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Duyurular</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="mb-2 black--text font-weight-bold" @click="editItem()">
                  Yeni Duyuru
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <EditDialog
        v-model="editDialog"
        :edited-item="editedItem"
        :form-title="formTitle"
        @onSave="save"
        @onCancel="close"
      />
    </v-card>
  </v-container>
</template>
<script>
import EditDialog from "./EditDialog";
import ApiService from "../../common/api.service";

export default {
  components: { EditDialog },
  data: () => ({
    search: "",
    editDialog: false,
    announcements: [],
    headers: [
      { text: "Duyuru Başlığı", value: "title" },
      { text: "Oluşturan", value: "createdBy" },
      { text: "Son Düzenleyen", value: "lastModifiedBy" },
      { text: "Oluşturma", value: "createdDate" },
      { text: "Son Düzenleme", value: "lastModifiedDate" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {}
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Duyuru" : "Duyuruyu Düzenle";
    }
  },

  mounted() {
    this.fetchAnnouncements();
  },

  watch: {
    editDialog(val) {
      val || this.close();
    }
  },

  methods: {
    async fetchAnnouncements() {
      let res = await ApiService.get("announcements?projection=AnnouncementProjection");
      this.announcements = res.data["_embedded"].announcements;
    },
    editItem(item) {
      if (item) {
        this.editedIndex = this.announcements.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "title", "content", "startDate", "endDate"]);
      }

      this.editDialog = true;
    },

    async save() {
      if (this.editedIndex > -1) {
        await ApiService.patch(`announcements/${this.editedItem.id}`, this.editedItem);
      } else {
        await ApiService.post("announcements", this.editedItem);
      }
      this.fetchAnnouncements();
      this.close();
    },

    close() {
      this.editDialog = false;
      this.editedItem = {};
      this.editedIndex = -1;
    },

    async deleteItem(announcement) {
      if (confirm("Bu duyuruya dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        await ApiService.delete(`announcements/${announcement.id}`);
        this.fetchAnnouncements();
      }
    }
  }
};
</script>
