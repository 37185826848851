<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <ValidationProvider name="Sınav Adı" v-slot="{ errors }" rules="required|min:4">
                    <v-text-field v-model="editedItem.name" label="Sınav Adı" :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider name="Sınıf" v-slot="{ errors }" rules="excluded:0|required">
                    <v-select
                      label="Sınıf"
                      :items="courseCategories"
                      v-model="editedItem.courseCategory"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="!editedItem.isExam"
                    name="Ders"
                    v-slot="{ errors }"
                    rules="excluded:0|required"
                  >
                    <v-select
                      v-if="!editedItem.isExam"
                      label="Ders"
                      :items="lectures"
                      v-model="editedItem.lecture"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="!editedItem.isExam"
                    name="Konu"
                    v-slot="{ errors }"
                    rules="excluded:0|required"
                  >
                    <v-select
                      v-if="!editedItem.isExam"
                      label="Konu"
                      :items="subjects"
                      v-model="editedItem.subject"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <ValidationProvider v-if="!editedItem.isExam" name="Video" v-slot="{ errors }">
                    <v-select
                      v-if="!editedItem.isExam"
                      label="Video"
                      :items="materials"
                      v-model="editedItem.material"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                  <v-switch v-model="editedItem.isExam" label="Deneme sınavı?" />
                  <ValidationProvider
                    v-if="editedItem.isExam"
                    name="Süre"
                    v-slot="{ errors }"
                    rules="required|min_value:0"
                  >
                    <v-text-field
                      v-if="editedItem.isExam"
                      v-model="editedItem.timeLimit"
                      type="number"
                      suffix="dk."
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                  <v-switch v-model="editedItem.published" label="Yayında?" />
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onCancel">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="onSubmit">
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: { ValidationProvider, ValidationObserver },
  props: ["value", "editedItem", "formTitle", "courseCategories", "lectures", "subjects", "materials"],
  computed: {
    show: {
      get() {
        this.$refs.observer?.reset();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    async onSubmit() {
      let valid = await this.$refs.observer.validate();
      if (valid) {
        this.$emit("onSave");
        this.$refs.form.reset();
        this.$refs.observer.reset();
      }
    },
    onCancel() {
      this.$emit("onCancel");
      this.$refs.form.reset();
      this.$refs.observer.reset();
    }
  }
};
</script>
