<template>
  <v-container v-if="lecture" id="lecture-admin-detail" fluid tag="section">
    <div class="mb-12">
      <v-toolbar color="cyan" class="primary" dark>
        <v-toolbar-title>{{ lecture.courseCategory.name }} / {{ lecture.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="openServerDialog = true" class="mr-2"><v-icon dark>mdi-server</v-icon></v-btn>
        <v-btn @click="openSchedulesDialog = true" class="mr-2"><v-icon dark>settings</v-icon></v-btn>
        <controls :webinar="lecture.webinar" />
      </v-toolbar>
    </div>
    <v-card>
      <subjects :parentLecture="lecture" @subjectsUpdated="initialize()" />
    </v-card>
    <br />
    <records :webinar="lecture.webinar" />
    <SchedulesEditDialog v-model="openSchedulesDialog" :webinar="lecture.webinar" />
    <SelectServer v-model="openServerDialog" :webinar="lecture.webinar" />
  </v-container>
</template>

<script>
import ApiService from "@/common/api.service";
import Subjects from "@/views/Subject/Subjects";
import Records from "@/components/Webinar/Records";
import Controls from "@/components/Webinar/Controls";
import SchedulesEditDialog from "@/components/Webinar/SchedulesEditDialog";
import SelectServer from "@/components/Webinar/SelectServer";

export default {
  components: {
    Subjects,
    Records,
    Controls,
    SchedulesEditDialog,
    SelectServer
  },
  data() {
    return {
      lecture: null,
      openSchedulesDialog: false,
      openServerDialog: false
    };
  },
  methods: {
    async initialize() {
      let lectureId = this.$route.params.lectureId;
      let res = await ApiService.get(`lectures/${lectureId}?projection=LectureDetailsProjection`);
      this.lecture = res.data;
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style></style>
