<template>
  <v-container>
    <v-row justify="center">
      <v-col lg="6" md="8" sm="9" xs="12">
        <v-card class="pa-3">
          <v-row justify="center" align="center" style="flex-direction: column">
            <v-select
              label="Sınıf"
              :items="courseCategories"
              v-model="courseCategory"
              item-text="name"
              item-value="id"
              class="pa-3"
            />
            <span v-if="usersPosition > 0">Sıralamanız : {{ usersPosition }}</span>
            <v-col lg="11" md="11" sm="11" xs="12">
              <v-data-table
                :headers="headers"
                :items="userListWithIndex"
                disable-sort
                disable-pagination
                :hide-default-footer="true"
              >
                <template v-slot:item="props">
                  <tr :style="{ backgroundColor: props.item.userId === currentUser.id ? '#4caf50' : 'transparent' }">
                    <td>{{ props.item.index }}</td>
                    <td>{{ props.item.name }}</td>
                    <td>{{ props.item.totalXp }}</td>
                  </tr>
                </template>
                <template v-slot:item.position="{ item }">
                  <v-icon small @click="deleteRecord(item)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-col>
            <span class="text--secondary">Not: 24 saatte bir güncellenir.</span>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ApiService from "@/common/api.service";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    courseCategory: 0,
    headers: [
      { text: "Sıra", value: "index" },
      { text: "Kullanıcı", value: "name" },
      { text: "Puan", value: "totalXp" }
    ],
    userList: [],
    usersPosition: 0
  }),
  mounted() {
    this.courseCategory = this.userCourseCategoryId;
    Promise.all([
      this.getLeaderboard(this.userCourseCategoryId),
      this.fetchCourseCategories(),
      this.getUsersPosition()
    ]);
  },
  watch: {
    courseCategory(val) {
      this.getLeaderboard(val);
      this.getUsersPosition();
    }
  },
  methods: {
    ...mapActions("course", ["fetchCourseCategories"]),
    async getLeaderboard(courseCategoryId) {
      let res = await ApiService.get(`leaderboards/${courseCategoryId}`);
      this.userList = res.data;
    },
    async getUsersPosition() {
      let res = await ApiService.get("leaderboards/usersPosition");
      this.usersPosition = res.data;
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("course", ["courseCategories"]),
    userListWithIndex() {
      return this.userList.map((item, i) => {
        return {
          ...item,
          index: i + 1
        };
      });
    },
    userCourseCategoryId() {
      return this.currentUser.courseCategory.id;
    }
  }
};
</script>
