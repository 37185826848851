<template>
  <LectureList listTitle="Arşiv Videoları" @action="doAction($event)" />
</template>

<script>
import LectureList from "../StudentLecture/LectureList";

export default {
  name: "ArchiveLectureList",
  components: { LectureList },
  methods: {
    doAction(lectureId) {
      this.$router.push({ name: "student-lecture", params: { id: lectureId }, query: { show: "VIDEO" } });
    }
  }
};
</script>

<style></style>
