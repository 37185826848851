<template>
  <v-data-table
    item-key="name"
    :search="search"
    class="elevation-1"
    hide-default-header
    :headers="headers"
    :items="materials"
  >
    <template v-slot:item.name="{ item }">
      <v-btn
        @click="$router.push('/student/video/' + item.id)"
        v-if="item.media.mediaType === 'VIDEO'"
        text
        color="primary"
      >
        {{ item.name }}
      </v-btn>
      <v-btn
        @click="$router.push('/quiz-attempt/' + item.id)"
        text
        color="primary"
        v-else-if="item.media.mediaType === 'QUIZ'"
      >
        {{ item.name }}
      </v-btn>
    </template>
    <template v-slot:item.info="{ item }">
      <v-checkbox class="align-self-center" v-if="item.media.mediaType === 'VIDEO'" disabled v-model="item.watched" />
      <div v-if="item.bestAttempt != null">
        <v-btn
          :to="'/quiz-result/' + item.bestAttempt.attemptId + '/' + item.bestAttempt.quizId"
          fab
          x-small
          color="green"
          class="font-weight-bold"
          style="font-size: 15px"
        >
          {{ item.bestAttempt.score }}
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: ["materials", "search"],
  data: () => ({
    headers: [{ value: "name" }, { value: "info" }]
  })
};
</script>
