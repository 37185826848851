<template>
  <v-card flat>
    <v-toolbar class="rounded-lg">
      <v-toolbar-title v-if="webinar">{{ webinar.name }}</v-toolbar-title>
    </v-toolbar>
    <v-card class="mt-7">
      <Records v-if="webinar" :webinar="webinar" />
    </v-card>
  </v-card>
</template>

<script>
import Records from "@/components/Webinar/Records";
import ApiService from "@/common/api.service";

export default {
  components: { Records },
  data: () => ({
    webinar: null
  }),
  mounted() {
    let lectureId = this.$route.params.id;

    ApiService.get(`lectures/${lectureId}/webinar`)
      .then(res => {
        this.webinar = res.data;
      })
      .catch(() => {
        this.$toast.error("Canlı ders geçmişi bulunamadı.");
      });
  }
};
</script>

<style></style>
