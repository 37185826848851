<template>
  <v-card class="rounded-lg">
    <v-toolbar flat>
      <v-toolbar-title>Çözülen Test/Soru Sayısı</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <bar-chart v-if="loaded" :chart-data="datacollection"></bar-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "./BarChart";
import ApiService from "@/common/api.service";

export default {
  name: "QuizQuestionReport",
  props: ["userId", "courseCategoryId"],
  components: { BarChart },
  data() {
    return {
      loaded: false,
      quizReport: [],
      datacollection: null
    };
  },
  watch: {
    courseCategoryId: function() {
      this.drawGraph();
    }
  },
  mounted() {
    this.drawGraph();
  },
  methods: {
    drawGraph() {
      ApiService.get(`reports/quizReport?userId=${this.userId}&courseCategoryId=${this.courseCategoryId}`).then(res => {
        let labels = [];
        let quizData = [];
        let questionData = [];
        if (res.data) {
          res.data.filter(report => {
            labels.push(report.start_time);
            quizData.push(report.total);
            questionData.push(report.question_count);
          });
        }
        this.datacollection = {
          labels: labels,
          datasets: [
            {
              label: "Test Sayısı",
              backgroundColor: "rgba(255, 99, 132, 0.4)",
              data: quizData,
              borderWidth: 2
            },
            {
              label: "Soru Sayısı",
              backgroundColor: "rgba(255, 159, 64, 0.4)",
              data: questionData,
              borderWidth: 2
            }
          ]
        };
        this.loaded = true;
      });
    }
  }
};
</script>
