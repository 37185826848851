var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"list-courseCategories","fluid":"","tag":"section"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Ara","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.courseCategories,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Sınıflar")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 black--text font-weight-bold",on:{"click":function($event){return _vm.editItem()}}},[_vm._v(" Yeni Sınıf ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                name: 'course-category-detail-admin',
                params: { categoryId: item.id }
              }}},[_c('p',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.name))])])]}},{key:"item.lecturesButton",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.showLectures(item)}}},[_vm._v(" Göster ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])})],1)],1),_c('EditDialog',{attrs:{"courses":_vm.courses,"edited-item":_vm.editedItem,"form-title":_vm.formTitle},on:{"onCancel":_vm.close,"onSave":_vm.save},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('LecturesDialog',{attrs:{"courseCategory":_vm.courseCategory},model:{value:(_vm.lecturesDialog),callback:function ($$v) {_vm.lecturesDialog=$$v},expression:"lecturesDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }