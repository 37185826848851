var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.subject)?_c('v-container',{attrs:{"id":"list-lectures","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3 mini-icon mb-12",attrs:{"icon":"video_library","title":"Materyaller"},scopedSlots:_vm._u([{key:"header-actions",fn:function(){return [_c('router-link',{attrs:{"to":{
          name: 'add-material-to-subject',
          params: { subjectId: _vm.subject.id }
        }}},[_c('v-btn',[_vm._v(" Yeni Materyal ")])],1)]},proxy:true}],null,false,4259298916)},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.materials},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'student-video', params: { materialId: item.id } }}},[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            path: 'add-material-to-subject',
            params: { subjectId: _vm.subject.id }
          }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,false,3965373722)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }