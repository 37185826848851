import ApiService from "@/common/api.service";

const actions = {
  async uploadFile(context, file) {
    let formData = new FormData();
    formData.append("file", file);
    const res = await ApiService.post(`medias/upload`, formData);
    return res.data;
  },
  deleteMedia(context, mediaId) {
    return ApiService.delete(`medias/${mediaId}`);
  }
};

export default actions;
