import ApiService from "@/common/api.service";
import { SET_USERS } from "../mutations.type";

const actions = {
  async searchUsers(context, wrapper) {
    let options = wrapper.options;
    let desc = options.sortDesc[0] ? "desc" : "asc";
    let filter = wrapper.filter;
    let query = `users/searchUsers?page=${options.page - 1}&size=${options.itemsPerPage}&sort=${
      options.sortBy
    },${desc}&projection=SearchUserProjection`;

    if (filter.expireIn) {
      query = query.concat(`&expireIn=${filter.expireIn}`);
    }

    if (filter.name) {
      query = query.concat(`&name=${filter.name}`);
    }

    if (filter.vendor) {
      query = query.concat(`&vendorId=${filter.vendor}`);
    }

    if (filter.advisoryTeacher) {
      query = query.concat(`&teacherId=${filter.advisoryTeacher}`);
    }

    if (filter.registeredBy) {
      query = query.concat(`&registeredById=${filter.registeredBy}`);
    }

    if (filter.activeAndExpired) {
      query = query.concat(`&activeAndExpired=${filter.activeAndExpired}`);
    }

    const res = await ApiService.get(query);
    context.commit(SET_USERS, res.data.content);
    return res.data;
  },
  async fetchVendorUsers(context, options) {
    let desc = options.sortDesc[0] ? "desc" : "asc";
    let url = `users/vendor-users?keyword=${options.keyword}`;
    const res = await ApiService.get(
      `${url}&page=${options.page - 1}&size=${options.itemsPerPage}&sort=${options.sortBy},${desc}&projection=UserInfo`
    );
    context.commit(SET_USERS, res.data["content"]);
    return res.data;
  },
  async fetchVendors() {
    const res = await ApiService.get(`vendors`);
    return res.data._embedded.vendors;
  },
  async fetchMyUsers(context, options) {
    let desc = options.sortDesc[0] ? "desc" : "asc";

    const res = await ApiService.get(
      `users/registered-by-me?keyword=${options.keyword}&page=${options.page - 1}&size=${options.itemsPerPage}&sort=${
        options.sortBy
      },${desc}&projection=UserInfo`
    );
    context.commit(SET_USERS, res.data["content"]);
    return res.data;
  },
  async saveUser(context, user) {
    user.username = user.username.trim();
    user.password = user.password?.trim();
    user.courseCategory = user.courseCategory.id;
    user.registeredBy = user.registeredBy ? user.registeredBy.id : null;
    user.advisoryTeacher = user.advisoryTeacher ? user.advisoryTeacher.id : null;
    user.vendor = user.vendor ? user.vendor.id : null;

    console.log(user);
    if (user.id) {
      await ApiService.patch("users/save", user);
    } else {
      await ApiService.post("users/save", user);
    }
  },
  async uploadProfilePic(context, file) {
    let formData = new FormData();
    formData.append("file", file);
    await ApiService.post("users/upload-profile-pic", formData);
  },
  async deleteUser(context, user) {
    await ApiService.delete("users/" + user.id);
  },
  async getUser(context, userId, projection = "UserDetailProjection") {
    let res = await ApiService.get(`users/${userId}?projection=${projection}`);
    return res.data;
  },
  async getUserByRole(context, role, projection = "MinimalUserProjection") {
    let res = await ApiService.get(`users/search/findUsersByRole?role=${role}&projection=${projection}`);
    return res.data["_embedded"].users;
  },
  async getUserNotes(context, userId) {
    let res = await ApiService.get("users/" + userId + `/notes?projection=NoteProjection`);
    return res.data["_embedded"].notes;
  },
  async createNote(context, note) {
    return ApiService.post("notes", note);
  },
  async updateNote(context, note) {
    return ApiService.patch(`notes/${note.id}`, note);
  },
  async deleteNote(context, note) {
    return ApiService.delete(`notes/${note.id}`);
  },
  async getLogs(context, data) {
    let res = await ApiService.get(
      `users/${data.userId}/logs?&page=${data.options.page - 1}&size=${data.options.itemsPerPage}`
    );
    return res.data;
  },
  async getActionsCount(context, userId) {
    let res = await ApiService.get(`users/${userId}/logs/actions`);
    return res.data;
  }
};

export default actions;
