<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <v-card-text>
        <v-card-title class="headline">
          Sınavı başlatmak istiyor musunuz ?
        </v-card-title>
        <v-alert class="mt-5" v-if="quiz.timeLimit" type="warning">
          Bu sınavda zaman limiti vardır. Sınavı
          <strong>{{ quiz.timeLimit }} dakika</strong>
          içerisinde tamamlamanız beklenmektedir.
        </v-alert>
        <v-alert class="mt-5" v-if="attempts.length > 0" type="info">
          <p class="font-weight-bold ma-0">Bu sınavı daha önce çözdünüz.</p>
          <p class="font-weight-bold ma-0">Aldığınız en yüksek puan : {{ maxScore }}</p>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('onCancel')">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="$emit('onStart')">
          Başlat
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value", "quiz", "attempts"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    maxScore() {
      return this.attempts.reduce((a, b) => (a.score > b.score ? a : b)).score;
    }
  }
};
</script>
