<template>
  <div>
    <v-row justify="end">
      <v-col md="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-data-table :headers="headers" :items="questions" :search="search">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Sorularım</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="mb-2 black--text font-weight-bold" @click="$emit('editItem')">
                Soru Sor
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.title="{ item }">
            <router-link class="font-weight-regular" :to="'question/' + item.id">
              {{ item.title }}
            </router-link>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.resolved="{ item }">
            <v-checkbox disabled v-model="item.resolved" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ApiService from "../../common/api.service";

export default {
  data: () => ({
    search: "",
    headers: [
      { text: "Başlık", value: "title" },
      { text: "Sınıf", value: "courseCategory.name" },
      { text: "Ders", value: "lecture.name" },
      { text: "Tarih", value: "createdDate" },
      { text: "Çözüldü", value: "resolved" }
    ],
    questions: []
  }),
  mounted() {
    this.getMyQuestions();
  },
  methods: {
    async getMyQuestions() {
      let res = await ApiService.get("qaQuestions/search/getMyQuestions?projection=QAQuestionProjection");
      this.questions = res.data["_embedded"].qaQuestions;
    }
  }
};
</script>
