<template>
  <v-sheet :elevation="3" width="75%" class="mx-auto pa-10">
    <v-sheet color="error" class="pa-3 white--text" v-if="$route.query.showError">
      <h2>
        <v-icon color="white">mdi-alert-circle</v-icon>
        Sistemi kullanabilmek için lütfen sınıfınızı tanımlayınız
      </h2>
    </v-sheet>
    <h3>Kullanıcı Bilgilerim</h3>
    <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="onSubmit()">
      <v-form>
        <ValidationProvider name="Ad" v-slot="{ errors }" rules="required|min:2|max:255">
          <v-text-field
            label="Ad"
            prepend-icon="perm_identity"
            type="text"
            :error-messages="errors"
            v-model="user.firstName"
            disabled
          />
        </ValidationProvider>
        <ValidationProvider name="Soyad" v-slot="{ errors }" rules="required|min:2|max:255">
          <v-text-field
            label="Soyad"
            prepend-icon="perm_identity"
            type="text"
            :error-messages="errors"
            v-model="user.lastName"
            disabled
          />
        </ValidationProvider>
        <ValidationProvider name="Sınıf" v-slot="{ errors }" rules="excluded:0|required">
          <v-select
            label="Sınıf"
            :items="courseCategories"
            v-model="user.courseCategory"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            prepend-icon="mdi-google-classroom"
          />
        </ValidationProvider>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" color="primary">{{ $t("common.update") }}</v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ApiService from "../../common/api.service";
import { COURSE_CATEGORY, StorageService } from "../../common/storage.service";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: ["showError"],
  data: function() {
    return {
      user: {}
    };
  },
  async mounted() {
    this.fetchCourseCategories("DefinitionProjection");
    this.user = await this.getUser();
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("course", ["courseCategories"])
  },
  methods: {
    ...mapActions("course", ["fetchCourseCategories"]),
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          await this.updateMe();
          this.$toast.success("Profiliniz güncellendi");
        } catch (e) {
          this.$toast.error("Güncelleme sırasında hata oluştu");
        }
      }
    },
    async getUser() {
      let res = await ApiService.get(`users/${this.currentUser?.id}?projection=MinimalUserProjection`);
      return res.data;
    },
    async updateMe() {
      let data = this._.pick(this.user, ["courseCategory"]);
      let courseCategory = {};
      if (typeof data.courseCategory === typeof {}) {
        courseCategory = data.courseCategory;
        data.courseCategory = data.courseCategory.id;
      } else {
        courseCategory = this._.find(this.courseCategories, { id: data.courseCategory });
      }
      StorageService.saveItem(COURSE_CATEGORY, courseCategory);
      await ApiService.patch("/users/update-me", data);
      location.assign("/");
    }
  }
};
</script>
