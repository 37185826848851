<template>
  <v-row justify="center" style="flex-direction: column" align="center">
    <v-col cols="12" md="8">
      <v-card class="ma-0 white--text font-weight-bold" color="#05668d">
        <v-card-title>
          {{ question.title }}
        </v-card-title>
      </v-card>
      <v-card class="ma-0">
        <v-card-text>
          <v-runtime-template :template="convertToExpandableImage(question.content)"></v-runtime-template>
        </v-card-text>
        <v-divider />
        <v-row align="center" justify="end" dense>
          <v-col cols="1" :style="xsOnly">
            <v-avatar size="28">
              <v-img :src="question.userPicUrl" />
            </v-avatar>
          </v-col>
          <v-col cols="11" sm="4" md="4" lg="3">{{ question.createdBy }} {{ question.createdDate }}</v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="8">
      <v-card
        v-for="comment in question.comments"
        v-bind:key="comment.id"
        class="ma-0"
        style="margin-bottom: 5px !important;"
      >
        <v-card-text>
          <v-runtime-template :template="convertToExpandableImage(comment.content)"></v-runtime-template>
        </v-card-text>
        <v-divider />
        <v-row align="center" justify="end" dense>
          <v-col cols="1" :style="xsOnly">
            <v-avatar size="28">
              <v-img :src="comment.userPicUrl" />
            </v-avatar>
          </v-col>
          <v-col cols="11" sm="4" md="4" lg="3">{{ comment.createdBy }} {{ comment.createdDate }}</v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col v-if="!question.resolved" cols="12" md="8">
      <v-card>
        <editor :content.sync="newComment.content" />
      </v-card>
      <v-btn @click="saveComment" color="primary" class="float-right">Yanıtla</v-btn>
      <v-btn
        v-if="currentUser.roles.includes('ROLE_ADMIN')"
        @click="closeQuestion"
        color="secondary"
        class="float-right"
      >
        Soruyu Kapat
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import ApiService from "../../common/api.service";
import Editor from "@/components/Editor";
import { mapGetters } from "vuex";
import VRuntimeTemplate from "v-runtime-template";

export default {
  components: { Editor, VRuntimeTemplate },
  data: () => ({
    parentComponent: this,
    question: { content: "<div></div>" },
    newComment: {}
  }),
  mounted() {
    this.fetchQaQuestion();
  },
  methods: {
    async fetchQaQuestion() {
      let id = this.$route.params.id;
      let res = await ApiService.get(`qaQuestions/${id}?projection=QAQuestionProjection`);
      this.question = res.data;
    },
    async saveComment() {
      let data = { question: { id: this.$route.params.id }, content: this.newComment.content };
      try {
        await ApiService.post(`qaComments`, data);
        await this.fetchQaQuestion();
        this.newComment = {};
      } catch (e) {
        console.log(e);
      }
    },
    async closeQuestion() {
      await ApiService.post(`qa/setResolved/${this.question.id}`);
      this.fetchQaQuestion();
    },
    convertToExpandableImage(content) {
      const regex = /<img [^>]*src="[^"]*"[^>]*/gi;
      const replaced = content.replace(regex, '$& class="image" /');
      return `<div v-viewer>${replaced}</div>`;
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    xsOnly() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return "";
      }
      return "display: flex; justify-content: end";
    }
  }
};
</script>
<style>
.image {
  width: 20vh;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
</style>
