import ApiService from "../../common/api.service";
import _ from "lodash";

const notification = {
  namespaced: true,
  state: {
    notifications: []
  },
  actions: {
    async fetchNotifications({ commit }) {
      let response = await ApiService.get("notification/get");
      commit("setNotifications", response.data);
    },
    async markAsRead(context, notification) {
      if (!notification.markRead) {
        await ApiService.get(`notification/read/${notification.id}`);
        context.dispatch("fetchNotifications");
      }
    }
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = _.orderBy(notifications, ["markRead"], ["desc"]);
    }
  },
  getters: {
    notifications(state) {
      return state.notifications;
    }
  }
};

export default notification;
