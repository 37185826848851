<template>
  <v-card rounded class="rounded-lg">
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Canlı Ders Programı</v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="currentDay" fixed-tabs show-arrows>
      <v-tab v-for="day in days" :key="day.index">
        {{ day.text }}
      </v-tab>
    </v-tabs>
    <daily-schedule v-if="currentDay != null" :day="currentDay + 1" />
  </v-card>
</template>

<script>
import DailySchedule from "./DailySchedule";

export default {
  name: "WebinarScheduleCard",
  components: { DailySchedule },
  data: () => ({
    currentDay: null,
    days: [
      { index: 1, text: "Pazartesi" },
      { index: 2, text: "Salı" },
      { index: 3, text: "Çarşamba" },
      { index: 4, text: "Perşembe" },
      { index: 5, text: "Cuma" },
      { index: 6, text: "Cumartesi" }
    ]
  }),
  mounted() {
    let date = new Date();
    this.currentDay = date.getDay() - 1;
  }
};
</script>

<style></style>
