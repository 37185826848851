var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Sınav Adı","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Sınav Adı","error-messages":errors},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Sınıf","rules":"excluded:0|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Sınıf","items":_vm.courseCategories,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.editedItem.courseCategory),callback:function ($$v) {_vm.$set(_vm.editedItem, "courseCategory", $$v)},expression:"editedItem.courseCategory"}})]}}],null,true)}),(!_vm.editedItem.isExam)?_c('ValidationProvider',{attrs:{"name":"Ders","rules":"excluded:0|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.editedItem.isExam)?_c('v-select',{attrs:{"label":"Ders","items":_vm.lectures,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.editedItem.lecture),callback:function ($$v) {_vm.$set(_vm.editedItem, "lecture", $$v)},expression:"editedItem.lecture"}}):_vm._e()]}}],null,true)}):_vm._e(),(!_vm.editedItem.isExam)?_c('ValidationProvider',{attrs:{"name":"Konu","rules":"excluded:0|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.editedItem.isExam)?_c('v-select',{attrs:{"label":"Konu","items":_vm.subjects,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.editedItem.subject),callback:function ($$v) {_vm.$set(_vm.editedItem, "subject", $$v)},expression:"editedItem.subject"}}):_vm._e()]}}],null,true)}):_vm._e(),(!_vm.editedItem.isExam)?_c('ValidationProvider',{attrs:{"name":"Video"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.editedItem.isExam)?_c('v-select',{attrs:{"label":"Video","items":_vm.materials,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.editedItem.material),callback:function ($$v) {_vm.$set(_vm.editedItem, "material", $$v)},expression:"editedItem.material"}}):_vm._e()]}}],null,true)}):_vm._e(),_c('v-switch',{attrs:{"label":"Deneme sınavı?"},model:{value:(_vm.editedItem.isExam),callback:function ($$v) {_vm.$set(_vm.editedItem, "isExam", $$v)},expression:"editedItem.isExam"}}),(_vm.editedItem.isExam)?_c('ValidationProvider',{attrs:{"name":"Süre","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.editedItem.isExam)?_c('v-text-field',{attrs:{"type":"number","suffix":"dk.","error-messages":errors},model:{value:(_vm.editedItem.timeLimit),callback:function ($$v) {_vm.$set(_vm.editedItem, "timeLimit", $$v)},expression:"editedItem.timeLimit"}}):_vm._e()]}}],null,true)}):_vm._e(),_c('v-switch',{attrs:{"label":"Yayında?"},model:{value:(_vm.editedItem.published),callback:function ($$v) {_vm.$set(_vm.editedItem, "published", $$v)},expression:"editedItem.published"}})],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onCancel}},[_vm._v(" İptal ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSubmit}},[_vm._v(" Kaydet ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }