<template>
  <base-material-card icon="video_library" title="Geçmiş Canlı Yayınlar" class="px-5 py-3 mini-icon mb-12">
    <v-data-table :headers="headers" :items="webinarRecords" sort-by="date" :sort-desc="true" class="elevation-1">
      <template v-slot:item.play="{ item }">
        <v-btn primary small :href="item.playUrl" target="_blank">Dersi İzle</v-btn>
      </template>

      <template v-if="currentUser.roles.includes('ROLE_ADMIN')" v-slot:item.actions="{ item }">
        <v-icon small @click="deleteRecord(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
  </base-material-card>
</template>

<script>
import ApiService from "@/common/api.service";
import { mapGetters } from "vuex";

export default {
  props: ["webinar"],
  data: function() {
    return {
      headers: [
        { text: "Başlangıç Tarihi", value: "startTime" },
        { text: "Katılımcı", value: "participants" },
        { text: "Boyut", value: "size" },
        { text: "Uzunluk", value: "duration" },
        { text: "Oynat", sortable: false, value: "play" },
        { text: "Aksiyon", sortable: false, value: "actions" }
      ],
      webinarRecords: []
    };
  },
  mounted() {
    this.getRecords();
    if (!this.currentUser.roles.includes("ROLE_ADMIN")) {
      this.headers.pop();
      this.headers.splice(1, 2);
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"])
  },
  methods: {
    getRecords() {
      ApiService.get(`webinars/${this.webinar.id}/recordings`)
        .then(res => {
          return res.data.recordings;
        })
        .then(records => {
          this.webinarRecords = records.map(record => {
            let startTime = new Date(parseInt(record.startTime));
            return {
              date: record.startTime,
              startTime: startTime.toLocaleString("tr-TR"),
              duration: record.playback[0].length + " dk.",
              participants: record.participants,
              playUrl: record.playback[0].url,
              size: parseInt(record.size / 1000000, 10) + "MB"
            };
          });
        });
    },
    deleteRecord(record) {
      ApiService.delete(`${this.webinar.id}/recordings/${record.id}`).then(() => {
        this.getRecords();
      });
    }
  }
};
</script>

<style></style>
