var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"quiz-detail","fluid":"","tag":"section"}},[_c('div',{staticClass:"mb-12"},[_c('v-toolbar',{staticClass:"primary",attrs:{"color":"cyan","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.quiz.name))]),_c('v-spacer')],1)],1),_c('base-material-card',{staticClass:"px-5 py-3 mini-icon mb-12",attrs:{"icon":"toc","title":"Sorular"},scopedSlots:_vm._u([{key:"header-actions",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'add-question-to-quiz', params: { quizId: _vm.quiz.id } }}},[_c('v-btn',{attrs:{"color":"primary","small":""}},[_vm._v("Soru Ekle")])],1)]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.questionTableHeader,"items":_vm.quiz.questions,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'edit-question', params: { questionId: item.id }, query: { quizId: _vm.quiz.id } }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteQuestion(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.content)}})]}}])})],1),_c('base-material-card',{staticClass:"px-5 py-3 mini-icon",attrs:{"icon":"assessment","title":"Katılımlar"},scopedSlots:_vm._u([{key:"header-actions",fn:function(){return [_c('v-btn',{staticClass:"error",on:{"click":_vm.reCalculate}},[_vm._v("Yeniden değerlendir")])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.attempsTableHeader,"items":_vm.attempts,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.attemptTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeDistance(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.reviewAttempt(item)}}},[_vm._v("İncele")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteAttempt(item)}}},[_vm._v("Sil")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }