<template>
  <v-dialog v-model="show" max-width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">Ders Programı</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Aktif?</th>
                  <th class="text-left">Gün</th>
                  <th class="text-left">Başlangıç Saati</th>
                  <th class="text-left">Süre</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(schedule, key) in schedules" :key="key">
                  <td><v-checkbox @change="markDirty(schedule)" v-model="schedule.isActive"></v-checkbox></td>
                  <td>
                    <v-select
                      @change="markDirty(schedule)"
                      v-model="schedule.day"
                      :items="days"
                      item-text="text"
                      item-value="value"
                      label="Gün seçin"
                      single-line
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field v-model="schedule.startTime" @input="markDirty(schedule)" type="time"></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="schedule.duration"
                      @input="markDirty(schedule)"
                      type="number"
                      suffix="dk."
                    ></v-text-field>
                  </td>
                  <td><v-icon @click="deleteSchedule(schedule)">remove_circle</v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn @click="schedules.push({ isNew: true })">Ekle</v-btn>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveChanges">
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "WebinarSchedulesEdit",
  props: ["value", "webinar"],
  data: () => ({
    schedules: [],
    day: "",
    days: [
      { value: 1, text: "Pazartesi" },
      { value: 2, text: "Salı" },
      { value: 3, text: "Çarşamba" },
      { value: 4, text: "Perşembe" },
      { value: 5, text: "Cuma" },
      { value: 6, text: "Cumartesi" },
      { value: 0, text: "Pazar" }
    ]
  }),
  mounted() {
    this.getSchedules();
  },
  methods: {
    getSchedules() {
      ApiService.get(`webinars/${this.webinar.id}/webinarSchedules`).then(res => {
        this.schedules = res.data._embedded.webinarSchedules;
      });
    },
    deleteSchedule(schedule) {
      if (schedule.isNew) {
        return;
      }
      ApiService.delete(`webinarSchedules/${schedule.id}`).then(() => {
        this.getSchedules();
      });
    },
    markDirty(item) {
      item.isDirty = true;
    },
    async saveChanges() {
      for (let i = 0; i < this.schedules.length; i++) {
        let schedule = this.schedules[i];
        schedule.webinar = `webinars/${this.webinar.id}`;
        schedule.duration = parseInt(schedule.duration);
        if (schedule.startTime.length == 5) {
          schedule.startTime += ":00"; // LOL
        }
        if (schedule.isNew) {
          await ApiService.post("webinarSchedules", schedule);
        }
        if (schedule.isDirty && !schedule.isNew) {
          await ApiService.put(`webinarSchedules/${schedule.id}`, schedule);
        }
      }
      this.getSchedules();
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style></style>
