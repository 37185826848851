<template>
  <v-card class="rounded-lg" rounded>
    <v-toolbar class="rounded-lg">
      <v-toolbar-title>{{ subject.name }} konusu</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-text-field dense class="mt-4" prepend-icon="mdi-magnify" clearable v-model="search" label="Ara" />
      </v-col>
    </v-toolbar>
    <v-row class="mt-5" dense justify="center">
      <v-btn-toggle
        v-if="!$route.query.filter"
        background-color="light-blue darken-4"
        dark
        color="success"
        v-model="selectedFilter"
        class="mx-auto font-weight-bold"
      >
        <v-btn value="VIDEO">
          <v-icon left>mdi-video</v-icon>
          <span class="font-weight-bold">Videolar</span>
        </v-btn>

        <v-btn value="QUIZ">
          <v-icon left>mdi-forum</v-icon>
          <span class="font-weight-bold">Testler</span>
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="pl-3 pr-3 pb-3">
      <v-col cols="12">
        <MaterialTable :search="search" :materials="filtered" />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import MaterialTable from "./MaterialTable";
import MaterialService from "../Subject/partials/Materials/material.service";
import ApiService from "../../common/api.service";
export default {
  components: { MaterialTable },
  data: () => ({ selectedFilter: "", subject: {}, search: "", materials: [], filtered: [] }),
  async mounted() {
    await this.fetchMaterials();
    Promise.all([this.fetchSubject(), this.fetchQuizzes()]).then(() => {
      let queryFilter = this.$route.query.filter;
      if (queryFilter) this.selectedFilter = queryFilter;
    });
  },
  watch: {
    selectedFilter(val) {
      this.filter(val);
    }
  },
  methods: {
    async fetchMaterials() {
      this.materials = await MaterialService.getMaterials(this.$route.params.id);
      let watched = await this.fetchWatchedVideos();
      this.materials.forEach(mat => {
        if (this._.find(watched, { id: mat.id })) {
          mat.watched = true;
        }
      });
      this.filtered = this.materials;
    },
    async fetchSubject() {
      let res = await ApiService.get(`subjects/${this.$route.params.id}?projection=DefinitionProjection`);
      this.subject = res.data;
    },
    async fetchQuizzes() {
      let res = await ApiService.get(`subjects/${this.$route.params.id}/quizzes`);
      let quizzes = res.data["_embedded"].quizzes;
      let solved = await this.fetchSolvedQuizzes();

      quizzes.forEach(quiz => {
        quiz.media = { mediaType: "QUIZ" };
        quiz.bestAttempt = solved.find(q => q.quizId === quiz.id);
        if (quiz.published) {
          this.materials.push(quiz);
        }
      });
    },
    async fetchWatchedVideos() {
      let res = await ApiService.get(`materials/search/getWatchedVideos?subjectId=${this.$route.params.id}`);
      return res.data["_embedded"].materials;
    },
    async fetchSolvedQuizzes() {
      let res = await ApiService.get(`quizzes/solvedQuizzes/${this.$route.params.id}`);
      return res.data;
    },
    filter(val) {
      if (!val) {
        this.filtered = this.materials;
      } else {
        this.filtered = this.materials.filter(mat => mat.media.mediaType === val);
      }
    }
  }
};
</script>
