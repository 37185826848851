<template>
  <v-container id="list-lectures" tag="section" v-if="subject">
    <base-material-card icon="video_library" title="Materyaller" class="px-5 py-3 mini-icon mb-12">
      <template v-slot:header-actions>
        <router-link
          :to="{
            name: 'add-material-to-subject',
            params: { subjectId: subject.id }
          }"
        >
          <v-btn>
            Yeni Materyal
          </v-btn>
        </router-link>
      </template>
      <v-data-table :headers="headers" :items="materials">
        <template v-slot:item.name="{ item }">
          <router-link :to="{ name: 'student-video', params: { materialId: item.id } }">
            <strong>{{ item.name }}</strong>
          </router-link>
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link
            :to="{
              path: 'add-material-to-subject',
              params: { subjectId: subject.id }
            }"
          >
            <v-icon small class="mr-2">
              mdi-pencil
            </v-icon>
          </router-link>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import MaterialService from "./material.service.js";

export default {
  props: ["subject"],
  data: () => ({
    headers: [
      { text: "Materyal Türü", value: "media.mediaType" },
      { text: "Materyal Adı", value: "name" },
      { text: "Oluşturma", value: "createdDate" },
      { text: "Son Düzenleme", value: "lastModifiedDate" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    materials: []
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      MaterialService.getMaterials(this.$route.params.subjectId).then(materials => {
        this.materials = materials;
      });
    },
    deleteItem(material) {
      if (confirm("Bu materyale dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        MaterialService.deleteMaterial(material.id).then(() => {
          this.initialize();
        });
      }
    }
  }
};
</script>
