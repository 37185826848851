<template>
  <div>
    <v-row dense no-gutters justify="center">
      <v-col cols="12" xl="3" lg="4" md="6" sm="8" xs="12">
        <v-select
          @change="onSelectChange"
          :value="selectedRange"
          :items="ranges"
          item-text="label"
          item-value="range"
        ></v-select>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xl="3" lg="4" md="6" sm="8" xs="12">
        <LineChart v-if="lineChartLoaded" :options="lineOptions" :chart-data="lineChartData" />
      </v-col>
      <v-col cols="12" xl="3" lg="4" md="6" sm="8" xs="12">
        <BarChart v-if="barChartLoaded" :options="barChartOptions" :chart-data="barChartData" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import ApiService from "../../common/api.service";
import LineChart from "@/views/Home/LineChart";
import BarChart from "@/views/Home/BarChart";

export default {
  components: { LineChart, BarChart },
  data: () => ({
    lineChartLoaded: false,
    barChartLoaded: false,
    selectedRange: { range: 7, label: "7 Günlük" },
    ranges: [
      { range: 3, label: "3 Günlük" },
      { range: 7, label: "7 Günlük" },
      { range: 14, label: "14 Günlük" },
      { range: 30, label: "30 Günlük" },
      { range: 60, label: "60 Günlük" },
      { range: 90, label: "90 Günlük" }
    ],
    lineChartData: {
      labels: [],
      datasets: [
        {
          label: "Yeni Kayıt",
          data: [],
          fill: false,
          borderColor: "#045282",
          pointBorderWidth: "2"
        }
      ]
    },
    lineOptions: {
      plugins: {
        datalabels: {
          display: false
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              stepSize: 1,
              min: 0
            }
          }
        ],
        xAxes: [
          {
            min: 0,
            type: "time",
            distribution: "series",
            time: {
              unit: "day"
            }
          }
        ]
      }
    },
    barChartData: {
      labels: [],
      datasets: [
        {
          label: "Firma Yeni Kayıt",
          backgroundColor: ["#698996", "#407076", "#4AAD52", "#F19953", "#6EB257", "#2F2963", "#E15A97", "#F4AC45"],
          data: []
        }
      ]
    },
    barChartOptions: {
      plugins: {
        datalabels: {
          display: false
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              stepSize: 1,
              beginAtZero: true
            }
          }
        ]
      }
    }
  }),

  mounted() {
    this.fillLineChart(this.selectedRange.range);
    this.fillBarChart(this.selectedRange.range);
  },

  methods: {
    onSelectChange(val) {
      this.selectedRange = val;
      this.lineChartData.datasets[0].data = [];
      this.lineChartData.labels = [];
      this.fillLineChart(val);
      this.fillBarChart(val);
    },
    async fillLineChart(range) {
      this.lineChartLoaded = false;
      this.fillDays(range);
      let res = await ApiService.get(`/stats/newUsers?range=${range}`);
      for (const data of res.data) {
        let indexOf = this.lineChartData.labels.findIndex(
          x =>
            x.valueOf() ===
            moment(data[0])
              .toDate()
              .valueOf()
        );
        this.lineChartData.datasets[0].data[indexOf] = data[1];
      }
      this.lineChartLoaded = true;
    },
    async fillBarChart(range) {
      this.barChartLoaded = false;
      this.barChartData.datasets[0].data = [];
      this.barChartData.labels = [];
      let vendorReq = await ApiService.get(`/vendors?size=1000`);
      let res = await ApiService.get(`/stats/newUsersByVendor?range=${range}`);
      let vendors = vendorReq.data["_embedded"].vendors;
      vendors.forEach(v => this.barChartData.labels.push(v.name));
      for (const data of res.data) {
        let indexOf = this.barChartData.labels.indexOf(data.vendorName);
        this.barChartData.datasets[0].data[indexOf] = data.count;
      }
      this.barChartLoaded = true;
    },
    fillDays(range) {
      let now = moment();
      for (let i = 0; i < range; i++) {
        this.lineChartData.labels.push(now.startOf("day").toDate());
        this.lineChartData.datasets[0].data.push(0);
        now = now.subtract("1", "days");
      }
    }
  }
};
</script>
