<template>
  <v-container id="list-badges" fluid tag="section">
    <v-card rounded class="rounded-lg pa-3">
      <v-row justify="end">
        <v-col md="3">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table :headers="headers" :items="userBadges" :search="search">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>
                  <router-link :to="/profile/ + user.id">{{ user.firstName }} {{ user.lastName }}</router-link>
                  Kullanıcısının Madalyaları
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="mb-2 black--text font-weight-bold" @click="editItem()">
                  Yeni Madalya
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.badgeImg="{ item }">
              <v-avatar>
                <v-img :src="item.picUrl" />
              </v-avatar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <EditDialog
        v-model="editDialog"
        :badges="badges"
        :edited-item="editedItem"
        :form-title="formTitle"
        @onSave="save"
        @onCancel="close"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditDialog from "./EditDialog";
import ApiService from "../../common/api.service";

export default {
  components: { EditDialog },
  data: () => ({
    search: "",
    editDialog: false,
    headers: [
      { text: "Madalya Adı", value: "name" },
      { text: "Görsel", value: "badgeImg" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      badge: {}
    },
    defaultItem: {
      id: 0,
      badge: {}
    },
    user: {},
    userBadges: []
  }),

  computed: {
    ...mapGetters("badge", ["badges"]),
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Madalya" : "Madalyayı Düzenle";
    }
  },

  mounted() {
    this.initData();
  },

  watch: {
    editDialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions("user", ["getUser"]),
    ...mapActions("badge", ["getUserBadges", "fetchBadges"]),

    initData() {
      Promise.all([
        this.getUserBadges(this.$route.params.userId),
        this.getUser(this.$route.params.userId),
        this.fetchBadges()
      ]).then(([userBadges, user]) => {
        this.userBadges = userBadges;
        this.user = user;
      });
    },

    editItem() {
      this.editDialog = true;
    },

    async save(badgeId) {
      const req = {
        userId: +this.$route.params.userId,
        badgeId: badgeId
      };
      await ApiService.post("badges/addBadgeToUser", req);
      this.close();
      this.initData();
    },

    close() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async deleteItem(badge) {
      if (confirm("Bu nota dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        console.log(badge);
        const req = {
          userId: +this.$route.params.userId,
          badgeId: badge.id
        };
        await ApiService.post("badges/deleteUserBadge", req);
        this.initData();
      }
    }
  }
};
</script>
