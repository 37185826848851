import { getField } from "vuex-map-fields";

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getField
};

export default getters;
