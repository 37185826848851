<template>
  <v-container id="list-subjects" fluid tag="section">
    <v-row justify="end">
      <v-col md="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-data-table :headers="headers" :items="tableItems" :search="search">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Konular</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="mb-2" @click="editItem()" dark>
                Yeni Konu
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <p class="font-weight-bold">
              <router-link
                :to="{
                  name: 'subjects-admin-detail',
                  params: { subjectId: item.id }
                }"
              >
                {{ item.name }}
              </router-link>
            </p>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <EditDialog
      v-model="editDialog"
      :edited-item="editedItem"
      :lectures="lectures"
      :form-title="formTitle"
      :parent-lecture="parentLecture"
      @onSave="save"
      @onCancel="close"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditDialog from "./EditDialog";

export default {
  props: ["parentLecture"],
  components: { EditDialog },
  data: () => ({
    search: "",
    editDialog: false,
    headers: [
      { text: "Konu Adı", value: "name" },
      { text: "Ders", value: "lecture.name" },
      { text: "Oluşturma", value: "createdDate" },
      { text: "Son Düzenleme", value: "lastModifiedDate" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      lecture: 0,
      name: ""
    },
    defaultItem: {
      id: 0,
      name: "",
      lecture: 0
    }
  }),

  computed: {
    ...mapGetters("course", ["subjects", "lectures"]),
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Konu" : "Konuyu Düzenle";
    },
    tableItems() {
      if (this.parentLecture) {
        return this.parentLecture.subjects.map(subject => {
          subject.lecture = this.parentLecture;
          return subject;
        });
      } else {
        return this.subjects;
      }
    }
  },

  mounted() {
    if (!this.parentLecture) {
      Promise.all([this.fetchSubjects("SubjectProjection"), this.fetchLectures()]);
    }
  },

  watch: {
    editDialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions("course", ["fetchLectures", "fetchSubjects", "createSubject", "updateSubject", "deleteSubject"]),

    editItem(item) {
      if (item) {
        this.editedIndex = this.tableItems.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "name", "lecture"]);
        this.editedItem.lecture = item.lecture.id;
      }

      this.editDialog = true;
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.updateSubject(this.editedItem);
      } else {
        if (this.parentLecture) this.editedItem.lecture = this.parentLecture.id;
        await this.createSubject(this.editedItem);
      }
      this.$emit("subjectsUpdated");
      this.close();
    },

    close() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async deleteItem(lecture) {
      if (confirm("Bu konuya dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        await this.deleteSubject(lecture);
        this.$emit("subjectsUpdated");
      }
    }
  }
};
</script>
