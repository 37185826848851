import Vue from "vue";
import router from "@/routes";
import ApiService from "@/common/api.service";
import { StorageService, USER, COURSE_CATEGORY } from "@/common/storage.service";
import { SET_AUTH, PURGE_AUTH } from "../mutations.type";
import axios from "axios";

const actions = {
  async login(context, credentials) {
    let res = await axios("/auth/signin", {
      method: "post",
      data: credentials,
      withCredentials: true
    });
    StorageService.saveItem(COURSE_CATEGORY, res.data.courseCategory);
    context.commit(SET_AUTH, res.data);
  },
  async logout(context) {
    await context.commit(PURGE_AUTH);
    router.push({ name: "login" }).catch(err => {
      throw new Error(`Problem handling something: ${err}.`);
    });
  },
  async register(context, user) {
    try {
      const res = await ApiService.post("auth/register", user);
      await context.commit(SET_AUTH, res.data);
      router.push({ name: "home" });
    } catch (e) {
      if (e.response.status === 409) {
        Vue.prototype.$toast.error("Bu mail daha önce kullanılmış");
      }
    }
  },
  async checkAuth(context) {
    const user = StorageService.getItem(USER);
    if (user.token) {
      const me = await ApiService.get("users/me");
      context.commit(SET_AUTH, me.data);
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  async updateUser(context, user) {
    await ApiService.patch(`users/${user.id}`, user);
    context.dispatch("checkAuth");
  },

  async updatePassword(context, user) {
    try {
      await ApiService.patch(`users/update-password`, user);
      await context.commit(PURGE_AUTH);
      router.push({ name: "login" });
    } catch (e) {
      console.log(e);
    }
  },

  resetPassword(context, username) {
    return ApiService.post(`auth/reset-password?email=${username}`);
  },

  async validatePasswordToken(context, token) {
    try {
      let res = await ApiService.post(`auth/validate-reset-token?token=${token}`);
      context.commit(SET_AUTH, res.data);
      router.push({ name: "update-password" });
    } catch (e) {
      throw new Error(e);
    }
  },

  async validateMailToken(context, token) {
    try {
      await ApiService.post(`users/validate-mail-token?token=${token}`);
    } catch (e) {
      throw new Error(e);
    }
  }
};

export default actions;
