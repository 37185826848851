<template>
  <v-data-table
    :options.sync="options"
    :server-items-length="serverItemsLength"
    :loading="loading"
    :headers="headers"
    :items="quizzes"
    :search="search"
    :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Sınavlar</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mb-2 black--text font-weight-bold" @click="$emit('newItem')">
          Yeni Sınav
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.published="{ item }">
      <v-chip v-if="item.published" class="ma-2" color="primary">
        Yayında
      </v-chip>
      <v-chip v-else class="ma-2" color="red" text-color="white">
        Yayında Değil
      </v-chip>
    </template>
    <template v-slot:item.name="{ item }">
      <router-link :to="{ name: 'quiz-detail', params: { quizId: item.id } }">
        <p class="table-item-link font-weight-bold">{{ item.name }}</p>
      </router-link>
    </template>
    <template v-slot:item.isExam="{ item }">
      <v-checkbox v-model="item.isExam" disabled />
    </template>
    <template v-if="hasActions" v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="$emit('editItem', item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="$emit('deleteItem', item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "QuizTable",
  props: {
    quizzes: Array,
    search: String,
    loading: Boolean,
    serverItemsLength: Number,
    hasActions: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (!this.hasActions) {
      this.headers.pop();
    }
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    },
    search() {
      this.options.page = 1;
      this.getData();
    }
  },
  methods: {
    async getData() {
      this.options.keyword = this.search;
      this.$emit("onOptionsChange", this.options);
    }
  },
  data: () => ({
    options: {},
    headers: [
      { text: "Sınav Adı", value: "name" },
      { text: "Sınıf", value: "courseCategory.name" },
      { text: "Ders", value: "lecture.name" },
      { text: "Konu", value: "subject.name" },
      { text: "Video", value: "material.name" },
      { text: "Deneme Sınavı", value: "isExam" },
      { text: "Yayın Durumu", value: "published" },
      { text: "Oluşturma", value: "createdDate" },
      { text: "Son Düzenleme", value: "lastModifiedDate" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ]
  })
};
</script>

<style></style>
