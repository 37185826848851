<template>
  <div v-if="user">
    <div v-if="courseCategories" class="mb-5">
      <v-toolbar class="rounded-lg">
        <v-toolbar-title>
          <strong>{{ user.firstName + " " + user.lastName }}</strong>
          isimli öğrencinin
          <strong>{{ courseCategory.name }}</strong>
          Karnesi
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mt-5">
          <v-select
            @change="changeCourseCategory"
            :items="courseCategories"
            :value="courseCategory.id"
            label="Sınıfı değiştir"
            item-text="name"
            item-value="id"
            dense
            return-object
          ></v-select>
        </div>
      </v-toolbar>
    </div>
    <QuizQuestionReport :userId="user.id" :courseCategoryId="courseCategory.id" />
    <SubjectQuizReport :user="user" :courseCategoryId="courseCategory.id" />
    <ExamReport :userId="user.id" :courseCategoryId="courseCategory.id" />
    <VideoReport :userId="user.id" :courseCategoryId="courseCategory.id" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/common/api.service";
import QuizQuestionReport from "./QuizQuestionReport";
import SubjectQuizReport from "./SubjectQuizReport";
import VideoReport from "./VideoReport";
import ExamReport from "./ExamReport";

export default {
  name: "UserReport",
  components: { QuizQuestionReport, SubjectQuizReport, VideoReport, ExamReport },
  props: ["requestedUserId"],
  data() {
    return {
      user: null,
      courseCategory: null
    };
  },
  methods: {
    changeCourseCategory(courseCategory) {
      this.courseCategory = courseCategory;
    }
  },
  created() {
    let userId = this.requestedUserId;

    if (!this.requestedUserId) {
      userId = this.currentUser.id;
    }

    ApiService.get(`users/${userId}?projection=MinimalUserProjection`).then(res => {
      this.user = res.data;
      this.courseCategory = this.user.courseCategory;
    });
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("course", ["courseCategories"])
  }
};
</script>

<style></style>
