<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Öğrenci Kayıtları</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="show = false">
            İptal
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <bar-chart v-if="chartLoaded" :chart-data="dataCollection"></bar-chart>
        <v-divider />
        <v-data-table
          v-if="logs.length"
          :headers="headers"
          :items="logs"
          :options.sync="options"
          :server-items-length="totalLogs"
          :loading="loading"
          :items-per-page="5"
          :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
          class="elevation-1"
        >
          <template v-slot:item.date="{ item }">
            {{ formatDate(item.date) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BarChart from "../Report/BarChart";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "UserLogsDialog",
  props: ["value", "userId"],
  components: { BarChart },
  data() {
    return {
      loading: false,
      totalLogs: 0,
      chartLoaded: false,
      options: {},
      dataCollection: null,
      logs: [],
      headers: [
        { text: "Tarih", value: "date", sortable: false },
        { text: "Olay", value: "message", sortable: false }
      ]
    };
  },
  watch: {
    value(val) {
      if (val) {
        moment.locale("tr");
        this.getData();
        this.initChart();
      }
    },
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions("user", ["getLogs", "getActionsCount"]),
    formatDate(date) {
      if (!date) {
        return "Hiçbir zaman";
      }
      let parsedDate = moment(date);
      if (moment().diff(parsedDate, "days") > 1) return parsedDate.format("D.M.YYYY HH:mm:ss");

      return parsedDate.fromNow();
    },
    async getData() {
      let res = await this.getLogs({ userId: this.userId, options: this.options });
      this.logs = res.content;
      this.totalLogs = res.totalElements;
    },
    initChart() {
      this.getActionsCount(this.userId).then(res => {
        let labels = [];
        let chartData = [];
        if (res) {
          res.filter(report => {
            labels.push(report.date);
            chartData.push(report.count);
          });
        }
        this.dataCollection = {
          labels: labels,
          datasets: [
            {
              label: "Aksiyon Sayısı",
              backgroundColor: "rgba(255, 99, 132, 0.4)",
              data: chartData,
              borderWidth: 2
            }
          ]
        };
        this.chartLoaded = true;
      });
    }
  }
};
</script>

<style></style>
