<template>
  <v-card class="pa-5">
    <v-card-title>
      <span class="headline">{{ material.name }}</span>
    </v-card-title>
    <v-row justify="center">
      <v-col cols="12" lg="6">
        <video-player v-if="material.media.url != null" :options="playerOptions" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        color="secondary"
        v-if="material.quiz && material.quiz.published"
        @click="$router.push('/quiz-attempt/' + material.quiz.id)"
      >
        Tarama Testini Çöz
      </v-btn>
      <v-btn v-if="material.watched" color="warning" @click="removeFromWatchedVideos">İzlemedim Olarak İşaretle</v-btn>
      <v-btn v-else color="primary" @click="addToWatchedVideos">İzledim Olarak İşaretle</v-btn>
    </v-row>
  </v-card>
</template>
<script>
import "video.js/dist/video-js.css";

import { videoPlayer } from "vue-video-player";
import ApiService from "../../common/api.service";
export default {
  components: { videoPlayer },
  async mounted() {
    await this.fetchMaterial();
    await this.hasWatchedVideo();
  },
  data: function() {
    return {
      material: { media: { url: null }, watched: false }
    };
  },
  computed: {
    playerOptions() {
      return {
        fluid: true,
        language: "tr",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: this.material.media.url
          }
        ],
        poster:
          "https://dershane.bykurs.com.tr/pluginfile.php/1/theme_moove/logo/1585232822/bykurs-atom-akademi-logo.png"
      };
    }
  },
  methods: {
    async fetchMaterial() {
      let res = await ApiService.get(`materials/${this.$route.params.materialId}?projection=MaterialProjection`);
      res.data.watched = false;
      this.material = res.data;
    },
    async hasWatchedVideo() {
      let res = await ApiService.get(`users/hasWatchedVideo/${this.material.id}`);
      this.material.watched = res.data;
    },
    addToWatchedVideos() {
      ApiService.post(`users/addWatchedVideo/${this.material.id}`);
      this.material.watched = true;
    },
    removeFromWatchedVideos() {
      ApiService.post(`users/removeFromWatchedVideos/${this.material.id}`);
      this.material.watched = false;
    }
  }
};
</script>
