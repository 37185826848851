var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"list-subjects","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Ara","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableItems,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Konular")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"dark":""},on:{"click":function($event){return _vm.editItem()}}},[_vm._v(" Yeni Konu ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"font-weight-bold"},[_c('router-link',{attrs:{"to":{
                name: 'subjects-admin-detail',
                params: { subjectId: item.id }
              }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])})],1)],1),_c('EditDialog',{attrs:{"edited-item":_vm.editedItem,"lectures":_vm.lectures,"form-title":_vm.formTitle,"parent-lecture":_vm.parentLecture},on:{"onSave":_vm.save,"onCancel":_vm.close},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }