<template>
  <v-card class="pa-10">
    <v-row style="flex-direction: column">
      <v-col>
        <v-sheet class="elevation-5" style="display: flex; align-items: center" color="#264653">
          <h2 class="font-weight-regular ma-2" style="color: #f1faee">
            <span v-html="question.content"></span>
          </h2>
        </v-sheet>
      </v-col>
      <v-col>
        <v-row style="flex-direction: column">
          <v-col v-for="answer in question.answers" v-bind:key="answer.id" style="padding-bottom: 0; padding-top: 5px">
            <v-sheet
              style="display:flex; justify-content: space-between; align-items: center"
              :color="calculateColor(answer)"
              class="elevation-5"
            >
              <h2 class="font-weight-regular" style="color: #f1faee; margin-left: 5px">
                <span v-html="answer.title"></span>
              </h2>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="question.feedbackVideo">
        <h2>Çözüm Videosu</h2>
        <video-player :options="playerOptions" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  components: { videoPlayer },
  props: ["question", "userAnswer", "correctAnswer"],
  name: "Question",
  methods: {
    calculateColor(answer) {
      if (answer.id === this.correctAnswer?.id) {
        return "#2a9d8f";
      }
      if (answer.id === this.userAnswer?.id) {
        return "#d62828";
      }
      return "#5e6472";
    }
  },
  computed: {
    playerOptions() {
      return {
        fluid: true,
        language: "tr",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: this.question.feedbackVideo.url
          }
        ],
        poster:
          "https://dershane.bykurs.com.tr/pluginfile.php/1/theme_moove/logo/1585232822/bykurs-atom-akademi-logo.png"
      };
    }
  }
};
</script>

<style scoped></style>
