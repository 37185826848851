<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row v-if="!success" align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{ $t("user.forgotPassword") }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <ul v-if="errors" class="error-messages">
                  <li v-for="(v, k) in errors" :key="k">{{ k }} {{ v | error }}</li>
                </ul>
                <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="onSubmit(email)">
                  <v-form>
                    <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email">
                      <v-text-field
                        autocomplete="off"
                        label="Email"
                        prepend-icon="email"
                        type="email"
                        :error-messages="errors"
                        v-model="email"
                      />
                    </ValidationProvider>
                    <v-row justify="end">
                      <v-btn type="submit" color="primary">
                        {{ $t("common.update") }}
                      </v-btn>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="success" align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="pa-12">
              <v-row justify="center">
                <v-icon x-large color="primary">mdi-checkbox-marked-circle</v-icon>
              </v-row>
              <v-row>
                <span class="font-weight-medium">
                  Mail adresinize sıfırlama maili gönderdik. Mailinizi kontrol ediniz. Spam klasörüne bakmayı
                  unutmayınız.
                </span>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      user: state => state.auth.user
    })
  },
  data() {
    return {
      email: null,
      success: false
    };
  },
  methods: {
    async onSubmit(email) {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          await this.resetPassword(email);
          this.success = true;
        } catch (e) {
          this.$toast.error("Mail bulunamadı");
        }
      }
    },
    ...mapActions("auth", ["resetPassword"])
  }
};
</script>
