import Vue from "vue";
import VueRouter from "vue-router";

import Home from "./views/Home/Home";
import Login from "./views/Auth/Login/Login";
import Profile from "./views/Profile/Profile";
import UserSettings from "./views/Profile/UserSettings";
import NotFound from "./components/NotFound";
import UpdatePassword from "./views/Auth/UpdatePassword/UpdatePassword";
import ForgotPassword from "./views/Auth/ForgotPassword/ForgotPassword";
import Index from "./views/Index";
import ValidatePasswordToken from "./views/Auth/ValidatePasswordToken/ValidatePasswordToken";
import Courses from "./views/Course/Courses";
import ValidateMailToken from "./views/Auth/ValidateMailToken/ValidateMailToken";
import LectureDetail from "./views/Lecture/LectureDetail";
import CourseCategories from "./views/CourseCategory/CourseCategories";
import CourseCategoryDetail from "./views/CourseCategory/Detail";
import SubjectDetail from "./views/Subject/SubjectDetail";
import Quizzes from "./views/Quiz/Quizzes";
import AddQuestion from "./views/Question/AddQuestion";
import EditQuestion from "./views/Question/EditQuestion";
import QuizDetail from "./views/Quiz/QuizDetail";
import UserList from "./views/UserList/UserList.vue";
import Notes from "./views/UserNote/Notes";
import QuizAttempt from "./views/QuizAttempt/QuizAttempt";
import QuizResult from "./views/QuizAttempt/Result";
import Badges from "./views/Badge/Badges";
import UserBadges from "./views/UserBadge/UserBadges";
import AddMaterial from "./views/Subject/partials/Materials/AddMaterial";
import MyQuestions from "./views/QA/MyQuestions";
import Question from "./views/QA/Question";
import AllQuestions from "./views/QA/AllQuestions";
import StudentLecture from "./views/StudentLecture/StudentLecture";
import StudentSubject from "./views/StudentSubject/StudentSubject";
import Video from "./views/Video/Video";
import Announcements from "./views/Announcement/Announcements";
import MyStudents from "./views/UserList/MyStudents";
import JoinWebinar from "./views/Webinar/JoinWebinar";
import TechError from "./views/TechError/TechError";
import WebinarLectureList from "./views/StudentWebinar/WebinarLectureList";
import ArchiveLectureList from "./views/StudentArchive/ArchiveLectureList";
import QuizLectureList from "./views/StudentQuiz/QuizLectureList";
import WebinarList from "./views/StudentWebinar/WebinarList";
import ExamList from "./views/StudentQuiz/ExamList";
import Salesmen from "./views/UserList/Salesmen";
import UserReport from "./views/Report/UserReport";
import Leaderboard from "./views/Leaderboard/Leaderboard";
import ExperimentsList from "./views/Interactive/Experiments/ExperimentsList";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Index,
    meta: { authorize: ["ROLE_USER"] },
    children: [
      {
        path: "/",
        name: "home-lectures",
        component: Home,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/profile/:id(\\d+)",
        name: "profile",
        component: Profile,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/profile/settings",
        name: "user-settings",
        component: UserSettings,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/courses",
        name: "courses",
        component: Courses,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/users",
        name: "users",
        component: UserList,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/my-students",
        name: "my-students",
        component: MyStudents,
        meta: { authorize: ["ROLE_SALESMAN", "ROLE_VENDOR"] }
      },
      {
        path: "/lectures/:lectureId",
        name: "lectures-admin-detail",
        component: LectureDetail,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/course-categories",
        name: "course-categories-admin",
        component: CourseCategories,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/course-categories/:categoryId",
        name: "course-category-detail-admin",
        component: CourseCategoryDetail,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/subjects/:subjectId",
        name: "subjects-admin-detail",
        component: SubjectDetail,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/quizzes",
        name: "quizzes-admin",
        component: Quizzes,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/quizzes/:quizId/add-question",
        name: "add-question-to-quiz",
        component: AddQuestion,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/question/:questionId/edit",
        name: "edit-question",
        component: EditQuestion,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/quizzes/:quizId",
        name: "quiz-detail",
        component: QuizDetail,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/notes/:userId(\\d+)",
        name: "notes",
        component: Notes,
        meta: { authorize: ["ROLE_ADMIN", "ROLE_SALESMAN", "ROLE_VENDOR"] }
      },
      {
        path: "/quiz-attempt/:quizId(\\d+)",
        name: "quiz-attempt",
        component: QuizAttempt,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/quiz-result/:attemptId(\\d+)/:quizId(\\d+)",
        name: "quiz-result",
        component: QuizResult,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/badges",
        name: "badges-admin",
        component: Badges,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/user-badges/:userId(\\d+)",
        name: "user-badges",
        component: UserBadges,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/subject/:subjectId/materials/add",
        name: "add-material-to-subject",
        component: AddMaterial,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/qa/my",
        name: "my-questions",
        component: MyQuestions,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/qa/all",
        name: "qa-all-questions",
        component: AllQuestions,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/qa/question/:id(\\d+)",
        name: "qa-question",
        component: Question,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/student/lecture/:id(\\d+)",
        name: "student-lecture",
        component: StudentLecture,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/student/lecture/:id(\\d+)/webinars",
        name: "student-webinar-list",
        component: WebinarList,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/student/subject/:id(\\d+)",
        name: "student-subject",
        component: StudentSubject,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/student/video/:materialId(\\d+)",
        name: "student-video",
        component: Video,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/announcements",
        name: "announcements",
        component: Announcements,
        meta: { authorize: ["ROLE_ADMIN"] }
      },
      {
        path: "/webinar/:webinarId(\\d+)/join",
        name: "join-webinar",
        component: JoinWebinar,
        meta: { authorize: ["ROLE_ADMIN", "ROLE_SALESMAN", "ROLE_VENDOR", "ROLE_STUDIO"] }
      },
      {
        path: "/student/webinar",
        name: "student-webinar",
        component: WebinarLectureList,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/student/archive",
        name: "student-archive",
        component: ArchiveLectureList,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/student/quiz",
        name: "student-quiz",
        component: QuizLectureList,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/student/exams",
        name: "student-exam",
        component: ExamList,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/salesmen",
        name: "salesmen",
        component: Salesmen,
        meta: { authorize: ["ROLE_VENDOR"] }
      },
      {
        path: "/report",
        name: "user-report",
        component: UserReport,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/leaderboard",
        name: "leaderboard",
        component: Leaderboard,
        meta: { authorize: ["ROLE_USER"] }
      },
      {
        path: "/interactive/experiments",
        name: "expertiments-list",
        component: ExperimentsList,
        meta: { authorize: ["ROLE_USER"] }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/update-password",
    name: "update-password",
    component: UpdatePassword,
    meta: { authorize: ["ROLE_CHANGE_PASSWORD"] }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: { authorize: ["ANONYMOUS"] }
  },
  {
    path: "/validate-password-token/:token",
    name: "validate-password-token",
    component: ValidatePasswordToken,
    meta: { authorize: ["ANONYMOUS"] }
  },
  {
    path: "/validate-mail-token/:token",
    name: "validate-mail-token",
    component: ValidateMailToken,
    meta: { authorize: ["ROLE_USER", "ANONYMOUS"] }
  },
  {
    path: "/tech-error",
    name: "tech-error",
    component: TechError
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
