<template>
  <v-card class="mx-auto">
    <v-list two-line class="user-detail-card">
      <v-list-item>
        <v-list-item-icon><v-icon color="indigo">account_circle</v-icon></v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.username }}</v-list-item-title>
          <v-list-item-subtitle>Kullanıcı Adı</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.mail">
        <v-list-item-icon>
          <v-icon color="indigo">mdi-email</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.mail }}</v-list-item-title>
          <v-list-item-subtitle>E-mail</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="user.city">
        <v-list-item-icon>
          <v-icon color="indigo">mdi-city</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.city }}</v-list-item-title>
          <v-list-item-subtitle>Şehir</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.phoneNumber">
        <v-list-item-icon>
          <v-icon color="indigo">mdi-phone</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.phoneNumber }}</v-list-item-title>
          <v-list-item-subtitle>Telefon Numarası</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>mdi-message-text</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item v-if="user.parentName">
        <v-list-item-icon>
          <v-icon color="indigo">supervisor_account</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ user.parentName }}</v-list-item-title>
          <v-list-item-subtitle>Veli Ad Soyad</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="user.parentPhoneNumber">
        <v-list-item-icon>
          <v-icon color="indigo">supervisor_account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ user.parentPhoneNumber }}</v-list-item-title>
          <v-list-item-subtitle>Veli Telefon</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>mdi-message-text</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider inset></v-divider>

      <v-list-item v-if="user.registerDate">
        <v-list-item-icon>
          <v-icon color="indigo">update</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ registerDate }}</v-list-item-title>
          <v-list-item-subtitle>Kayıt Tarihi</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <template v-if="authorized">
        <v-list-item v-if="user.membershipDuration">
          <v-list-item-icon>
            <v-icon color="indigo">hourglass_bottom</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ user.membershipDuration }} Gün</v-list-item-title>
            <v-list-item-subtitle>Kayıt Süresi</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="user.membershipDuration && user.registerDate">
          <v-list-item-icon>
            <v-icon color="indigo">autorenew</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ calculateRemaining(user) }}</v-list-item-title>
            <v-list-item-subtitle>Kalan Süre</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item v-if="user.registeredBy">
          <v-list-item-icon>
            <v-icon color="indigo">account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ user.registeredBy.firstName + " " + user.registeredBy.lastName }}</v-list-item-title>
            <v-list-item-subtitle>Satış Temsilcisi</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="user.vendor">
          <v-list-item-icon>
            <v-icon color="indigo">work</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ user.vendor.name }}</v-list-item-title>
            <v-list-item-subtitle>Firma</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "UserCard",
  props: ["user"],
  data() {
    return {
      authorized: false
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    registerDate() {
      moment.locale("tr");
      return moment(this.user.registerDate).format("D MMMM YYYY");
    }
  },
  created() {
    let authorizedRoles = ["ROLE_ADMIN", "ROLE_VENDOR", "ROLE_SALESMAN"];
    authorizedRoles.forEach(roleToCheck => {
      if (this.currentUser.roles.includes(roleToCheck)) {
        this.authorized = true;
      }
    });
  },
  methods: {
    calculateRemaining(user) {
      let registerDate = moment(user.registerDate);
      registerDate.add(user.membershipDuration, "d");
      let remainingDays = registerDate.diff(moment(), "days");
      if (remainingDays < 0) return "Üyelik Süresi Dolmuş";

      return remainingDays + " gün";
    }
  }
};
</script>

<style>
.user-detail-card .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 16px;
  font-weight: 400;
}
</style>
