const getters = {
  courseCategories(state) {
    return state.courseCategories;
  },
  courses(state) {
    return state.courses;
  },
  lectures(state) {
    return state.lectures;
  },
  subjects(state) {
    return state.subjects;
  }
};

export default getters;
