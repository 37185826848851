<template>
  <v-container id="list-lectures" fluid tag="section">
    <v-row justify="end">
      <v-col md="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
      </v-col>
      <v-btn class="mt-4" @click="editItem()">
        Yeni Ders
      </v-btn>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-data-table :headers="headers" :items="lectures" :search="search">
          <template v-slot:item.name="{ item }">
            <p class="font-weight-bold">
              <router-link
                class="bold"
                :to="{
                  name: 'lectures-admin-detail',
                  params: { lectureId: item.id }
                }"
              >
                {{ item.name }}
              </router-link>
            </p>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <EditDialog
      v-model="editDialog"
      :edited-item="editedItem"
      :course-categories="courseCategories"
      :form-title="formTitle"
      @onSave="save"
      @onCancel="close"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditDialog from "./EditDialog";

export default {
  props: ["courseCategoryId"],
  components: { EditDialog },
  data() {
    return {
      search: "",
      editDialog: false,
      headers: [
        { text: "Ders Adı", value: "name" },
        { text: "Sınıf", value: "courseCategory.name" },
        { text: "Oluşturma", value: "createdDate" },
        { text: "Son Düzenleme", value: "lastModifiedDate" },
        { text: "Aksiyonlar", value: "actions", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        name: "",
        courseCategory: this.courseCategoryId
      },
      defaultItem: {
        id: 0,
        name: "",
        courseCategory: 0
      }
    };
  },
  computed: {
    ...mapGetters("course", ["lectures", "courseCategories"]),
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Ders" : "Dersi Düzenle";
    }
  },

  mounted() {
    Promise.all([this.fetchLectures(this.courseCategoryId), this.fetchCourseCategories("DefinitionProjection")]);
  },

  watch: {
    editDialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions("course", [
      "fetchLectures",
      "fetchCourseCategories",
      "createLecture",
      "updateLecture",
      "deleteLecture"
    ]),

    editItem(item) {
      if (item) {
        this.editedIndex = this.lectures.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "name", "courseCategory"]);
        this.editedItem.courseCategory = item.courseCategory.id;
      }

      this.editDialog = true;
    },

    save() {
      if (this.editedIndex > -1) {
        this.updateLecture(this.editedItem);
      } else {
        this.createLecture(this.editedItem);
      }
      this.close();
    },

    close() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItem(lecture) {
      if (confirm("Bu derse dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        this.deleteLecture(lecture);
      }
    }
  }
};
</script>
