import ApiService from "@/common/api.service";
import { SET_QUIZZES } from "../mutations.type";

const actions = {
  async fetchQuizzes(context, projection = "") {
    const res = await ApiService.get(`quizzes?projection=${projection}`);
    context.commit(SET_QUIZZES, res.data["_embedded"].quizzes);
  },
  async searchQuizzes(context, options) {
    let desc = options.sortDesc[0] ? "desc" : "asc";

    const res = await ApiService.get(
      `quizzes/search/searchQuizzes?keyword=${options.keyword}&page=${options.page - 1}&size=${
        options.itemsPerPage
      }&sort=${options.sortBy},${desc}&projection=QuizListProjection`
    );
    context.commit(SET_QUIZZES, res.data["_embedded"].quizzes);
    return res.data;
  },
  async fetchExamsByCourseCategory(context, courseCategoryId) {
    const res = await ApiService.get(`quizzes/search/getAllExams?courseCategoryId=${courseCategoryId}`);
    return res.data._embedded.quizzes;
  },
  async fetchSubjectQuizzes(context, subjectId) {
    const res = await ApiService.get(`subjects/${subjectId}/quizzes?projection=QuizListProjection&size=1000`);
    context.commit(SET_QUIZZES, res.data["_embedded"].quizzes);
  },
  async fetchQuiz(context, id) {
    const res = await ApiService.get(`quizzes/${id}?projection=MinimalQuizProjection`);
    return res.data;
  },
  async fetchUserAttempts(context, quizId) {
    const res = await ApiService.get(
      `/attempts/search/getCurrentUsersAttempts?quizId=${quizId}&projection=AttemptProjection`
    );
    return res.data["_embedded"].attempts;
  },
  async createQuiz(context, quiz) {
    let copy = { ...quiz };
    copy.courseCategory = `courseCategories/${quiz.courseCategory}`;
    if (!quiz.isExam) {
      copy.lecture = `lectures/${quiz.lecture}`;
      copy.subject = `subjects/${quiz.subject}`;
      if (quiz.material) {
        copy.material = `materials/${quiz.material}`;
      }
    }
    await ApiService.post("quizzes", copy);
  },
  async updateQuiz(context, quiz) {
    let copy = { ...quiz };
    copy.courseCategory = `courseCategories/${quiz.courseCategory}`;
    if (!quiz.isExam) {
      copy.lecture = `lectures/${quiz.lecture}`;
      copy.subject = `subjects/${quiz.subject}`;
      if (quiz.material) {
        copy.material = `materials/${quiz.material}`;
      }
    }
    await ApiService.patch("quizzes/" + quiz.id, copy);
  },
  async deleteQuiz(context, quiz) {
    await ApiService.delete("quizzes/" + quiz.id);
  },
  async fetchQuestions(context, quizId) {
    const res = await ApiService.get(`quizzes/${quizId}/questions`);
    return res.data["_embedded"].questions;
  },
  async fetchMinQuestions(context, quizId) {
    const res = await ApiService.get(`quizzes/quizQuestions/${quizId}`);
    return res.data;
  },
  async fetchQuestionsWithAnswers(context, quizId) {
    const res = await ApiService.get(`questions/all?quizId=${quizId}`);
    return res.data;
  },
  async fetchAttempt(context, attemptId) {
    let res = await ApiService.get(`attempts/${attemptId}`);
    return res.data;
  },
  async fetchQuizWithQuestions(context, quizId) {
    let res = await ApiService.get(`quizzes/${quizId}?projection=QuizWithQuestions`);
    return res.data;
  },
  async fetchAttempts(context, quizId) {
    let res = await ApiService.get(`quizzes/${quizId}/attempts?projection=AttemptListProjection`);
    return res.data["_embedded"].attempts;
  },
  async fetchAverageScore(context, quizId) {
    let res = await ApiService.get(`attempts/search/getAverageScore?quizId=${quizId}`);
    return res.data;
  },
  async fetchResult(context, attemptId) {
    let res = await ApiService.get(`userProgress/result?attemptId=${attemptId}`);

    return res.data;
  },
  async fetchUserProgresses(context, attemptId) {
    let res = await ApiService.get(`userProgress/all?attemptId=${attemptId}`);
    return res.data;
  }
};

export default actions;
