import ApiService from "@/common/api.service";
import { SET_COURSE_CATEGORIES, SET_COURSES, SET_LECTURES, SET_SUBJECTS } from "../mutations.type";

const actions = {
  async fetchCourseCategories(context, projection = "") {
    const res = await ApiService.get(`courseCategories?projection=${projection}&size=1000`);
    context.commit(SET_COURSE_CATEGORIES, res.data["_embedded"].courseCategories);
  },
  async fetchCourses(context, params = "") {
    const res = await ApiService.get(`courses?projection&${params}&size=1000`);
    context.commit(SET_COURSES, res.data["_embedded"].courses);
  },
  async fetchLectures(context, courseCategoryId) {
    let url = "lectures?projection=LectureProjection&sort=name,asc&size=1000";
    if (courseCategoryId) {
      url = `courseCategories/${courseCategoryId}/` + url;
    }
    const res = await ApiService.get(url);
    context.commit(SET_LECTURES, res.data["_embedded"].lectures);
  },
  async fetchSubjects(context, projection = "") {
    const res = await ApiService.get(`subjects?projection=${projection}&size=1000`);
    context.commit(SET_SUBJECTS, res.data["_embedded"].subjects);
  },
  async createCourseCategory(context, courseCategory) {
    courseCategory.course = `courses/${courseCategory.course}`;
    await ApiService.post("courseCategories", courseCategory);
    context.dispatch("fetchCourseCategories", "CourseCategory");
  },
  async updateCourseCategory(context, courseCategory) {
    courseCategory.course = `courses/${courseCategory.course}`;
    await ApiService.patch("courseCategories/" + courseCategory.id, courseCategory);
    context.dispatch("fetchCourseCategories", "CourseCategory");
  },
  async deleteCourseCategory(context, courseCategory) {
    await ApiService.delete("courseCategories/" + courseCategory.id);
    context.dispatch("fetchCourseCategories", "CourseCategory");
  },
  createCourse(context, course) {
    return ApiService.post("courses", course);
  },
  updateCourse(context, course) {
    return ApiService.patch("courses/" + course.id, course);
  },
  deleteCourse(context, course) {
    return ApiService.delete("courses/" + course.id);
  },
  async createLecture(context, lecture) {
    lecture.courseCategory = `courseCategories/${lecture.courseCategory}`;
    await ApiService.post("lectures", lecture);
    context.dispatch("fetchLectures");
  },
  async updateLecture(context, lecture) {
    lecture.courseCategory = `courseCategories/${lecture.courseCategory}`;
    await ApiService.patch("lectures/" + lecture.id, lecture);
    context.dispatch("fetchLectures");
  },
  async deleteLecture(context, lecture) {
    await ApiService.delete("lectures/" + lecture.id);
    context.dispatch("fetchLectures");
  },
  async createSubject(context, subject) {
    subject.lecture = `lectures/${subject.lecture}`;
    await ApiService.post("subjects", subject);
    context.dispatch("fetchSubjects", "SubjectProjection");
  },
  async updateSubject(context, subject) {
    subject.lecture = `lectures/${subject.lecture}`;
    await ApiService.patch("subjects/" + subject.id, subject);
    context.dispatch("fetchSubjects", "SubjectProjection");
  },
  async deleteSubject(context, subject) {
    await ApiService.delete("subjects/" + subject.id);
    context.dispatch("fetchSubjects", "SubjectProjection");
  }
};

export default actions;
