<template>
  <div class="webinar-buttons">
    <v-btn v-if="!isRunning" @click="joinWebinar" class="join">Canlı Dersi Başlat</v-btn>
    <template v-if="isRunning">
      <v-btn @click="joinWebinar" class="join">Canlı Derse Katıl</v-btn>
      <v-btn class="error" @click="endWebinar">Canlı Dersi Sonlandır</v-btn>
    </template>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "WebinarControls",
  props: ["webinar"],
  data: () => ({
    isRunning: false
  }),
  mounted() {
    this.getIsRunning();
  },
  methods: {
    getIsRunning() {
      ApiService.get(`webinars/${this.webinar.id}/isRunning`).then(res => {
        this.isRunning = res.data.status;
      });
    },
    joinWebinar() {
      ApiService.get(`webinars/${this.webinar.id}/join`).then(res => {
        let joinUrl = res.data.joinUrl;
        window.open(joinUrl);
      });
    },
    endWebinar() {
      ApiService.get(`webinars/${this.webinar.id}/end`).then(() => {
        this.getIsRunning();
      });
    }
  }
};
</script>

<style>
.webinar-buttons button {
  margin-right: 1rem;
}
.webinar-buttons button.join {
  background-color: rgb(92, 187, 246) !important;
  border-color: rgb(92, 187, 246) !important;
}
</style>
