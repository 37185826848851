<template>
  <router-view></router-view>
</template>

<script>
import SockJS from "sockjs-client";
import * as Stomp from "@stomp/stompjs";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import { StorageService, USER } from "./common/storage.service";
import { API_URL } from "./common/config";

export default {
  name: "App",
  watch: {
    username: {
      handler(user) {
        if (user) {
          this.connect();
        }
      }
    }
  },
  methods: {
    ...mapActions("notification", ["fetchNotifications"]),
    connect() {
      let username = this.username;
      let token = StorageService.getItem(USER).token;
      if (username) {
        let stompClient = Stomp.Stomp.over(function() {
          return new SockJS(`${API_URL}websocket`);
        });
        let onConnect = () => {
          stompClient.subscribe(`/user/${username}/queue/award`, val => {
            let data = JSON.parse(val.body);
            this.$toast.success(`${data.message} ${data.xp} puan kazandınız`);
          });
          stompClient.subscribe(`/user/${username}/queue/notification`, val => {
            let data = JSON.parse(val.body);
            this.$toast.info(data.message);
            this.fetchNotifications();
          });
        };

        let connect = _token => {
          stompClient.connect({ Authorization: "Bearer " + _token }, onConnect);
        };
        stompClient.onStompError = async function(frame) {
          console.log(frame);
        };
        connect(token);
        Vue.prototype.stompClient = stompClient;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    username() {
      return this.currentUser.username;
    }
  }
};
</script>
