<template>
  <v-app>
    <v-container class="text-center" fill-height style="height: calc(100vh - 58px);">
      <v-row align="center" v-if="error">
        <v-col>
          <h1 class="display-2">
            {{ $t("common.mailTokenError") }}
          </h1>

          <v-btn :to="`/`" color="secondary" outlined>
            {{ $t("common.saveMe") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      error: ""
    };
  },
  async mounted() {
    let token = this.$route.params.token;
    try {
      await this.validateMailToken(token);
      await this.$router.push("/");
    } catch (e) {
      this.error = e;
    }
  },
  methods: {
    ...mapActions("auth", ["validateMailToken"])
  }
};
</script>
