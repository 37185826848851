<template>
  <v-card class="mx-auto" raised>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4 justify-space-between align-center" style="display: flex">
          <span>{{ subject.courseCategoryName }} {{ subject.lectureName }}</span>
          <v-btn icon x-small @click="favOrUnfavSubject(subject)">
            <v-icon color="red">
              {{ heart }}
            </v-icon>
          </v-btn>
        </div>
        <router-link
          class="black--text"
          :to="{ name: 'student-subject', params: { id: subject.id }, query: { filter: filter } }"
        >
          <v-list-item-title :title="subject.name" class="headline mb-1">{{ subject.name }}</v-list-item-title>
        </router-link>
        <v-progress-linear
          class="white--text"
          style="margin-top: 20px"
          height="15px"
          :value="subject.completeRate"
          color="primary"
        >
          İlerlemem
        </v-progress-linear>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
import ApiService from "../../common/api.service";

export default {
  props: ["subject", "filter"],
  data: function() {
    return {
      hover: false
    };
  },
  computed: {
    heart() {
      if (this.subject.favorited) {
        if (this.hover) {
          return "mdi-heart-outline";
        }
        return "mdi-heart";
      } else {
        if (this.hover) {
          return "mdi-heart";
        }
        return "mdi-heart-outline";
      }
    }
  },
  methods: {
    favOrUnfavSubject(subject) {
      if (subject.favorited) {
        ApiService.post(`users/unfavSubject/${subject.id}`);
        this.$set(subject, "favorited", false);
      } else {
        ApiService.post(`users/favSubject/${subject.id}`);
        this.$set(subject, "favorited", true);
      }
    }
  }
};
</script>
