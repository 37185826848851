<template>
  <v-container id="list-quizzes" fluid tag="section">
    <v-card class="pa-3">
      <v-row justify="end">
        <v-col md="3">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <quiz-table
            :quizzes="quizzes"
            :search="search"
            :server-items-length="itemsLength"
            @newItem="editItem"
            @editItem="editItem"
            @deleteItem="deleteItem($event)"
            @onOptionsChange="setOptions"
            :loading="loading"
          ></quiz-table>
        </v-col>
      </v-row>
      <EditDialog
        v-model="editDialog"
        :edited-item="editedItem"
        :form-title="formTitle"
        :course-categories="courseCategories"
        :lectures="lectures"
        :subjects="subjects"
        :materials="materials"
        @onSave="save"
        @onCancel="close"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditDialog from "./EditDialog";
import QuizTable from "./QuizTable";
import ApiService from "../../common/api.service";

export default {
  props: ["subject"],
  components: { EditDialog, QuizTable },
  data: () => ({
    search: "",
    editDialog: false,
    editedIndex: -1,
    editedItem: {},
    lectures: [],
    subjects: [],
    materials: [],
    loading: true,
    itemsLength: 0,
    options: {}
  }),

  computed: {
    ...mapGetters("quiz", ["quizzes"]),
    ...mapGetters("course", ["courseCategories"]),
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Sınav" : "Sınavı Düzenle";
    },
    selectedCourseCategory() {
      return this.editedItem.courseCategory;
    },
    selectedLecture() {
      return this.editedItem.lecture;
    },
    selectedSubject() {
      return this.editedItem.subject;
    },
    isExam() {
      return this.editedItem.isExam;
    }
  },

  mounted() {
    if (this.subject) {
      Promise.all([this.fetchSubjectQuizzes(this.subject.id), this.fetchCourseCategories("CourseCategory")]);
    } else {
      Promise.all([this.fetchCourseCategories("CourseCategory")]);
      this.getData = this.lodash.debounce(this.getData, 300);
    }
  },

  watch: {
    editDialog(val) {
      val || this.close();
    },
    selectedCourseCategory(val) {
      this.lectures = this._.find(this.courseCategories, {
        id: val
      })?.lectures;
    },
    async selectedLecture(val) {
      if (!val) return;
      let res = await ApiService.get(`lectures/${val}/subjects`);
      this.subjects = res.data["_embedded"].subjects;
    },
    async selectedSubject(val) {
      if (!val) return;
      let res = await ApiService.get(`subjects/${val}/materials?projection=MaterialProjection`);
      this.materials = res.data["_embedded"].materials.filter(mat => mat.quiz == null);
    },
    isExam(val) {
      if (val) {
        delete this.editedItem.lecture;
        delete this.editedItem.subject;
      }
    }
  },

  methods: {
    ...mapActions("quiz", ["searchQuizzes", "fetchSubjectQuizzes", "createQuiz", "updateQuiz", "deleteQuiz"]),
    ...mapActions("course", ["fetchCourseCategories"]),

    async setOptions(options) {
      this.options = options;
      this.getData();
    },
    async getData() {
      if (!this.subject) {
        this.loading = true;

        const res = await this.searchQuizzes(this.options);
        this.itemsLength = res.page.totalElements;
        this.loading = false;
      } else {
        this.loading = false;
        this.itemsLength = -1;
      }
    },

    editItem(item) {
      if (item) {
        this.editedIndex = this.quizzes.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "name", "published", "timeLimit", "isExam"]);
      }

      this.editDialog = true;
    },

    save() {
      if (this.editedIndex > -1) {
        this.updateQuiz(this.editedItem);
      } else {
        this.createQuiz(this.editedItem);
      }
      this.close();
      this.getData();
    },

    close() {
      this.editDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteItem(quiz) {
      if (confirm("Bu sınava dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        this.deleteQuiz(quiz);
        this.getData();
      }
    }
  }
};
</script>
<style>
.v-application p.table-item-link {
  margin-bottom: 0;
}
</style>
