<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <v-card-text>
        <v-card-title class="headline">
          Quiz'i bitirmek istiyor musunuz ?
        </v-card-title>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('onCancel')">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="$emit('onEnd')">
          Bitir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
