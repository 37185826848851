<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Sunucu Seçimi</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-radio-group v-model="serverConfig">
            <v-radio v-for="config in configs" :key="config.id" :label="config.url" :value="config.id">
              <template v-slot:label>
                <b style="color:black;">{{ config.url }}</b>
              </template>
            </v-radio>
          </v-radio-group>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateServer">
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "SelectWebinarServer",
  props: ["value", "webinar"],
  data() {
    return {
      serverConfig: null,
      configs: []
    };
  },
  mounted() {
    this.getServerData();
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    updateServer() {
      ApiService.post(`webinars/${this.webinar.id}/serverConfig/${this.serverConfig}`).then(() => {
        this.getServerData();
      });
    },
    getServerData() {
      this.configs = [];
      ApiService.get(`webinars/${this.webinar.id}/serverConfig`).then(res => {
        this.serverConfig = String(res.data);
      });
      ApiService.get(`config/bbb-servers`).then(res => {
        Object.entries(res.data).forEach(([id, url]) => {
          this.configs.push({ id: id, url: url });
        });
      });
    }
  }
};
</script>

<style></style>
