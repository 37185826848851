<template>
  <div>
    <ExamSchedule :courseCategory="courseCategory" />
    <schedule-card />
    <v-card rounded class="rounded-lg">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title v-if="currentUser.roles.includes('ROLE_STATS')">İstatistikler</v-toolbar-title>
        <v-toolbar-title v-else>Derslerim</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" v-if="currentUser.roles.includes('ROLE_STATS')">
            <StatsCard />
          </v-col>
          <v-col v-else v-for="lecture in filtered" v-bind:key="lecture.id" cols="12" sm="5" lg="4" xl="3">
            <LectureCard :lecture="lecture" @onFav="onFav" @onUnFav="onUnFav" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LectureCard from "./LectureCard";
import ScheduleCard from "@/components/Webinar/ScheduleCard";
import ExamSchedule from "@/components/Quiz/ExamSchedule";
import ApiService from "../../common/api.service";
import { mapGetters } from "vuex";
import { COURSE_CATEGORY, StorageService } from "../../common/storage.service";
import StatsCard from "@/views/Home/StatsCard";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { LectureCard, StatsCard, ScheduleCard, ExamSchedule },
  data: () => ({
    lectures: [],
    filtered: [],
    favs: [],
    selectedFilter: null
  }),
  async mounted() {
    await this.getUserLectures();
    this.fetchFavSubjects();
  },
  watch: {
    selectedFilter(val) {
      if (val === "fav") {
        this.filtered = this.favs;
      } else if (val === "live") {
        this.filtered = this.lectures;
        this.$toast.error("not implemented");
      } else {
        this.filtered = this.lectures;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    courseCategory() {
      return StorageService.getItem(COURSE_CATEGORY);
    }
  },
  methods: {
    async getUserLectures() {
      const courseCategoryId = this.courseCategory?.id;
      if (!courseCategoryId) {
        return;
      }
      let res = await ApiService.get(
        `/lectures/search/findAllByCourseCategory_Id?courseCategoryId=${courseCategoryId}&projection=LectureProjection`
      );
      this.lectures = res.data["_embedded"].lectures;
      this.filtered = this.lectures;
    },
    async fetchFavSubjects() {
      let res = await ApiService.get(`users/search/getCurrentUsersFavLectures?projection=LectureProjection`);
      let favs = res.data["_embedded"].lectures;
      for (let i = 0; i < favs.length; i++) {
        let fav = favs[i];
        let found = this.filtered.find(s => s.id === fav.id);
        if (found) {
          this.$set(found, "favorited", true);
          fav.favorited = true;
        }
      }
      this.favs = favs;
    },
    onFav(lecture) {
      this.favs.push(lecture);
    },
    onUnFav(lecture) {
      this.favs = this.favs.filter(f => f.id !== lecture.id);
      if (this.selectedFilter === "fav") {
        this.filtered = this.favs;
      }
    }
  }
};
</script>
