<template>
  <v-dialog v-model="show" max-width="700px">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        Deneme Sınavı Seçiniz
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-data-table
            v-model="selectedQuiz"
            :headers="headers"
            :items="quizzes"
            :single-select="true"
            :hide-default-footer="true"
            item-key="name"
            show-select
            class="elevation-1"
          >
            <template v-slot:item.published="{ item }">
              <v-chip v-if="item.published" class="ma-2" color="primary">
                Yayında
              </v-chip>
              <v-chip v-else class="ma-2" color="red" text-color="white">
                Yayında Değil
              </v-chip>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('onCancel')">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="confirmSave">
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      Sınav seçmeden kayıt yapamazsınız!
      <v-btn color="pink" text @click="snackbar = false">
        Kapat
      </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  name: "SelectQuizDialog",
  props: ["value", "categoryId"],
  data: () => ({
    quizzes: [],
    selectedQuiz: [],
    headers: [
      { text: "Sınav Adı", value: "name" },
      { text: "Yayında?", value: "published" },
      { text: "Süre", value: "timeLimit" },
      { text: "Oluşturma Tarihi", value: "createdDate" }
    ],
    snackbar: false
  }),
  methods: {
    confirmSave() {
      if (!this.selectedQuiz.length) {
        this.snackbar = true;
        return;
      }

      this.$emit("onSave", this.selectedQuiz[0]);
    }
  },
  mounted() {
    ApiService.get("quizzes/search/findAllByCourseCategoryNullAndSubjectNull").then(res => {
      this.quizzes = res.data._embedded.quizzes;
    });
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style></style>
