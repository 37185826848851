<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Şifre Sıfırla</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="password" label="Şifre" type="password"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false">
          İptal
        </v-btn>
        <v-btn color="blue darken-1" text @click="changePassword">
          Değiştir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  props: ["value", "userId"],
  data: () => ({
    password: null
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    changePassword() {
      ApiService.post(`users/${this.userId}/reset-password`, { password: this.password }).then(() => {
        this.show = false;
      });
    }
  }
};
</script>

<style></style>
