<template>
  <v-container id="list-courses" fluid tag="section">
    <v-row justify="center">
      <v-col>
        <v-data-table :headers="headers" :items="courses" sort-by="calories" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Kurslar</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn class="mb-2 black--text font-weight-bold" v-on="on">
                    Yeni Kurs
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-if="editedIndex != -1"
                            v-model="editedItem.id"
                            disabled
                            label="ID"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field @keyup.enter="save" v-model="editedItem.name" label="Kurs Adı"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      İptal
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Kaydet
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id"
      },
      { text: "Kurs Adı", value: "name" },
      { text: "Oluşturma", value: "createdDate" },
      { text: "Son Düzenleme", value: "lastModifiedDate" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    courses: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      createdDate: 0,
      lastModifiedDate: 0
    },
    defaultItem: {
      id: 0,
      name: "",
      createdDate: 0,
      lastModifiedDate: 0
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Kurs" : "Kursı Düzenle";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("course", ["fetchCourses", "createCourse", "updateCourse", "deleteCourse"]),
    ...mapState("course", { courseState: "courses" }),
    initialize() {
      this.fetchCourses().then(() => {
        this.courses = this.courseState();
      });
    },

    editItem(item) {
      this.editedIndex = this.courses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(course) {
      if (confirm("Bu Kursa dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        this.deleteCourse(course).then(() => {
          this.initialize();
        });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.updateCourse(this.editedItem).then(() => {
          this.initialize();
        });
      } else {
        let newCourse = {
          name: this.editedItem.name
        };
        this.createCourse(newCourse).then(() => {
          this.initialize();
        });
      }
      this.close();
    }
  }
};
</script>
