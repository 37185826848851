<template>
  <v-dialog v-model="show" max-width="870px">
    <v-card v-if="experiment">
      <v-card-title>
        <span class="headline">{{ experiment.name }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <iframe :src="experiment.iframe_url" width="800" height="600" scrolling="no" allowfullscreen></iframe>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ExperimentDetail",
  props: ["value", "experiment"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style></style>
