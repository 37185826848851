<template>
  <v-container id="list-courseCategories" fluid tag="section">
    <v-card class="pa-3">
      <v-row justify="end">
        <v-col md="3">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Ara" single-line hide-details />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table :headers="headers" :items="courseCategories" :search="search">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Sınıflar</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="mb-2 black--text font-weight-bold" @click="editItem()">
                  Yeni Sınıf
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'course-category-detail-admin',
                  params: { categoryId: item.id }
                }"
              >
                <p style="font-weight:bold">{{ item.name }}</p>
              </router-link>
            </template>
            <template v-slot:item.lecturesButton="{ item }">
              <v-btn small @click="showLectures(item)">
                Göster
              </v-btn>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <EditDialog
        v-model="dialog"
        :courses="courses"
        :edited-item="editedItem"
        :form-title="formTitle"
        @onCancel="close"
        @onSave="save"
      />
      <LecturesDialog :courseCategory="courseCategory" v-model="lecturesDialog" />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LecturesDialog from "./LecturesDialog";
import EditDialog from "./EditDialog";

export default {
  components: { EditDialog, LecturesDialog },
  data: () => ({
    search: "",
    dialog: false,
    courseCategory: {},
    lecturesDialog: false,
    headers: [
      { text: "Sınıf Adı", value: "name" },
      { text: "Kurs", value: "course.name" },
      { text: "Dersler", value: "lecturesButton" },
      { text: "Oluşturma", value: "createdDate" },
      { text: "Son Düzenleme", value: "lastModifiedDate" },
      { text: "Aksiyonlar", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      course: 0
    },
    defaultItem: {
      id: 0,
      name: "",
      course: 0
    }
  }),

  computed: {
    ...mapGetters("course", ["courseCategories", "courses"]),
    formTitle() {
      return this.editedIndex === -1 ? "Yeni Sınıf" : "Sınıfı Düzenle";
    }
  },

  mounted() {
    Promise.all([this.fetchCourseCategories("CourseCategory"), this.fetchCourses()]);
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions("course", [
      "fetchCourses",
      "fetchCourseCategories",
      "createCourseCategory",
      "updateCourseCategory",
      "deleteCourseCategory"
    ]),

    editItem(item) {
      if (item) {
        this.editedIndex = this.courseCategories.indexOf(item);
        this.editedItem = this._.pick(item, ["id", "name", "course"]);
        this.editedItem.course = item.course.id;
      }

      this.dialog = true;
    },

    showLectures(item) {
      this.lecturesDialog = true;
      this.courseCategory = item;
    },

    deleteItem(courseCategory) {
      if (confirm("Bu derse dair kayıtlı tüm bilgiler silinecektir. Devam edilsin mi?")) {
        this.deleteCourseCategory(courseCategory);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.updateCourseCategory(this.editedItem);
      } else {
        this.createCourseCategory(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
