/* eslint-disable no-undef */
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from "vuex";

import auth from "./auth";
import course from "./course";
import loader from "./loader";
import quiz from "./quiz";
import user from "./user";
import badge from "./badge";
import media from "./media";
import announcement from "./announcement";
import notification from "./notification";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  modules: { auth, course, loader, quiz, user, badge, media, announcement, notification },
  strict: true,
  state: {
    drawer: null
  },
  mutations: {
    SET_DRAWER(state, payload) {
      try {
        if ($Android) {
          $Android.toggleDrawer(payload);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        state.drawer = payload;
      }
    }
  }
});
