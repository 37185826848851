import ApiService from "@/common/api.service";
import { SET_BADGES } from "../mutations.type";

const actions = {
  async fetchBadges(context) {
    const res = await ApiService.get(`badges?projection=BadgeProjection&size=1000`);
    context.commit(SET_BADGES, res.data["_embedded"].badges);
  },
  async createBadge(context, badge) {
    badge.media = `medias/${badge.mediaId}`;
    await ApiService.post("badges", badge);
    context.dispatch("fetchBadges");
  },
  async updateBadge(context, badge) {
    if (badge.mediaId) {
      badge.media = `medias/${badge.mediaId}`;
    }
    await ApiService.patch(`badges/${badge.id}`, badge);
    context.dispatch("fetchBadges");
  },
  async deleteBadge(context, badgeId) {
    await ApiService.delete(`badges/delete/${badgeId}`);
    context.dispatch("fetchBadges");
  },
  async getUserBadges(context, userId) {
    let res = await ApiService.get(`/users/${userId}/badges?projection=BadgeProjection`);
    return res.data["_embedded"].badges;
  }
};

export default actions;
