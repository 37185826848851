<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <ValidationObserver ref="observer" v-slot="{ invalid }" @submit.prevent="onSubmit()">
            <form id="badgeForm">
              <v-row justify="center">
                <v-col cols="12">
                  <ValidationProvider name="Madalya Adı" rules="required" v-slot="{ errors }">
                    <v-text-field
                      prepend-icon="mdi-note-outline"
                      v-model="editedItem.name"
                      label="Madalya Adı"
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </v-col>
                <v-img v-if="editedItem.picUrl" max-width="100" :src="editedItem.picUrl" />

                <v-col cols="12">
                  <v-file-input
                    :rules="rules"
                    accept="image/*"
                    @change="inputFile"
                    v-model="file"
                    :clearable="false"
                    show-size
                    label="Görsel"
                  />
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onCancel">
          İptal
        </v-btn>
        <v-btn form="badgeForm" type="submit" color="blue darken-1" text>
          Kaydet
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: { ValidationProvider, ValidationObserver },
  props: ["value", "editedItem", "formTitle"],
  data: () => ({
    rules: [value => !value || value.size < 2000000 || "Görsel 2 MB'dan büyük olmamalı"],
    file: null
  }),
  watch: {
    show(val) {
      if (val) {
        this.$refs?.observer?.reset();
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions("media", ["uploadFile", "deleteMedia"]),
    async inputFile(file) {
      if (file?.size > 2000000) {
        return;
      }
      if (this.editedItem.mediaId) {
        await this.deleteMedia(this.editedItem.mediaId);
      }
      let uploadResult = await this.uploadFile(file);
      this.editedItem.picUrl = uploadResult.url;
      this.editedItem.mediaId = uploadResult.id;
    },
    onCancel() {
      if (this.editedItem.mediaId) {
        this.deleteMedia(this.editedItem.mediaId);
      }
      this.$emit("onCancel");
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (this.file?.size > 2000000) {
        return;
      }
      if (isValid) {
        this.$emit("onSave");
        this.file = null;
      }
    }
  }
};
</script>
